<template>
	<div class="edit-method-form">
		<p class="title">Edit Method</p>
		<form v-on:submit.prevent="handleFormSubmit">
			<label for="method-image" class="image-preview btn">
				<p v-if="!imageSrc">Select an Image</p>
				<img v-if="imageSrc" :src="imageSrc" />
			</label>
			<input
				@change="handleImageSelected"
				type="file"
				id="method-image"
				class="file-input"
			/>
			<div class="input-ctn">
				<div class="label">Title</div>
				<input
					v-model="title"
					required
					type="text"
					class="input"
					placeholder="Title"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Description</div>
				<textarea
					v-model="description"
					required
					type="text"
					class="input"
					placeholder="Description"
					row="3"
				></textarea>
			</div>
			<button v-if="!loading" class="btn submit">Save</button>
			<p v-if="loading" class="disabled-btn">
				{{ loading }}
			</p>
		</form>
	</div>
</template>

<script>
import { FileUpload } from "../../handler";
import { API_URL } from "../../const";

export default {
	name: "EditMethodForm",
	props: ["onMethodUpdated", "topUpInfo"],

	data() {
		return {
			title: this.topUpInfo.title,
			description: this.topUpInfo.description,
			imageSrc: `${API_URL}/${this.topUpInfo.image}`,
			file: null,
			loading: false,
		};
	},
	methods: {
		async handleImageSelected(e) {
			const image = e.target.files[0];
			this.imageSrc = URL.createObjectURL(image);
			this.file = image;
		},
		async handleFormSubmit() {
			this.loading = "Uploading Image . . .";
			const file = this.file && await FileUpload.uploadFile(this.file);
			this.loading = "Creating Image . . .";
			const updated = await this.$store.dispatch("topupModule/updateInfo", {
        _id: this.topUpInfo._id,
				title: this.title,
				description: this.description,
				image: this.file? file.path : this.topUpInfo.image,
			});
			this.onMethodUpdated();
			this.loading = false;
		},
	},
};
</script>

<style></style>
