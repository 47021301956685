<template>
  <div class="modal">
    <div class="modal-content select-category-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <form>
        <input type="text" v-model="search" class="input" placeholder="Search Sale Person">
      </form>
      <div class="category-list">
        <div 
          v-for="c in salePeople.data" 
          v-if="search == '' || c.code.toLowerCase().startsWith(search.toLowerCase()) || c.name.toLowerCase().startsWith(search.toLowerCase())|| (c.code + ' - ' + c.name).toLowerCase().startsWith(search.toLowerCase())"
          :key="c._id" 
          v-on:click="handleSubmit(c)"
          class="category btn"
        >
          <p>{{c.code + ' - ' + c.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit'],
  data() {
    return {
      search: ''
    }
  },
  methods: {
    handleSubmit(sale) {
      this.onSubmit(sale)
      this.closeModal()
    }
  },
  computed: {
    salePeople() {
      return this.$store.state.salePersonModule.salePeople
    }
  }
}
</script>

<style>

</style>