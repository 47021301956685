<template>
	<div class="page dashboard invoice-dashboard">
		<div class="content">
			<div class="title-bar">
				<p class="title">VIP Invoice Management</p>
				<div class="group" v-if="['order', 'admin', 'financer'].includes(currentUser.role.name)">
					<div class="btn new">
						<div class="icon"></div>
                        <router-link
                            :to="`/invoices/new`"
                            class="label"
                        >
						    Add New
                        </router-link>
					</div>
				</div>
			</div>
			<TabBarComponent
				:isLoading="false"
				:query.sync="query"
				:data="status"
				queryKey="status"
				:label="(r) => r.label"
				:key="false"
				:hasAll="false"
			/>
            <input placeholder="Search for invoices..." type="text" class="search" v-model="search" />
            <select placeholder="Payment Status" type="text" class="search" style="margin-left: 10px; width: 10%" v-model="query.paymentStatus" >
                <option value="all">All</option>
                <option value="paid">Paid</option>
                <option value="due">Due</option>
                <option value="due_100">Due 100%</option>
            </select>
            <input
                v-model="vipCustomerLabel"
                type="text"
                class="search"
                style="margin-left: 10px; width: 10%"
                placeholder="Vip Customer"
                @focus="openVipCustomerModal"
            />
            <br>
            <input type="date" class="search" style="width: 20%; margin-top:10px" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; margin-bottom: 10px; width: 20%" v-model="query.toDate">
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
			<ListingComponent
				model="data"
				:isLoading="isLoadingInvoice"
				:data="invoices"
				:query.sync="query"
				:refresh="getInvoices"
				:toggleActionMenu="handleInvoiceClick"
				:listingOptions="listingOptions"
				:deleteRecord="deleteInvoice"
                :recoverRecord="recoverInvoice"
			/>
		</div>
        <SelectVipCustomerModal :closeModal="closeVipCustomerModal" v-show="vipCustomerModalOpened"
            :onSubmit="selectedVipCustomer" />
	</div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";
import moment from "moment";
import SelectVipCustomerModal from "../../components/SelectVipCustomerModal";

export default {
	name: "InvoiceDashboard",
	components: {
		ListingLoadingComponent,
		TabBarLoadingComponent,
		ListingComponent,
		TabBarComponent,
        SelectVipCustomerModal
	},
	data() {
        const status = [
            { _id: -1, label: "All" },
            { _id: 0, label: "Pending" },
            { _id: 1, label: "Ordering" },
            { _id: 2, label: "In China" },
            { _id: 3, label: "Shipping" },
            { _id: 4, label: "In Cambodia" },
            { _id: 5, label: "Wait For Payment" },
            { _id: 6, label: "Paid" },
            { _id: 7, label: "Delivering" },
            { _id: 8, label: "Completed" },
            { _id: 1.1, label: "Comission" },
        ];

        if(['admin'].includes(this.$store.state.usersModule.currentUser.role.name)) {
            status.push({ _id: 0.9, label: "Delete" });
        }
    
		return {
            searchTimeout: null,
            search: null,
			isLoadingInvoice: false,
			query: {
				invoice: "ASC",
				page: 1,
				limit: 10,
				sortBy: "createdAt",
				status: 0,
                fromDate: null,
                paymentStatus: 'all',
                toDate: null
			},
			status,
            customer: null,
            vipCustomerLabel: null,
            vipCustomerModalOpened: false,
            excelName: 'Invoice-'+moment().format('YYYY-MM-DD')+'.xls'
		};
	},
	created() {
		this.getInvoices();
        this.$store.dispatch("usersModule/getUserRoles");
	},
	methods: {
        openVipCustomerModal() {
            this.vipCustomerModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectVipCustomerModelSearchInput").focus();
            })
        },
        closeVipCustomerModal() {
            this.vipCustomerModalOpened = false;
        },
        selectedVipCustomer(vipCustomer) {
            const id = vipCustomer ? vipCustomer._id : null;
            if (this.customer == id) {
                return;
            }

            if (vipCustomer) {
                this.vipCustomerLabel = vipCustomer.name + ' - ' + vipCustomer.phoneNumber + " (" + vipCustomer
                    .code + ")";
            } else {
                this.vipCustomerLabel = null;
            }
            this.customer = id;
            this.getInvoices();
        },
        async excelData() {
            const res = await this.$store.dispatch("invoiceModule/getInvoices", {
                export: true,
                ...this.query, 
                searchValue: this.search,
                customerId: this.customer
            });

            return res.data.map((record, index) => {
                let total = 0;
                let totalChinaMoney = 0;
                let totalRefund = 0;
                record.invoiceItems?.map((i) => {
                    let productPrice = i.price * i.amount;
                    if(i.status !== 9) {
                        totalRefund += i.refundAmount > 0 ? i.price * i.refundAmount : 0
                        productPrice -= (i.refundAmount > 0 ? i.price * i.refundAmount : 0)
                        total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                        totalChinaMoney += i.chinaMoney;
                    } else {
                        totalRefund += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                    }
                });

                total += record.internationalShippingCost - record.discountAmount 

                return {
                    '#': index + 1,
                    'ID': record.invoiceId,
                    'Invoice No': record.invoiceNo,
                    'Customer': record.customer ? record.customer.name + " - " + record.customer.phoneNumber + " (" + record.customer.code + ")" : 'N/A',
                    'Total ¥': totalChinaMoney.toFixed(2),
                    'Total': total.toFixed(2),
                    'Paid': record.paidAmount.toFixed(2),
                    'Due': (total - record.paidAmount).toFixed(2),
                    'Refund': totalRefund.toFixed(2),
                    'Date': moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                    'Status': this.getStatus(record.status)
                };
            });
        },
		async getInvoices() {
			this.isLoadingInvoice = true;
            await this.$store.dispatch("invoiceModule/getInvoices", {
                ...this.query, 
                searchValue: this.search,
                customerId: this.customer
            });
			this.isLoadingInvoice = false;
		},
		handleInvoiceClick(action, payload) {
			window.open(`/invoices/${payload._id}`);
		},
		deleteInvoice(item) {
            const confirmed = confirm('Are you sure you want to delete this?')
            
            if(confirmed) {
                this.$store.dispatch("invoiceModule/delete", {
                    _id: item._id
                });

                this.getInvoices();
            }
        },
        recoverInvoice(item) {
            const confirmed = confirm('Are you sure you want to recover this?')
            
            if(confirmed) {
                this.$store.dispatch("invoiceModule/recover", {
                    _id: item._id
                });
                
                this.getInvoices();
            }
        },
		getStatus(status) {
			switch (status) {
				case -1:
					return "All";
				case 0:
					return "Pending";
				case 1:
					return "Ordering";
				case 2:
					return "In China";
				case 3:
					return "Shipping";
				case 4:
					return "In Cambodia";
				case 5:
					return "Wait For Payment";
				case 6:
                    return "Paid";
                case 7: 
                    return "Delivering";
                case 8: 
                    return "Completed"
                case 1.1: 
                    return "Commission"
			}
		},
  },
  watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getInvoices();
				}
			},
		},
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getInvoices(), 500
            )
        }
	},
	computed: {
		invoices() {
			return this.$store.state.invoiceModule.invoices;
		},
		listingOptions() {
            let actions = [];
            if(['admin'].includes(this.$store.state.usersModule.currentUser.role.name)) {
                if(this.query.status == 0) {
                    actions = ["delete"];
                } else if (this.query.status == 0.9) {
                    actions = ["recover"];
                }
            }

			return {
				hasSelected: false,
				hasNo: true,
				actions,
				queryOptions: {
					sortBy: [{ label: "CreatedAt", value: "createdAt" }],
					sortDirection: [
						{ label: "Ascending", value: "ASC" },
						{ label: "Descending", value: "DESC" },
					],
				},
				schema: [
					{ label: "ID", key: "invoiceId" },
					{ label: "Invoice No", key: "invoiceNo" },
					{
						label: "VIP Customer",
						key: "createBy",
						value: (record) => record.customer ? record.customer.name + " - " + record.customer.phoneNumber + " (" + record.customer.code + ")" : 'N/A',
					},
					{
						label: "Total ¥",
						value: (record) => {
                            let total = 0;
                            record.invoiceItems?.filter(o => o.status !== 9)?.map((i) => {
                                total += i.chinaMoney;
                            });
                            return total.toFixed(2);
                        },
					},
					{
						label: "Total",
						value: (record) => {
                            let total = 0;
                            record.invoiceItems?.filter(o => o.status !== 9)?.map((i) => {
                                const productPrice = i.price * i.amount - (i.refundAmount > 0 ? i.price * i.refundAmount : 0);
                                total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                            });
                            total += record.internationalShippingCost - record.discountAmount 
                            return total.toFixed(2);
                        },
					},
					{
						label: "Piad",
						value: (record) => {
                            return record.paidAmount.toFixed(2);
                        },
					},
					{
						label: "Due",
						value: (record) => {
                            let total = 0;
                            record.invoiceItems?.filter(o => o.status !== 9)?.map((i) => {
                                const productPrice = i.price * i.amount - (i.refundAmount > 0 ? i.price * i.refundAmount : 0);
                                total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                            });
                            total += record.internationalShippingCost - record.discountAmount - record.paidAmount;
                            return total.toFixed(2);
                        },
					},
					{
						label: "Refund",
						value: (record) => {
                            let total = 0;
                            record.invoiceItems?.map((i) => {
                                if(i.status === 9) {
                                    const productPrice = i.price * i.amount;
                                    total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                                } else if(i.refundAmount > 0) {
                                    const productPrice = i.price * i.refundAmount;
                                    total += productPrice;
                                }
                            });

                            return total.toFixed(2);
                        },
					},
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
					},
					{
						label: "Status",
						key: "status",
						value: (record) => this.getStatus(record.status),
					},
				],
			};
		},
        roles() {
            return this.$store.state.usersModule.userRoles;
        },
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        }
	},
};
</script>

<style></style>
