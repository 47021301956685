import Vue from 'vue'
import VueRouter from 'vue-router'

import mainLayoutRouter from './mainLayout/mainLayoutRouter'
import authLayoutRouter from './authLayout/authLayoutRouter'
import NotFoundPage from '../pages/NotFoundPage.vue'

Vue.use(VueRouter)
export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...mainLayoutRouter,
    ...authLayoutRouter,
    {
      path: "*",
      name: 'non-found-page',
      component: NotFoundPage,
    }
  ]
});
