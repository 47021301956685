<template>
  <div class="edit-category">
    <p class="title">Edit Group</p>
    <form v-on:submit="handleFormSubmit">
      <div class="input-ctn">
        <div class="label">Name</div>
        <input type="text" class="input" placeholder="English" v-model="en" />
        <input type="text" class="input" placeholder="ភាសាខ្មែរ" v-model="km" />
        <input type="text" class="input" placeholder="中文" v-model="cn" />
      </div>
      <button v-if="!loading" type="submit" class="btn submit">Edit Category</button>
      <p v-if="loading" class="disabled-btn">Updating . . .</p>
      <h3>Category</h3>
      <div class="category-list">
        <div class="category" :key="category._id" v-for="category in categories">
          <div class="label">{{category.en}} {{category.cn}} {{category.km}}</div>
          <div class="btn remove" v-on:click="removeCategory(category._id)">Remove</div>
        </div>
        <div class="btn submit" v-on:click="openModal" style="width: 100%; text-align: center;">NewCategory</div>
        <!-- <button
          class="btn submit"
          type="submit"
          v-on:click="openModal"
          style="width: 100%;"
        >New Category</button> -->
      </div>
    </form>
    <NewCategoryModal :onSubmit="addCategory" v-if="openCategoryModal" :closeModal="closeModal" />
  </div>
</template>

<script>
import NewCategoryModal from './NewCategoryModal'
export default {
  name: "EditCategory",
  props: ["categoryGroup", "editCategory"],
  components: {NewCategoryModal},
  data() {
    return {
      loading: false,
      openCategoryModal: false,
      en: this.categoryGroup.en,
      km: this.categoryGroup.km,
      cn: this.categoryGroup.cn,
      loadingCategories: true,
      categories: [],
      newCategory: ''
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    closeModal() {
      this.openCategoryModal = false
    },
    openModal() {
      this.openCategoryModal = true
    },
    async getCategories() {
      this.loadingCategories = true;
      const category = await this.$store.dispatch(
        "productCategoriesModule/getProductCategoryGroupById",
        { id: this.categoryGroup._id }
      );
      if (category) {
        this.categories = category.categories;

      }
      this.loadingCategories = false;
    },
    async addCategory(payload) {
      this.loadingCategories = true
      
      await this.$store.dispatch(
        'productCategoriesModule/addProductCategory',
        {...payload, id: this.categoryGroup._id}
      )
      this.getCategories()
      this.newCategory = ""
      this.loadingCategories = false
    },
    async handleFormSubmit(e) {
      e.preventDefault();
      this.loading = true;
      let {km, en, cn} = this
      await this.editCategory({ km, en, cn });
      this.loading = false;
    },
    async removeCategory(category) {
      const confirmed = confirm('Delete Category?')
      if (confirmed) {
        this.loading = true
        await this.$store.dispatch(
          'productCategoriesModule/deleteProductCategories', 
          {_id: this.categoryGroup._id, category}
        ) 
        this.getCategories()
        this.loading = false
      }
    }
  },
};
</script>

<style>
</style>