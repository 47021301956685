<template>
  <div class="page dashboard exchange-rate">
    <div class="content">
      <div class="title-bar">
        <p class="title">Exchange Rate</p>
      </div>
      <ListingComponent
        model="data"
        :isLoading="isLoading"
        :data="exchangeRates"
        :query.sync="query"
        :refresh="getExchangeRates"
				:toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
        :deleteRecord="() => {}"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen? 'active': ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <div class="title">Edit Rate</div>
      <form v-on:submit.prevent="updateExchangeRate">
        <div class="input-ctn">
          <div class="label">Name</div>
          <input 
            v-model="focusedRates.name"
            type="text" 
            class="input"
            placeholder="Name"
          >
        </div>
        <div class="input-ctn">
          <div class="label">Rate To USD</div>
          <input 
            v-model="focusedRates.rateTo"
            type="text" 
            class="input"
            placeholder="Rate To USD"
          >
        </div>
        <div class="input-ctn">
          <div class="label">Rate From USD</div>
          <input 
            v-model="focusedRates.rateFrom"
            type="text" 
            class="input"
            placeholder="Rate From USD"
          >
        </div>
        <button type="submit" class="btn submit">Save Change</button>
      </form>
    </div>
  </div>
</template>

<script>
import ListingComponent from '../../components/ListingComponent'
export default {
  name: "ExchangeRate",
  components: {
    ListingComponent
  },
  data() {
    return {
      isActionMenuOpen: "",
      isLoading: true,
      focusedRates: {
        name: "",
        rateTo: "",
        rateFrom: ""
      }, 
      query: {
        order: "ASC",
        page: 1,
        limit: 10
      }
    }
  }, 
  mounted() {
    this.getExchangeRates()
  },
  methods: {
    async getExchangeRates() {
      this.isLoading = true
      await this.$store.dispatch("exchangeRateModule/getExchangeRates", {
        query: this.query
      })
      this.isLoading = false
    }, 
    async updateExchangeRate() {
      this.isLoading = true
      const {
        _id,
        name,
        rateTo, 
        rateFrom
      } = this.focusedRates
      const success = await this.$store.dispatch("exchangeRateModule/updateExchangeRate", {
        _id, data: {name, rateTo, rateFrom}
      })
      if(success) {
        this.toggleActionMenu("")
        this.getExchangeRates()
      }
    },
    toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedRates = {...payload};
				}
			}
			this.isActionMenuOpen = menu;
		},
  }, 
  computed: {
    listingOptions() {
      return {
        hasSalected: false,
        actions: [],
        queryOptions: {
          sortBy: [],
          sortDirections: [
            {
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
          ]
        },
        schema: [
          { label: "Name", key: "name" }, 
          { label: "To USD", key: "rateTo" }, 
          { label: "From USD", key: "rateFrom" }, 
        ]
      }
    },
    exchangeRates() {
      return this.$store.state.exchangeRateModule.exchangeRates
    }
  }
}
</script>

<style>

</style>