<template>
  <div class="modal">
    <div class="modal-content new-variation-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <div class="label">New Variation</div>
      <form v-on:submit.prevent="handleSubmit">
        <input 
          type="text" 
          placeholder="Name" 
          class="input"
          v-model="name"
        >
        <div class="row">
          <input type="checkbox" class="checkbox" v-model="hasImage">
          <p>Has Image</p>
        </div>
        <div class="submit">
          <button type="submit" class="btn">Add</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit'],
  data() {
    return {
      name: '',
      hasImage: false,
      data: []
    }
  },
  methods: {
    handleSubmit(){
      this.onSubmit({name: this.name, data: [], hasImage: this.hasImage})
      this.closeModal()
    }
  }
}
</script>

<style>

</style>