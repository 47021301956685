import AuthLayout from '../../layouts/AuthLayout.vue';
import LogInPage from '../../pages/auth/LogInPage.vue';
import ForgotPasswordPage from '../../pages/auth/ForgotPasswordPage.vue';

export default [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'auth',
        redirect: 'login'
      },
      {
        path: 'login',
        name: 'log-in-page',
        component: LogInPage,
      },
      {
        path: 'forgot-password',
        name: 'forgot-password-page',
        component: ForgotPasswordPage,
      },
    ]
  },
]
