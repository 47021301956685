<template>
  <div class="modal">
    <div class="modal-content new-banner-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <form v-on:submit.prevent="handleFormSubmit">
        <label for="image" class="preview" :style="{'background-color': color}">
          <p v-if="!filePreview">Select Image</p>
          <img v-if="filePreview" :src="filePreview" alt="">
        </label>
        <input
          @change="handleImageSelected" 
          type="file" 
          class="image-input" 
          id="image"
        >
        <div class="input-ctn">
          <div class="label">Color</div>
          <input type="color" v-model="color" class="input color-input">
        </div>
        <div class="submit-group">
          <button type="submit" class="btn submit">
            Create
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { FileUpload } from "../../handler";
export default {
  props: ['closeModal'],
  data() {
    return {
      file: null,
      filePreview: '',
      color: '#000'
    }
  },
  methods: {
    async handleFormSubmit() {
      const file = await FileUpload.uploadFile(this.file)
      const data = {
        image: file.path,
        bgColor: this.color
      }
      const done = await this.$store.dispatch('bannerModule/createBanner', data)
      if(done) {
        this.closeModal()
      }
    },
    handleImageSelected(e) {
      const file = e.target.files[0]
      this.file = file
      this.filePreview = URL.createObjectURL(file)
    }
  }
}
</script>

<style>

</style>