<template>
  <div class="new-method-form">
    <p class="title">New Method</p>
    <form v-on:submit.prevent="handleFormSubmit">
      <label for="method-image" class="image-preview btn">
        <p v-if="!imageSrc">Select an Image</p>
        <img v-if="imageSrc" :src="imageSrc"/>
      </label>
      <input @change="handleImageSelected" type="file" id="method-image" class="file-input">
      <div class="input-ctn">
        <div class="label">Title</div>
        <input v-model="title" required type="text" class="input" placeholder="Title">
      </div>
      <div class="input-ctn">
        <div class="label">Description</div>
        <textarea v-model="description" required type="text" class="input" placeholder="Description" row="3"></textarea>
      </div>
      <button v-if="!loading" class="btn submit">Save</button>
      <p v-if="loading" class="disabled-btn">
        {{loading}}
      </p>
    </form>
  </div>
</template>

<script>
import { FileUpload } from '../../handler'
export default {
  name: "NewMethodForm",
  props: ["onMethodCreated"],
  data() {
    return {
      title: "",
      description: "",
      imageSrc: null,
      file: {}, 
      loading: false,
    }
  },
  methods: {
    async handleImageSelected(e) {
      const image = e.target.files[0]
      this.imageSrc = URL.createObjectURL(image)
      this.file=image
    },
    async handleFormSubmit() {
      this.loading = "Uploading Image . . ."
      const file = await FileUpload.uploadFile(this.file)
      this.loading = "Creating Method . . ."
      const created = await this.$store.dispatch('topupModule/createInfo', {
        title: this.title,
        description: this.description,
        image: file.path
      })
      this.onMethodCreated()
      this.loading = false
    }
  }
}
</script>

<style>

</style>