import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    balanceTransactions: {pageMeta: {}, data:[]},
    blanceHistory: {},
    exportedBalanceTransactions: [],
    isLoadingBalanceTransaction: false,
  },
  mutations: {
    setBalanceTransactions(state, payload) {
      state.balanceTransactions = payload
    },
    setBlanceHistory(state, payload) {
      state.blanceHistory = payload
    },
    setExportedBalanceTransactions(state, payload) {
      state.exportedBalanceTransactions = payload.data
    },
    setIsLoadingBalanceTransaction(state, payload) {
      state.isLoadingBalanceTransaction = payload
    }
  },
  actions: {
    async getBalanceTransactions(context, payload) {
      context.commit('setIsLoadingBalanceTransaction', true)
      const query = payload
      const url = 'balanceTransaction'

      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET',{}, query, {})
      if(success) {
        context.commit('setBalanceTransactions', result)
      } else {
        httpErrorHandler(message) 
      }
      context.commit('setIsLoadingBalanceTransaction', false)
    },

    async getHistory(context, payload) {
      const url = 'balanceHistory'

      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET',{}, payload, {})
      if(success) {
        context.commit('setBlanceHistory', result)
      } else {
        httpErrorHandler(message) 
      }
    },

    async exportBalanceTransactions(context, payload) {
      context.commit('setIsLoadingBalanceTransaction', true)
      const query = payload
      const url = 'balanceTransaction'

      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET',{}, query, {})
      if(success) {
        context.commit('setExportedBalanceTransactions', result)
      } else {
        httpErrorHandler(message) 
      }
      context.commit('setIsLoadingBalanceTransaction', false)
    }
  }
}