import Vue from 'vue'
import Vuex from 'vuex'

import mainLayoutModule from './layouts/mainLayoutModule'
import productsModule from './models/productsModule'
import usersModule from './models/usersModule'
import firstInfoModule from './models/firstInfoModule'
import merchantModule from './models/merchantModule';
import productCategoriesModule from './models/productCategoriesModule'
import transactionsModule from './models/transactionsModule';
import balanceTransactionsModule from './models/balanceTransactionsModule';
import orderModule from './models/orderModule';
import quotationModule from './models/quotationModule';
import invoiceModule from './models/invoiceModule';
import topupModule from './models/topupModule';
import bannerModule from './models/bannerModule';
import shippingMethodModule from './models/shippingMethodModule';
import exchangeRateModule from './models/exchangeRateModule';
import salePersonModule from './models/salePersonModule';
import userRoleModule from './models/userRoleModule';
import percentageCutsModule from './models/percentageCutsModule';
import notificationModule from './models/notificationModule';
import setting from './models/setting'
import customerSubscriptionModule from "./models/customerSubscriptionModule";
import customerTypeModule from './models/customerTypeModule';
import vipCustomerModule from './models/vipCustomerModule';
import voucherHistoryModule from './models/voucherHistoryModule';

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    mainLayoutModule,
    bannerModule,
    usersModule,
    firstInfoModule,
    merchantModule,
    productCategoriesModule,
    transactionsModule,
    balanceTransactionsModule,
    orderModule,
    quotationModule,
    invoiceModule,
    productsModule,
    topupModule,
    shippingMethodModule,
    exchangeRateModule,
    salePersonModule,
    customerTypeModule,
    vipCustomerModule,
    userRoleModule,
    percentageCutsModule,
    notificationModule,
    setting,
    customerSubscriptionModule,
    voucherHistoryModule
  }
})
