import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    shippingMethods:{
      pageMeta: null, methods: []
    },
  },
  mutations: {
    setShippingMethods(state, payload) {
      state.shippingMethods = payload
    }
  },
  actions: {
    async getShippingMethods(context, payload) {
      const url = 'shippingMethod'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "GET", {}, query, {})
      if(success) {
        context.commit('setShippingMethods', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async editShippingMethods(context, payload) {
      const { _id, name, description, duration } = payload
      const url = `shippingMethod/${_id}`
      const { 
        success, 
        result, 
        message 
      } = await HttpRequest.authorizedRequest(url, 'PATCH', {
        name, 
        description,
        duration
      })
      if(success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}