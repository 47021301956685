<template>
  <div class="page dashboard sale-person-dashboard">
    <div class="content">
      <div
				v-if="isActionMenuOpen"
				class="action-menu-backdrop"
				v-on:click="toggleActionMenu('')"
			></div>

      <div class="title-bar">
        <div class="title">Customer Type</div>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">Add New</p>
          </div>
        </div>
      </div>
    <input placeholder="Search by name..." type="text" class="search" v-model="search" />
      <ListingComponent 
        model="data"
        :isLoading="isLoading"
        :data="customerType"
        :query.sync="query"
        :refresh="getCustomerType"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
        :deleteRecord="deleteCustomerType"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active': '' }`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <NewCustomerTypeForm 
        v-if="isActionMenuOpen === 'new'"
        :addCustomerType="addCustomerType"
      />
      <EditCustomerTypeForm 
        v-if="isActionMenuOpen === 'read'"
        :editCustomerType="editCustomerType"
        :customerType="focusedItem"
      />
    </div>
  </div>
</template>

<script>
import ListingComponent from '../../components/ListingComponent'

import NewCustomerTypeForm from './NewCustomerTypeForm'
import EditCustomerTypeForm from './EditCustomerTypeForm'

export default {
  name: "CustomerTypeDashboard",
  components: {
    ListingComponent,
    NewCustomerTypeForm,
    EditCustomerTypeForm
  },
  data() {
    return {
      focusedItem: {},
      isActionMenuOpen: "",
      isLoading: true,
      search: null,
      searchTimeout: null,
      query: {
        order: "ASC",
        page: 1,
        limit: 10
      }
    }
  },
  mounted() {
    this.getCustomerType()
  },
  methods: {
    async getCustomerType() {
      this.isLoading = true
      await this.$store.dispatch("customerTypeModule/getCustomerType", {
        query:{
            ... this.query,
            search: this.search
        }
      })
      this.isLoading = false
    },
    async addCustomerType(payload) {
      const created = await this.$store.dispatch("customerTypeModule/createCustomerType", payload)
      if(created) {
        this.getCustomerType()
        this.toggleActionMenu("")
      }
    },
    async editCustomerType(payload) {
      const { name, phoneNumber } = payload
      const updated = await this.$store.dispatch("customerTypeModule/editCustomerType", {
        _id: this.focusedItem._id,
        name,
        phoneNumber
      })
      if(updated) {
        this.getCustomerType() 
        this.toggleActionMenu("")
      }
    },
    async deleteCustomerType(item) {
        const confirmed = confirm('Are you sure you want to delete this?')
        
        if(confirmed) {
            const res = await this.$store.dispatch("customerTypeModule/deleteCustomerType", {
                _id: item._id
            })
            if(res) {
                this.getCustomerType() 
                this.toggleActionMenu("")
            }
        }
    },
    toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedItem = {...payload};
				}
			}
			this.isActionMenuOpen = menu;
		},
    },
    watch: {
        query: {
            deep: true,
            handler: function () {
                if (this.query.page >= 1) {
                    this.getCustomerType();
                }
            },
        },
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getCustomerType(), 500
            )
        }
    },
    computed: {
        listingOptions() {
        return {
            hasSelect: false,
            actions: ['delete'],
            queryOptions: {
                sortBy: [],
                sortDirection: [
                    {
                    label: "Ascending",
                    value: "ASC"
                    }, 
                    { 
                    label: "Descending",
                    value: "DESC"
                    }
                ]
            }, 
            schema: [
                { label: "Name", key: "name" }
            ]
        }
    },
    customerType() {
        return this.$store.state.customerTypeModule.customerType
    }
  }
}
</script>

<style>

</style>