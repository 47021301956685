<template>
    <div class="page dashboard transactions-dashboard">
        <div class="content">
            <div class="title-bar">
                <p class="title">Setting, Promotion Product</p>
				<div class="group">
					<div class="btn new">
						<div class="icon"></div>
                        <router-link
                            :to="`/setting/edit-promotion-product`"
                            class="label"
                        >
						    Add New
                        </router-link>
					</div>
				</div>
            </div>
            <ListingComponent model="data" :data="{data:data,pageMeta: {page: 1, size: data.length, totalEntries: data.length, totalPages: 1}}" :query.sync="query"
                :refresh="getData" :toggleActionMenu="handleDataClick" :listingOptions="listingOptions"
                :deleteRecord="deleteData" :isLoading="false"/>
        </div>
    </div>
</template>

<script>
    import ListingLoadingComponent from "../../components/ListingLoadingComponent";
    import ListingComponent from "../../components/ListingComponent";
    import Searchbar from '../../components/Searchbar';

    export default {
        name: "SettingPromotionProductDashboard",
        components: {
            ListingLoadingComponent,
            ListingComponent,
            Searchbar,
        },
        data: function () {
            return {
                query: {
                    order: "ASC",
                    page: 1,
                    limit: 10,
                    sortBy: "",
                    status: 0,
                }
            };
        },
        created() {
            this.getData();
        },
        methods: {
            handleDataClick(action, payload) {
                if(action == 'edit') {
                    this.$router.push({ path: "/setting/edit-promotion-product", query: payload });
                }
            },
            getData() {
                this.$store.dispatch("getSettingPromotionProduct", {});
            },
            deleteData(item) {
                const confirmed = confirm('Are you sure you want to delete this?')
                
                if(confirmed) {
                    this.$store.dispatch("deleteSettingPromotionProduct", {
                        _id: item._id
                    });
                }
            },
        },
        watch: {
            query: {
                deep: true,
                handler: function () {
                    if (this.query.page >= 1) {
                        this.getData();
                    }
                },
            }
        },
        computed: {
            data() {
                return this.$store.state.setting.promotionProduct || [];
            },
            listingOptions() {
                return {
                    hasSelected: false,
                    hasNo: true,
                    actions: ['delete', 'edit'],
                    queryOptions: {
                        sortBy: [],
                        sortDirection: [],
                    },
                    schema: [
                        {
                            label: "Name",
                            key: 'name'
                        },
                        {
                            label: "Order",
                            key: 'order'
                        },
                        {
                            label: "Image",
                            key: "imageUrl",
                            value: (record) => {
                                return `
                                    <img
                                        src="${record.imageUrl}"
                                        data-lity 
                                        data-lity-target="${record.imageUrl}"
                                        width="50px"
                                        style="cursor: pointer;"
                                        alt=""
                                    />
                                `;
                            },
                        },
                        {
                            label: "Link To",
                            key: 'linkTo',
                            value: (record) => {
                                if(!record.linkTo) {
                                    return 'N/A';
                                }

                                return '<a target="_blank" style="color: blue" href="' + record.linkTo + '">'+ record.linkTo +'</a>'
                            },
                        },
                        {
                            label: "Origin Price",
                            key: 'originPrice'
                        },
                        {
                            label: "Promotion Price",
                            key: 'promotionPrice'
                        },
                        {
                            label: "Promotion Percentage",
                            key: 'promotionPercentage',
                            value: (record) => {
                                return '<span style="color: '+record.promotionPercentageColor+'">'+ record.promotionPercentage +'</span>'
                            },
                        }
                    ],
                };
            }
        },
    };
</script>

<style></style>