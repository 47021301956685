<template>
  <div class="page dashboard banner-dashboard">
    <div class="content">
      <div class="title-bar">
        <p class="title">Banners</p>
        <p 
          class="add btn" 
          v-on:click="openNewBannerModal"
        >
          Add Banner
        </p>
      </div>
      <div class="banner-list">
        <div class="banner-item" :style="{'background-color': banner.bgColor}" :key="banner._id" v-for="banner in banners">
          <img :src="`${API_URL}/${banner.image}`" alt="" class="image">
          <div class="delete-container">
            <p class="btn delete" v-on:click="deleteBanner(banner._id)">
              Delete
            </p>
          </div>
        </div>
      </div>
    </div>
    <NewBannerModal 
      v-if="newBannerModalOpened" 
      :closeModal="closeNewBannerModal"
    />
  </div>
</template>

<script>
import {API_URL} from '../../const'
import NewBannerModal from './NewBannerModal'
export default {
  components: {
    NewBannerModal
  },
  data() {
    return {
      newBannerModalOpened: false
    }
  },
  created() {
    this.getBanners()
  },
  methods: {
    openNewBannerModal() {
      this.newBannerModalOpened = true
    },
    closeNewBannerModal() {
      this.newBannerModalOpened = false
    },
    getBanners() {
      this.$store.dispatch('bannerModule/getBanners')
    },
    deleteBanner(_id) {
      const confirmed = confirm('Are you sure you want to delete this?')
      confirmed && this.$store.dispatch('bannerModule/deleteBanner', {_id})
    }
  },
  computed: {
    banners() {
      return this.$store.state.bannerModule.banners.data
    },
    API_URL() {
      return API_URL
    }
  }
}
</script>

<style>

</style>