<template>
	<div class="sale-person-form">
		<p class="title">Edit VIP Customer</p>
		<form v-on:submit.prevent="handleFormSubmitted">
			<div class="input-ctn">
				<div class="label">Name</div>
				<input v-model="name" type="text" class="input" placeholder="Name" />
				<input v-model="phoneNumber" type="text" class="input" placeholder="Phone Number" />
				<input v-model="address" type="text" class="input" placeholder="Address" />
			</div>
			<div class="input-ctn">
				<div class="label">Sale Person</div>
				<input
					v-model="recommender"
					type="text"
					class="input"
					placeholder="Sale Person"
                    @focus="openSalePersonModal"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Customer Type</div>
				<input
					v-model="customerTypeName"
					type="text"
					class="input"
					placeholder="Customer Type"
                    @focus="openCustomerTypeModal"
				/>
			</div>
			<button type="submit" class="btn submit">Edit</button>
		</form>
        <SelectSalePersonModal
            :closeModal="closeSalePersonModal"
            v-show="salePersonModalOpened"
            :onSubmit="selectedSalePerson"
        />
        <SelectCustomerTypeModal
        :closeModal="closeCustomerTypeModal"
        v-if="customerTypeModalOpened"
        :onSubmit="selectedCustomerType"
        />
	</div>
</template>

<script>
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
import SelectCustomerTypeModal from "../../components/SelectCustomerTypeModal";
export default {
	name: "EditCustomerForm",
	props: ["edit", "customer"],
    components: {
        SelectSalePersonModal,
        SelectCustomerTypeModal
    },

	data() {
		return {
			loading: false,
            customerTypeModalOpened: false,
            salePersonModalOpened: false,
			name: this.customer.name,
			phoneNumber: this.customer.phoneNumber,
			address: this.customer.address,
			salePerson: this.customer.salePerson._id,
			recommender: this.customer.salePerson.code + ' - ' + this.customer.salePerson.name,
			customerType: this.customer.customerType._id,
			customerTypeName: this.customer.customerType.name,
		};
	},
	methods: {
        openSalePersonModal() {
            this.salePersonModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectSalePersonModelSearchInput").focus();
            })
        },
        closeSalePersonModal() {
            this.salePersonModalOpened = false;
        },
        selectedSalePerson(sale) {
            if(sale) {
                this.recommender = sale.code + ' - ' + sale.name;
                this.salePerson = sale._id;
            } else {
                this.recommender = null;
                this.salePerson = null;
            }
        },
        openCustomerTypeModal() {
            this.customerTypeModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectCustomerTypeModelSearchInput").focus();
            })
        },
        closeCustomerTypeModal() {
            this.customerTypeModalOpened = false;
        },
        selectedCustomerType(customerType) {
            this.customerType = customerType?._id;
            this.customerTypeName = customerType?.name;
        },
		async handleFormSubmitted() {
			this.loading = true;
			await this.edit({ name: this.name, phoneNumber: this.phoneNumber, address: this.address, salePerson: this.salePerson, customerType: this.customerType });
			this.loading = false;
		},
	},
};
</script>

<style></style>
