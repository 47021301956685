<template>
  <div class="modal">
    <div class="modal-content select-category-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <form>
        <input type="text" class="input" placeholder="Search Category">
      </form>
      <div class="category-list">
        <div 
          v-for="c in categories.data" 
          :key="c._id" 
          v-on:click="handleSubmit(c)"
          class="category btn"
        >
          <p>{{c.en}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit'],
  data() {
    return {
      keyword: ''
    }
  },
  created() {
    this.getCategories()
  },
  methods: {
    async getCategories() {
      await this.$store.dispatch('productCategoriesModule/getProductCategories')
    },
    handleSubmit(category) {
      this.onSubmit(category)
      this.closeModal()
    }
  },
  computed: {
    categories() {
      return this.$store.state.productCategoriesModule.categories
    }
  }
}
</script>

<style>

</style>