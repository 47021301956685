import { TOKEN_LABEL } from "../../const";
import { httpErrorHandler, HttpRequest } from "../../handler";

const SET_CURRENT_USER = 'setCurrentUser'
const SET_USER_ROLES = 'setUserRoles'
const SET_LOADING_USER_ROLES = 'setLoadingUserRoles'
const SET_USERS = 'setUsers'
const SET_LOADING_USERS_LISTING = 'setLoadingUsers'
const SET_SUBMITTING_USER = 'setSubmittingUser'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    userRoles: [],
    isLoadingUserRoles: false,
    usersListing: { pageMeta: null, users: [], totalBuyerBalance: 0 },
    isLoadingUsersListing: false,
    isSubmittingUser: false
  },
  mutations: {
    [SET_CURRENT_USER](state, payload) {
      state.currentUser = payload;
    },
    [SET_USER_ROLES](state, payload) {
      state.userRoles = payload;
    },
    [SET_LOADING_USER_ROLES](state, payload) {
      state.isLoadingUserRoles = payload;
    },
    [SET_USERS](state, payload) {
      state.usersListing = payload;
    },
    [SET_LOADING_USERS_LISTING](state, payload) {
      state.isLoadingUsersListing = payload;
    },
    [SET_SUBMITTING_USER](state, payload) {
      state.isSubmittingUser = payload;
    }
  },
  actions: {
    async logIn(context, payload) {
      const url = 'user/other/login'
      const { phoneNumber, password } = payload
      const { success, result, message } = await HttpRequest.publicRequest(url, 'POST', { phoneNumber, password })
      if (success && result.user) {
        if (result.user.role.name !== 'buyer') {
          localStorage.setItem(TOKEN_LABEL, result.currentToken)
          context.dispatch('loadUser')
          return true
        } else {
          httpErrorHandler('Not Admin')
        }
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async loadUser(context, payload) {
      const url = 'user/current'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {})
      if (success) {
        context.commit(SET_CURRENT_USER, result)
        return true
      } else {
        // httpErrorHandler(message)
        return false
      }
    },
    logOut(context, payload) {
      localStorage.removeItem(TOKEN_LABEL)
      return true
    },
    async getUserRoles(context, payload) {
      context.commit(SET_LOADING_USER_ROLES, true)
      const url = 'userRole'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {})
      if (success) {
        context.commit(SET_USER_ROLES, result)

      } else {
        httpErrorHandler(message)
      }
      context.commit(SET_LOADING_USER_ROLES, false)
    },
    clearUsers(context) {
        context.commit(SET_USERS, { pageMeta: null, users: [] })
    },
    async getUsers(context, payload) {
      context.commit(SET_LOADING_USERS_LISTING, true)
      const url = 'user'
      const { query, roles } = payload
      const { role, search, ...rest } = query

      const { success, result, message } = await HttpRequest.authorizedRequest(
          url, 'GET', {}, { ...rest, role: role ? role : undefined, search: search ? search : "", roles: roles }
      )
      if (success) {
        context.commit(SET_USERS, result)
        context.commit(SET_LOADING_USERS_LISTING, false)
        return true
      } else {
        httpErrorHandler(message)
        context.commit(SET_LOADING_USERS_LISTING, false)
        return false
      }
    },
    async exportUsers(context, payload) {
      const url = 'user'
      const { query, roles } = payload
      const { role, search, ...rest } = query

      const { success, result, message } = await HttpRequest.authorizedRequest(
          url, 'GET', {}, { ...rest, role: role ? role : undefined, search: search ? search : "", roles: roles }
      )
      if (success) {
        return result.data
      } else {
        httpErrorHandler(message)
        return []
      }
    },
    async topUpUser(context, payload) {
      const { id, amount, note, method } = payload
      const url = `user/${id}/balanceTransaction`
      const data = {
        amount,
        method,
        note
      }
      const { success, message } = await HttpRequest.authorizedRequest(url, "PATCH", data, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateUserRole(context, payload) {
      const { _id, role_id } = payload
      const url = `user/${_id}/changeRole`
      const { success, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { role: role_id })
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async createUser(context, payload) {
      context.commit(SET_SUBMITTING_USER, true)
      const url = 'user/adminCreate'
      const { success, message } = await HttpRequest.authorizedRequest(url, "POST", payload)
      context.commit(SET_SUBMITTING_USER, false)
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateUser(context, payload) {
      const { _id, salePerson } = payload
      const url = `user/${_id}`
      const { success, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {salePerson})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async changePassword(context, payload) {
      context.commit(SET_SUBMITTING_USER, true)
      const url = `user/${payload.id}/adminChangePassword`;
      const { success, message } = await HttpRequest.authorizedRequest(url, "PATCH", {
        newPassword: payload.password
      });
      context.commit(SET_SUBMITTING_USER, false)
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async deleteUser(context, payload) {
      const { id } = payload
      const url = `user/${id}`
      context.commit(SET_SUBMITTING_USER, true)
      const { success, message } = await HttpRequest.authorizedRequest(url, 'DELETE', {})
      context.commit(SET_SUBMITTING_USER, false)
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async buyerLogin(context, payload) {
        const { _id } = payload
        const url = `user/${_id}/buyerLogin`
        context.commit(SET_SUBMITTING_USER, true)
        const { success, message, result } = await HttpRequest.authorizedRequest(url, 'GET', {})
        context.commit(SET_SUBMITTING_USER, false)
        if (success) {
          return result;
        } else {
          httpErrorHandler(message)
          return false
        }
    },
    async updateToVIP(context, payload) {
      const { _id } = payload
      const url = `user/${_id}/updateToVIP`
      const { success, message } = await HttpRequest.authorizedRequest(url, 'PATCH')
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async removeVIP(context, payload) {
      const { _id } = payload
      const url = `user/${_id}/removeVIP`
      const { success, message } = await HttpRequest.authorizedRequest(url, 'PATCH')
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}
