<template>
  <div class="page dashboard order-details">
    <div class="content">
      <div class="title-bar" style="justify-content: normal;">
        <p class="title">Order Details ({{ order.orderId }})</p>
        <div class="icon" style="
            background-image: url(/icons/copy-text.png); 
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem; 
            height: 2rem;"

            @click="copy(order.orderId, 'ID ')"
        >
        </div>
      </div>
      <router-link to="/orders">Back</router-link>
      <p v-if="isLoading">Fetching Data . . .</p>
      <div class="page-content" v-if="!isLoading">
        <div class="order-info">
          <div class="user-info">
            <p class="bold">
              UserName:
              {{ order.createBy ? order.createBy.name : "(Deleted Account)" }}
            </p>
            <p>
              Receiver Name: {{ order.shippingDetail.name }}
              {{ order.shippingDetail.lastname }}
            </p>
            <p>
              PHone Number:
              {{ order.shippingDetail.phoneNumber }}
            </p>
          </div>
          <div class="shipping-info">
            <p class="bold">Shipping:</p>
            <p>Address: {{ order.shippingDetail.address }}</p>
            <p>Region: {{ order.shippingDetail.region }}</p>
            <!-- <p>Country: {{ order.shippingDetail.country }}</p> -->
            <!-- <p>PostalCode: {{ order.shippingDetail.postalCode }}</p> -->
          </div>
          <div class="shipping-methods">
            <p class="bold">Shipping Setting</p>
            <p>Shipping Method: {{ order.shippingMethod.name }}</p>
            <p>
              Delivery Method:
              {{
              order.transportMethod === 0 ? "Self-pickup" : "Nokor delivery"
              }}
            </p>
            <p>Insurance: {{ order.insurance ? "Yes" : "No" }}</p>
          </div>
            <div class="shipping-methods">
                <p class="bold">
                    <span style="float: left;"> Payment </span>                            
                </p>
                <br>
                <p>Status: 
                    <span v-if="transaction.paid_amount == 0" :class="transaction.confirmedPaymentAt || isConfirmPayment ? 'text-success' : 'text-warning'">{{ transaction.confirmedPaymentAt || isConfirmPayment ? "Confirmed" : "Pending" }}</span>
                    <span v-else class="text-success">Paid</span>
                </p>
                <p>Total Insurance: {{(transaction.totalInsurance.toFixed(2))}}</p>
                <p>Total Service Cost: {{(transaction.totalServiceCost).toFixed(2)}}</p>
                <p>Total: {{(transaction.total).toFixed(2)}}</p>
                <img
                    v-for="image in transaction.transactionUploadImages" :key="image"
                    class="order-image"
                    :src="`${API_URL}/${image.image}`"
                    data-lity 
                    :data-lity-target="`${API_URL}/${image.image}`"
                    width="50px"
                    style="cursor: pointer;"
                    alt=""
                />
            </div>
            <div class="payment-confirmation">
                <p class="bold text-danger">
                    <span style="float: left;"> Refund Images</span>                            
                </p>
                <br>
                <img
                    v-for="image in order.refundImages" :key="image"
                    class="order-image"
                    :src="`${API_URL}/${image.image}`"
                    data-lity 
                    :data-lity-target="`${API_URL}/${image.image}`"
                    width="50px"
                    style="cursor: pointer; margin-right: 5px"
                    alt=""
                />
                <br>
                <button
                @click="openUploadfundImageModel"
                class="btn pay"
                v-if="order.status < 5"
                >Upload Image</button>
            </div>
          <div class="payment-confirmation">
            <p class="bold small">
              International Shipping Cost: $
              {{ order.internationalShippingCost }}
            </p>
            <p class="bold small">China Shipping Cost: $ {{ order.chineseShippingCost }}</p>
            <p class="bold small">Local Delivery Cost: $ {{ order.localDeliveryCost }}</p>
            <p class="bold small">Other Cost: $ {{ order.otherCost }}</p>
            <p class="bold">Total: $ {{ order.internationalShippingCost + order.chineseShippingCost + order.otherCost + order.localDeliveryCost }}</p>
            <p class="bold">User Balance: $ {{ order.userBalance ? order.userBalance.amount.toFixed(2) : 0}}</p>
            <img
                v-for="image in order.uploadImages" :key="image"
                class="order-image"
                :src="`${API_URL}/${image.image}`"
                data-lity 
                :data-lity-target="`${API_URL}/${image.image}`"
                width="50px"
                style="cursor: pointer;"
                alt=""
            />
            <br>
            <button
              v-on:click="openUpdateCostModal"
              class="btn pay"
              v-if="order.status < 5"
            >Update Order</button>
            <button v-on:click="payOrder" class="btn pay" v-if="order.status === 5">Confirm Payment</button>
          </div>
        </div>
        <div class="items-list">
          <div class="status">
            <p class="bold">Order Status:</p>
            <p v-if="updatingStatus">Updating Status . . .</p>
            <div class="status-list" v-if="order.status === 9">
              <p>Rejected</p>
            </div>
            <div class="status-list" v-if="!updatingStatus && order.status !== 9">
              <p v-on:click="updateOrderStatus(0)" :class="`btn active`">Pending</p>
              <p
                :class="`btn ${order.status > 0 ? 'active' : 'not-allow'}`"
              >Ordering</p>
              <p
                :class="`btn ${order.status > 1 ? 'active' : 'not-allow'}`"
              >In China</p>
              <p
                :class="`btn ${order.status > 2 ? 'active' : 'not-allow'}`"
              >Shipping</p>
              <p
                :class="`btn ${order.status > 3 ? 'active' : 'not-allow'}`"
              >In Cambodia</p>
              <p
                v-on:click="updateOrderStatus(5)"
                :class="`btn ${order.status > 4 ? 'active' : ''}`"
              >Wait For Payment</p>
              <p
                v-on:click="updateOrderStatus(6)"
                :class="`btn ${order.status > 5 ? 'active' : ''}`"
              >Paid</p>
              <p
                v-on:click="updateOrderStatus(7)"
                :class="`btn ${order.status > 6 ? 'active' : ''}`"
              >Delivering</p>
              <p
                v-on:click="updateOrderStatus(8)"
                :class="`btn ${order.status > 7 ? 'active' : ''}`"
              >Completed</p>
            </div>
          </div>
          <p class="bold big">
              Items 
              <span @click="rejectOrder" v-if="isCanReject" class="btn refund" style="width: 90px; background-color: #dc3545; color: #ffffff; text-align: center; padding: 0 10px;">Reject</span>
                </p>
                
          <div class="product-list">
            <div class="product-item" :style="product.itemId == itemId ? 'border: 1px solid red;' : null" v-for="(product, index) in order.orderItems" :key="index">
              <div class="text_num" style="background: #333; color: #fff; padding: .1rem .4rem; border-radius: .2rem;">
                <ul>
                  <li>{{ index + 1 }}</li>
                </ul>
              </div>
              <img :src="product.product.mainImage" alt class="image" />
              <div class="product-info">
                  <div style="width:60%; display:inline-block;">
                    <p class="small bold">{{ product.product.name }} <span style="font-size: 1rem;">(x {{ product.amount }})</span></p>

                    <p class="small" v-for="variation in product.product.variations" :key="variation.name">
                        {{ variation.name }}: {{ variation.data[0].value }}
                        <img :src="variation.data[0].image" v-if="variation.data[0].image" data-lity :data-lity-target="variation.data[0].image" alt style="width:32px; height: 32px" />
                    </p>
                    <p class="small bold">
                    Price:
                    {{
                    product.product.price.maxPrice
                    ? `$ ${product.product.price.maxPrice}/per unit`
                    : "Unknown"
                    }}
                    </p>
                    <p class="small bold">Service Cost: $ {{ product.serviceCost }}</p>
                    <p
                    class="small bold"
                    v-if="product.insuranceCost"
                    >Insurance Cost: $ {{ product.insuranceCost }}</p>
                    <a target="_blank" :href="product.product.url" class="link bold">Check Product</a>
                  </div>
                  <div class="btn-group" style="width:40%; display:inline-block;vertical-align: top;">
                    <textarea 
                    v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) || product.note"
                    v-model="product.note" rows="3" style="width: 170px; height: 60px; padding: .3rem; margin-top: 5px; border: 1px solid #6c757d;border-radius: 5px;"
                    :readonly="!['admin', 'financer', 'order'].includes(currentUser.role.name)"
                    >
                    </textarea>
                    <p
                    v-on:click="noteOrderItem(product.itemId, product.note)"
                    v-if="['admin', 'financer', 'order'].includes(currentUser.role.name)"
                    class="btn info" style="width: 100px;"
                    >Update Note</p>
                    <input placeholder="Tracking ID" type="text" style="border: 1px solid #6c757d;border-radius: 5px; width: 150px" v-model="product.trackingId" />
                    <p
                    v-on:click="
                        updateTrackingIdOrderItem(
                        product.itemId,
                        product.trackingId
                        )
                    "
                    class="btn info"
                    style="margin-top: 5px; width: 90px"
                    >
                    Update
                    </p>
                  </div>
              </div>
              <div class="btn-group">
                <div>
                  <input
                  type="checkbox"
                  v-model="product.isArrived"
                  @click="updateOrderArrival(product.itemId)"
                  class="checkbox"
                />
                <span>
                    {{product.isArrived? 'Arrive': "Not Arrived"}}
                </span>
                </div>
                
                <p v-if="product.isApproved" class="approved">Approved</p>
                <p
                  v-if="product.isApproved && [0, 1, 2, 3, 4, 5, 6, 7].includes(order.status)"
                  v-on:click="refundOrderItem(product.itemId)"
                  class="btn refund" style="width: 90px"
                >Refund</p>
                <p v-if="!product.isApproved" class="rejected">Rejected</p>
                <label v-if="product.orderingAt">Ordering At: {{product.orderingAt}}</label>
                <br v-if="product.orderingAt">
                <label v-if="product.inChinaAt">In China At: {{product.inChinaAt}}</label>
                <br v-if="product.inChinaAt">
                <label v-if="product.shippingAt">Shipping At: {{product.shippingAt}}</label>
                <br v-if="product.shippingAt">
                <label v-if="product.inCambodiaAt">In Cambodia At: {{product.inCambodiaAt}}</label>
                <br v-if="product.inCambodiaAt">
                <label v-if="product.chinaMoney && product.status > 0">China Money: {{product.chinaMoney}}</label>
                <br v-if="product.chinaMoney && product.status > 0">
                <input v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && product.isApproved && order.status == 0 && order.status >= product.status" placeholder="China Money" type="number" setp="0.01" style="border: 1px solid #6c757d;border-radius: 5px; width: 200px" v-model="product.chinaMoney" />
                <br v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && product.isApproved && order.status == 0 && order.status >= product.status">
                <input v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && product.isApproved && order.status < 4 && product.status < 4" type="datetime-local" style="border: 1px solid #6c757d;border-radius: 5px; width: 200px" v-model="product.nextStatusDate">
                <p
                v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && product.isApproved && order.status < 4 && product.status < 4"
                v-on:click="nextStatusOrderItem(product.itemId, product.trackingId, product.nextStatusDate, product.chinaMoney)"
                class="btn info" style="margin-top: 5px; width: 90px"
                >{{ getNextStatus(product.status) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateCostModal
      v-if="updateCostModalOpened"
      :closeModal="closeUpdateCostModal"
      :onSubmit="updateExtraPrice"
      :otherCost="order.otherCost"
      :chineseShippingCost="order.chineseShippingCost"
      :internationalShippingCost="order.internationalShippingCost"
      :localDeliveryCost="order.localDeliveryCost"
    />
    <UploadFileModal
        v-if="uploadfundImageModelOpened" 
        :closeModal="closeUploadfundImageModel"
        :handleFormSubmit="uploadfundImageModelSubmit"
    />
  </div>
</template>

<script>
import UpdateCostModal from './UpdateCostModal'
require('lity');
import { API_URL } from "../../const";
import { FileUpload } from "../../handler";
import UploadFileModal from '../../components/UploadFileModal'
import moment from "moment";
export default {
    components: {
        UpdateCostModal,
        FileUpload,
        UploadFileModal
    },
	data() {
    
		return {
			isLoading: true,
      updatingStatus: false,
      updateCostModalOpened: false,
      uploadfundImageModelOpened: false,
      API_URL:API_URL,
      itemId: this.$route.query.itemId
		};
	},
	async created() {
		await this.getOrderDetails();
        await this.getTransactionDetails(this.$store.state.orderModule.orderDetails.transaction);
        
        if(this.itemId) {
            do{
                this.order && setTimeout(() => this.arrivedCambodia(this.itemId), 1000);
            } while(!this.order);
        }
	},
	methods: {
        getNextStatus(status) {
            switch(status) {
                case 0:
                    return 'Ordering';
                case 1:
                    return 'In China';
                case 2:
                    return 'Shipping';
                case 3:
                    return 'In Cambodia';
            }
            return null;
        },
        copy(text, label) {
            if (window.clipboardData && window.clipboardData.setData) {
                alert('Copy ' + label + 'successfully');
                // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
                return window.clipboardData.setData("Text", text);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    alert('Copy ' + label + 'successfully');
                    return document.execCommand("copy"); // Security exception may be thrown by some browsers.
                } catch (ex) {
                    alert('Copy failed');
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },
        async uploadfundImageModelSubmit(file) {
            if(file) {
                this.uploadfundImageModelOpened = false;
                const fileObj = await FileUpload.uploadFile(file);
                const success = await this.$store.dispatch("orderModule/uploadRefundImage", {
                    image: fileObj.path,
                    id: this.order._id
                });
                if(success) {
                    alert('Upload refund image successfully');
                } else {
                    alert('Error something went wrong.');
                }
            }
        },
        openUploadfundImageModel() {
            this.uploadfundImageModelOpened = true;
        },
        closeUploadfundImageModel() {
            this.uploadfundImageModelOpened = false;
        },
        closeUpdateCostModal() {
            this.updateCostModalOpened = false
        },
        openUpdateCostModal() {
            this.updateCostModalOpened = true
        },
        async updateOrderArrival(itemId) {
            const confirmed = confirm("Are you sure this order has arrived?");
            if(confirmed) {
                this.$store.dispatch('orderModule/orderArrived', {_id: this.orderId, itemId})
            }
        },
		async getOrderDetails() {
			await this.$store.dispatch("orderModule/getOrderDetails", {
				_id: this.orderId,
			});
			this.isLoading = false;
		},
        async getTransactionDetails(transactionId) {
            if(transactionId) {
                await this.$store.dispatch("transactionsModule/getTransactionDetails", {
                    _id: transactionId,
                });
                this.isLoading = false;
            }
        },
		async updateOrderStatus(status) {
			const confirmed = confirm("Are you sure you want to updated status?");
			if (confirmed) {
				this.updatingStatus = true;
				await this.$store.dispatch("orderModule/updateStatus", {
					_id: this.order._id,
					status,
				});
				this.updatingStatus = false;
			}
		},
		async refundOrderItem(itemId) {
			const confirmed = confirm('Are you sure you want to refund this item?')
			if (confirmed) {
				this.$store.dispatch('orderModule/refundOrderItem', {orderId: this.orderId, itemId})
			}
		},
		async noteOrderItem(itemId, note) {
			const confirmed = confirm('Are you sure you want to note this item?')
			if (confirmed) {
				this.$store.dispatch('orderModule/noteOrderItem', {orderId: this.orderId, itemId, note})
			}
		},
        async arrivedCambodia(itemId) {
            const item = this.order.orderItems.find(item => item.itemId == itemId);

            if(item && item.status == 3) {
			    const confirmed = confirm('Are you sure this item arrived in cambodia?')
                
                if (confirmed) {
                    this.$store.dispatch('orderModule/nextStatusOrderItem', {
                        orderId: this.orderId, 
                        itemId,
                        date: moment().format('YYYY-MM-DD h:mm A')
                    });
                }
            }
        },
		async nextStatusOrderItem(itemId, trackingId, date, chinaMoney) {
			const confirmed = confirm('Are you sure you want to update status this item?')
			if (confirmed) {
                date = date ? moment(date) : moment();
				this.$store.dispatch('orderModule/nextStatusOrderItem', {
                    orderId: this.orderId, 
                    itemId,
                    date: date.format('YYYY-MM-DD h:mm A'),
                    trackingId,
                    chinaMoney
                })
			}
		},

        async updateTrackingIdOrderItem(itemId, trackingId) {
            const confirmed = confirm(
                "Are you sure you want to update tracking id this item?"
            );
            if (confirmed) {
                const success = await this.$store.dispatch("orderModule/trackingIdOrderItem", {
                orderId: this.orderId,
                itemId,
                trackingId,
                });

                if(success) {
                    alert('Update tracking id suceesfully')
                } else {
                    alert('Fail to update tracking id')
                }
            }
        },
		async updateExtraPrice(
			{otherCost,
			localDeliveryCost,
			internationalShippingCost,
			chineseShippingCost}
		) {
			const done = await this.$store.dispatch("orderModule/updateCost", {
				_id: this.orderId,
				otherCost,
				localDeliveryCost,
				internationalShippingCost,
				chineseShippingCost,
      });
      if (done) {
        this.getOrderDetails()
        this.closeUpdateCostModal()
        alert("Updated")
      }
		},
		async payOrder() {
			const confirmed = confirm(
				"Are you sure, this order is already completed 2nd payment?"
			);
			if (confirmed) {
				const done = await this.$store.dispatch("orderModule/payOrder", {
					_id: this.orderId,
				});
				if (done) {
					this.getOrderDetails();
				}
			}
        },
		async rejectOrder() {
			const confirmed = confirm(
				"Are you sure, you want to reject all item in this order?"
			);
			if (confirmed) {
				const done = await this.$store.dispatch("orderModule/rejectOrder", {
					_id: this.orderId,
				});
				if (done) {
					this.getOrderDetails();
				}
			}
        },
	},
	computed: {
		orderId() {
			return this.$route.params.id;
		},
		order() {
			return this.$store.state.orderModule.orderDetails;
		},
        transaction() {
            let transaction = this.$store.state.transactionsModule.transactionDetails;
            let total = 0;
            let totalInsurance = 0;
            let totalServiceCost = 0;
            transaction.transactionItems?.map((i) => {
                total += ((i.product.price.maxPrice * i.amount) + i.serviceCost + i.insuranceCost);
                totalInsurance += i.insuranceCost;
                totalServiceCost += i.serviceCost;
            });
            transaction.total = total;
            transaction.totalInsurance = totalInsurance;
            transaction.totalServiceCost = totalServiceCost;
            return transaction
        },
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        },
        isCanReject() {
            if(this.order.status === 9) {
                return false;
            }

            if(this.currentUser.role.name !== 'admin' && this.currentUser.role.name !== 'financer') {
                return false;
            }

            const isHasApproved = this.order.orderItems.findIndex(item => {
                return item.isApproved;
            })

            return isHasApproved === -1;
        }
	},
};
</script>

<style src="lity/dist/lity.min.css"></style>
<style scoped>
    .not-allow {
        cursor: not-allowed;
    }
</style>
