<template>
  <div class="top-bar-component">
    <div class="navigation">
        <div
            class="element"
            v-for="topBarRoute in topBarRoutes"
            v-bind:key="topBarRoute.title"
            v-on:click="topBarRoute.route == '/dashboard' ? null : goToRoute(topBarRoute.route)"
        >
            <div class="title">{{topBarRoute.title}}</div>
            <div class="icon" :style="{'background-image': 'url(/images/Forward.png)'}"></div>
        </div>
        <div class="element">
            <div @click="openScanningModal" class="icon" :style="{'background-image': 'url(/images/scanner.png)', 'background-repeat': 'no-repeat', width: '25px', cursor: 'pointer'}"></div>
        </div>
    </div>
    <div class="modal" v-show="isScanning">
        <div class="modal-content new-banner-modal">
            <p class="btn" v-on:click="isScanning = false">x</p>
            <p>Scan Order or VIP Invoince Item tracking ID</p>
            <input @keyup.enter="getOrderByTrackingID" type="text" id="selectScanningTrackingIDSearchInput" v-model="trackingId" class="input" style="border: border: 1px solid gray;" placeholder="Tracking ID">
            <br>
            <img src="/gif/barcode_scanning.gif" style="width: 250px; object-fit: none;" alt="">
        </div>
    </div>
    <div class="menu">
        <p>{{currentUser.role.name}}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "TopBarComponent",
  data() {
      return {
          isScanning: false,
          trackingId: null
      }
  },
  computed: {
    topBarRoutes() {
      return this.$store.state.mainLayoutModule.topBarRoutes;
    },
    currentUser() {
      return this.$store.state.usersModule.currentUser
    }
  },
  methods: {
    openScanningModal() {
        this.isScanning = true;
        setTimeout(() => {
            document.getElementById("selectScanningTrackingIDSearchInput").focus();
        })
    },
    async getOrderByTrackingID() {
        if(this.trackingId) {
            const res = await this.$store.dispatch("orderModule/getOrderByTrackingId", {
                trackingId: this.trackingId
            });

            if(res && res.order) {
                this.isScanning=false;
                this.trackingId=null;
                this.$router.push({'path': '/orders/' + res.order._id + "?itemId=" + res.item?.itemId})
            } else if(res && res.invoice) {
                this.isScanning=false;
                this.trackingId=null;
                this.$router.push({'path': '/invoices/' + res.invoice._id + "?itemId=" + res.item?.itemId})
            } else {
                alert('Tracking ID: '+this.trackingId+' not found!');
                this.trackingId=null;
            }
        }
        // const await = 
    },
    goToRoute(route) {
        this.$router.push({ path: route });
    }
  }
};
</script>
<style scoped>
</style>
