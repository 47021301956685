<template>
  <div class="page log-in-page">
    <p class="title">Forgot Password?</p>
    <p
      class="description"
    >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
    <div class="input-ctn">
      <div class="label">Email</div>
      <input type="text" class="input" v-model="email" placeholder="Email" />
    </div>
    <div class="submit">
      <div class="label">Submit</div>
      <div class="icon"></div>
    </div>

    <div class="go-back" v-on:click="goBack()">Go Back</div>
  </div>
</template>


<script>
export default {
  name: "ForgotPasswordPage",
  methods: {
    goBack() {
      this.$router.push({ path: "/auth/login" });
    }
  }
};
</script>
<style scoped>
</style>
