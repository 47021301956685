<template>
  <div class="edit-user">
    <p class="title">Edit User</p>
    <form v-on:submit="handleFormSubmit" autocomplete="off">
      <div class="input-ctn">
        <div class="label">Username</div>
        <input v-model="name" class="input" type="text" placeholder="Username" />
      </div>
      <div class="input-ctn">
        <div class="label">Phone Number</div>
        <input v-model="phoneNumber" type="number" class="input" placeholder="Phone Number" />
      </div>
      <div class="input-ctn" v-if="['admin', 'order'].includes(currentUser.role.name) && role == 'buyer'">
        <div class="label">Sale Person</div>
        <input
            v-model="saleLabel"
            type="text"
            class="input"
            placeholder="Sale Person"
            @focus="openSaleModal"
        />
      </div>
      <button class="submit btn" v-if="['admin', 'order'].includes(currentUser.role.name) && role == 'buyer'">Save</button>
    </form>
    <p
      v-if="currentUser.role.name === 'admin'"
      class="btn action-button"
      @click="toggleActionMenu('ChangePassword')"
    >Change Password</p>
    <p
      v-if="(currentUser.role.name === 'admin' || currentUser.role.name === 'financer') && user.role.name === 'buyer'"
      class="btn action-button"
      @click="buyerLogin()"
    >Login</p>
    <p
    <!-- <p class="btn action-button" @click="toggleActionMenu('shippingDetails')">View Shipping Details</p> -->
    <p class="btn action-button"
      v-if="(currentUser.role.name === 'admin' || currentUser.role.name === 'financer') && user.role.name === 'buyer'"
     @click="toggleActionMenu('adjustBalance')">Balance</p>
    <p
      v-if="(currentUser.role.name === 'admin' || currentUser.role.name === 'financer') && user.role.name === 'buyer' && user.buyerType != 'vip'"
      class="btn action-button"
      @click="updateBuyerToVIP()"
    >Make VIP</p>
    <p
      v-if="(currentUser.role.name === 'admin' || currentUser.role.name === 'financer') && user.role.name === 'buyer' && user.buyerType == 'vip'"
      class="btn action-button"
      @click="removeBuyerVIP()"
    >Remove VIP</p>
    <p
      v-if="user.role.name === 'buyer' && currentUser.role.name === 'admin'"
      class="btn action-button"
      @click="toggleActionMenu('newMerchant')"
    >Make Merchant</p>
    <p
      v-if="user.role.name === 'buyer' && currentUser.role.name === 'admin'"
      class="btn action-button"
      @click="updateRole(moderatorId)"
    >Make Moderator</p>
    <p
      v-if="user.role.name === 'buyer' && currentUser.role.name === 'admin'"
      class="btn action-button"
      @click="updateRole(financerId)"
    >Make Financer</p>
    <p
      v-if="user.role.name === 'buyer' && currentUser.role.name === 'admin'"
      class="btn action-button"
      @click="updateRole(orderId)"
    >Make Order</p>
    <p
      v-if="currentUser.role.name === 'admin' && user.role.name === 'buyer'"
      class = "btn action-button"
      @click="updateRole(adminId)"
    >Make Admin</p>
    <p
      v-if="['moderator', 'financer', 'order'].includes(user.role.name)"
      class="btn action-button"
      @click="updateRole(buyerId)"
    >Remove Role</p>
    <p
      v-if="['admin'].includes(user.role.name)"
      class="btn action-button"
      @click="updateRole(moderatorId)"
      >Make Moderator</p>
    <!-- <p class="btn action-button" @click="toggleActionMenu('editMerchant')">EditMerchant</p> -->
    <SelectSalePersonModal
        :closeModal="closeSaleModal"
        v-show="saleModalOpened"
        :onSubmit="selectedSale"
    />
  </div>
</template>

<script>
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
export default {
  name: "EditUser",
  props: ["user", "toggleActionMenu", "getUsers", "roles"],
  components: {
    SelectSalePersonModal
  },
  data() {
    return {
        _id: this.user._id,
        name: this.user.name,
        role: this.user.role ? this.user.role.name : null,
        phoneNumber: this.user.phoneNumber,
        salePerson: this.user.salePerson ? this.user.salePerson._id : null,
        saleLabel: this.user.salePerson ? this.user.salePerson.code + ' - ' + this.user.salePerson.name : null,
        saleModalOpened: false,
    };
  },
  methods: {
    selectedSale(sale) {
        if(sale) {
            this.saleLabel = sale.code + ' - ' + sale.name;
            this.salePerson = sale._id;
        } else {
            this.saleLabel = null;
            this.salePerson = null;
        }
    },
    openSaleModal() {
        this.saleModalOpened = true;
        setTimeout(() => {
            document.getElementById("selectSalePersonModelSearchInput").focus();
        })
    },
    closeSaleModal() {
        this.saleModalOpened = false;
    },
    async updateRole(role_id) {
      const updated = await this.$store.dispatch("usersModule/updateUserRole", {
        _id: this.user._id,
        role_id,
      });
      if (updated) {
        this.toggleActionMenu("");
        this.getUsers();
      }
    },
    async updateBuyerToVIP() {
      const updated = await this.$store.dispatch("usersModule/updateToVIP", {
        _id: this.user._id,
      });
      if (updated) {
        this.toggleActionMenu("");
        this.getUsers();
      }
    },
    async removeBuyerVIP() {
      const updated = await this.$store.dispatch("usersModule/removeVIP", {
        _id: this.user._id,
      });
      if (updated) {
        this.toggleActionMenu("");
        this.getUsers();
      }
    },
    async handleFormSubmit(e) {
        e.preventDefault();
        if(!['admin', 'order'].includes(this.currentUser.role.name) || this.role != 'buyer') {
            return;
        }

        const updated = await this.$store.dispatch("usersModule/updateUser", {
            _id: this.user._id,
            salePerson: this.salePerson
        });

        if (updated) {
            this.toggleActionMenu("");
            this.getUsers();
        }
    },
    goToShippingDetails() {
      this.toggleActionMenu("shippingDetails");
    },
    async buyerLogin() {
      const res = await this.$store.dispatch("usersModule/buyerLogin", {
        _id: this.user._id
      });

      if (res) {
          window.open(res);
      }
    }
  },
  computed: {
    moderatorId() {
      const moderator = this.roles.data.filter(r => (r.name === 'moderator'))
      return moderator ? moderator[0]._id : ''
    },
    orderId() {
      const order = this.roles.data.filter(r => (r.name === 'order'))
      return order ? order[0]._id : ''
    },
    financerId() {
      const financer = this.roles.data.filter(r => (r.name === 'financer'))
      return financer ? financer[0]._id : ''
    },
    buyerId() {
      const buyer = this.roles.data.filter(r => (r.name === 'buyer'))
      return buyer ? buyer[0]._id : ''
    },
    adminId() {
      const admin = this.roles.data.filter(r => (r.name === 'admin'))
      return admin ? admin[0]._id : ''
    },
    currentUser() {
      return this.$store.state.usersModule.currentUser
    }
  },
};
</script>

<style>
</style>
