<template>
  <div class="page dashboard sale-person-dashboard">
    <div class="content">
      <div
				v-if="isActionMenuOpen"
				class="action-menu-backdrop"
				v-on:click="toggleActionMenu('')"
			></div>

      <div class="title-bar">
        <div class="title">VIP Customer</div>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">New</p>
          </div>
        </div>
      </div>
        <input placeholder="Search by name,code,phone number..." type="text" class="search" v-model="search" />
            <input
                v-model="recommender"
                type="text"
                class="search"
                style="margin-left: 10px"
                placeholder="Sale Person"
                @focus="openSalePersonModal"
            />
            <input
                v-model="customerTypeName"
                type="text"
                class="search"
                style="margin-left: 10px"
                placeholder="Customer Type"
                @focus="openCustomerTypeModal"
            />
            <br>
            <input type="date" class="search" style="width: 20%; margin-top:10px" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; margin-bottom: 10px; width: 20%" v-model="query.toDate">
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
      <ListingComponent 
        model="data"
        :isLoading="isLoading"
        :data="customer"
        :query.sync="query"
        :refresh="getListing"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
        :deleteRecord="destroy"
      />
    </div>
        <div :class="`action-menu ${isActionMenuOpen ? 'active': '' }`">
            <div class="btn close" v-on:click="toggleActionMenu('')"></div>
            <NewCustomerForm 
                v-if="isActionMenuOpen === 'new'"
                :addCustomer="addCustomer"
            />
            <EditCustomerForm 
                v-if="isActionMenuOpen === 'read'"
                :edit="edit"
                :customer="focusedPerson"
            />
        </div>
        <SelectSalePersonModal
            :closeModal="closeSalePersonModal"
            v-show="salePersonModalOpened"
            :onSubmit="selectedSalePerson"
        />
        <SelectCustomerTypeModal
        :closeModal="closeCustomerTypeModal"
        v-if="customerTypeModalOpened"
        :onSubmit="selectedCustomerType"
        />
  </div>
</template>

<script>
import ListingComponent from '../../components/ListingComponent'
import NewCustomerForm from './NewCustomerForm'
import EditCustomerForm from './EditCustomerForm'
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
import SelectCustomerTypeModal from "../../components/SelectCustomerTypeModal";
import moment from "moment";

export default {
  name: "CustomerDashboard",
  components: {
    SelectSalePersonModal,
    SelectCustomerTypeModal,
    ListingComponent,
    NewCustomerForm,
    EditCustomerForm
  },
  data() {
    return {
        focusedPerson: {},
        isActionMenuOpen: "",
        isLoading: true,
        search: null,
        searchTimeout: null,
        query: {
            order: "ASC",
            page: 1,
            limit: 10,
            fromDate: null,
            toDate: null
        },
        customerTypeModalOpened: false,
        salePersonModalOpened: false,
        salePerson: null,
        recommender: null,
        customerType: null,
        customerTypeName: null,
        excelName: 'VIP-Customer-'+moment().format('YYYY-MM-DD')+'.xls'
    }
  },
  mounted() {
    this.getListing()
  },
  methods: {
        async excelData() {
            const res = await this.$store.dispatch("vipCustomerModule/get", {
                query: {
                    ...this.query,
                    search: this.search,
                    customerType: this.customerType,
                    salePerson: this.salePerson,
                    export: true
                }
            });

            return res.data.map((record, index) => {
                return {
                    '#': index + 1,
                    'Name': record.name + " ("+record.code+")",
                    'Phone Number': record.phoneNumber,
                    'Address': record.address,
                    'Sale Person': record.salePerson ? record.salePerson.name  : 'N/A',
                    'Type': record.customerType ? record.customerType.name  : 'N/A',
                    'Date': moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                };
            });
        },
    openSalePersonModal() {
        this.salePersonModalOpened = true;
        setTimeout(() => {
            document.getElementById("selectSalePersonModelSearchInput").focus();
        })
    },
    closeSalePersonModal() {
        this.salePersonModalOpened = false;
    },
    selectedSalePerson(sale) {
        if(sale) {
            this.recommender = sale.code + ' - ' + sale.name;
            this.salePerson = sale._id;
        } else {
            this.recommender = null;
            this.salePerson = null;
        }
    },
    openCustomerTypeModal() {
        this.customerTypeModalOpened = true;
        setTimeout(() => {
            document.getElementById("selectCustomerTypeModelSearchInput").focus();
        })
    },
    closeCustomerTypeModal() {
        this.customerTypeModalOpened = false;
    },
    selectedCustomerType(customerType) {
        this.customerType = customerType?._id;
        this.customerTypeName = customerType?.name;
    },
    async getListing() {
      this.isLoading = true
      await this.$store.dispatch("vipCustomerModule/get", {
        query: {
            ...this.query,
            search: this.search,
            customerType: this.customerType,
            salePerson: this.salePerson
        }
      })
      this.isLoading = false
    },
    async addCustomer(payload) {
      const created = await this.$store.dispatch("vipCustomerModule/create", payload)
      if(created) {
        this.getListing()
        this.toggleActionMenu("")
      }
    },
    async edit(payload) {
      const updated = await this.$store.dispatch("vipCustomerModule/edit", {
        _id: this.focusedPerson._id,
        ...payload
      })
      if(updated) {
        this.getListing() 
        this.toggleActionMenu("")
      }
    },
    async destroy(item) {
        const confirmed = confirm('Are you sure you want to delete this?')
        
        if(confirmed) {
            const res = await this.$store.dispatch("vipCustomerModule/delete", {
                _id: item._id
            })
            if(res) {
                this.getListing() 
                this.toggleActionMenu("")
            }
        }
    },
    toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedPerson = {...payload};
				}
			}
			this.isActionMenuOpen = menu;
		},
    },
    watch: {
        query: {
            deep: true,
            handler: function () {
                if (this.query.page >= 1) {
                    this.getListing();
                }
            },
        },
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getListing(), 500
            )
        },
        salePerson: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getListing(), 500
            )
        },
        customerType: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getListing(), 500
            )
        }
    },
    computed: {
        listingOptions() {
        return {
            hasSelect: false,
            actions: ['delete'],
            queryOptions: {
                sortBy: [],
                sortDirection: [
                    {
                    label: "Ascending",
                    value: "ASC"
                    }, 
                    { 
                    label: "Descending",
                    value: "DESC"
                    }
                ]
            }, 
            schema: [
                { 
                    label: "Name", 
                    value: (record) => {
                        return record.name + " ("+record.code+")"
                    }
                },
                { label: "phoneNumber", key: "phoneNumber" },
                { label: "Address", key: "address"},
                { 
                    label: "Customer Type", 
                    value: (record) => {
                        return record.customerType?.name;
                    },
                },
                { 
                    label: "Sale Person", 
                    value: (record) => {
                        return record.salePerson?.name;
                    },
                },
                {
                    label: "Created At",
                    key: "createdAt",
                    value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                },
            ]
        }
    },
    customer() {
        return this.$store.state.vipCustomerModule.customer
    }
  }
}
</script>

<style>

</style>