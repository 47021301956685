<template>
  <div class="new-product page dashboard">
    <div class="content">
      <div class="title-bar">
        <div class="title">New Product</div>
      </div>
      <form v-on:submit.prevent="createProduct">
        <div class="page-content">
          <div class="product-image">
            <label
              :style="{
                backgroundImage: `url(${
                  product.mainImage ? product.mainImage.path : ''
                })`,
              }"
              for="main-image"
              class="main-image btn"
            >
              <p v-if="!product.mainImage">Select an image</p>
            </label>
            <input id="main-image" class="file-input" type="file" @change="handleMainImageSelected" />
            <div class="image-list">
              <img
                :key="idx"
                v-for="(image, idx) in product.listImage"
                alt
                :src="image.path"
                class="image"
              />
              <label for="list-image" class="add-image btn">Add</label>
              <input id="list-image" type="file" class="file-input" @change="addListImage" />
            </div>
          </div>
          <div class="product-info">
            <div class="product-detail">
              <input type="text" class="input" v-model="product.name" placeholder="Product Name" />
              <div v-if="!this.product.category" class="row category">
                <p class="btn" v-on:click="openCategoryModal">Category</p>
              </div>
              <div v-if="this.product.category" class="row selected-category">
                <p>{{ this.product.category.en }}</p>
                <p class="btn" v-on:click="openCategoryModal">Change</p>
              </div>
              <input type="text" class="input" v-model="product.tag" placeholder="Tags" />
              <select class="input" v-model="product.type">
                  <option selected value="normal">Normal</option>
                  <option value="spacial">Spacial</option>
                  <option value="1688">1688</option>
              </select>
              <input
                type="text"
                class="input"
                v-model="product.price.maxPrice"
                placeholder="Default Price"
              />
              <input
                type="text"
                class="input"
                v-model="product.promoPrice.maxPrice"
                placeholder="Promo Price"
              />
            </div>
            <div class="variations">
              <div class="add-variation">
                <p class="btn" v-on:click="openNewVariationModal">Add Variation</p>
              </div>
              <div
                class="variation-item"
                v-for="(variation, vIdx) in product.variations"
                :key="vIdx"
              >
                <div class="vairation-header">
                  <p class="bold">Name: {{ variation.name }}</p>
                  <p class="remove-variation btn" v-on:click="removeVariation(vIdx)">Remove</p>
                </div>
                <div class="option-list">
                  <div class="option" v-for="(option, idx) in variation.data" :key="idx">
                    <p class="btn remove-option" v-on:click="removeOptions(vIdx, idx)">x</p>
                    <div
                      v-if="variation.hasImage"
                      class="option-image"
                      :style="`backgroundImage: url(${option.image.path})`"
                    ></div>
                    <div class="option-info">
                      <p class="bold">{{ option.value }}</p>
                    </div>
                  </div>
                  <div
                    class="add-option btn"
                    v-on:click="openVariationOptionModal(vIdx, variation)"
                  >
                    <p>Add Option</p>
                  </div>
                </div>
              </div>
              <div class="sku-map">
                <form v-if="product.variations.length > 0" v-on:submit.prevent="addSkuMap">
                  <table>
                    <tr>
                      <th v-if="product.variations.length > 0">Variation 1</th>
                      <th v-if="product.variations.length > 1">Variation 2</th>
                      <th>Price</th>
                      <th>Promo price</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <td v-if="product.variations.length > 0">
                        <select class="input" v-model="newSkuMap.variation1">
                          <option
                            v-for="(option, key) in product.variations[0].data"
                            :key="key"
                            :value="option.value"
                          >{{ option.value }}</option>
                        </select>
                      </td>
                      <td v-if="product.variations.length > 1">
                        <select class="input" v-model="newSkuMap.variation2">
                          <option
                            v-for="(option, key) in product.variations[1].data"
                            :key="key"
                            :value="option.value"
                          >{{ option.value }}</option>
                        </select>
                      </td>
                      <td>
                        <input class="input" type="text" v-model="newSkuMap.price" />
                      </td>
                      <td>
                        <input class="input" type="text" v-model="newSkuMap.promoPrice" />
                      </td>
                      <td>
                        <input class="btn" type="submit" value="Add" />
                      </td>
                    </tr>
                    <tr v-for="(price, idx) in skuMap" :key="idx">
                      <td v-if="product.variations.length > 0">{{ price.variation1 }}</td>
                      <td v-if="product.variations.length > 1">{{ price.variation2 }}</td>

                      <td>{{ price.price }}</td>
                      <td>{{ price.promoPrice }}</td>
                      <td v-on:click="deleteSkuMap(idx)">Delete</td>
                    </tr>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="add-product">
          <button type="submit" class="btn" v-if="!uploadingImage">Add product</button>
          <p class="loading" v-if="uploadingImage">Uploading Images . . .</p>
        </div>
      </form>
    </div>
    <NewVariationModal
      :closeModal="closeNewVariationModal"
      v-if="newVariationModalOpened"
      :onSubmit="addVariation"
    />
    <SelectCategoryModal
      :closeModal="closeCategoryModal"
      v-if="categoryModalOpened"
      :onSubmit="selectCategory"
    />
    <VariationOptionModal
      :closeModal="closeVariationOptionModal"
      v-if="variationOptionModalOpened"
      :hasImage="focusedVariation.variation.hasImage"
      :onSubmit="addOption"
    />
  </div>
</template>
<script>
import NewVariationModal from "./NewVariationModal";
import SelectCategoryModal from "./SelectCategoryModal";
import VariationOptionModal from "./VariationOptionModal";
import { FileUpload } from "../../handler";
export default {
  components: {
    NewVariationModal,
    SelectCategoryModal,
    VariationOptionModal,
  },
  data() {
    return {
      newVariationModalOpened: false,
      categoryModalOpened: false,
      variationOptionModalOpened: false,
      uploadingImage: false,
      product: {
        listImagePath: [],
        mainImage: null,
        listImage: [],
        name: "",
        type: "normal",
        price: {
          minPrice: 0,
          maxPrice: null,
        },
        promoPrice: {
          minPrice: 0,
          maxPrice: null
        },
        variations: [],
      },
      focusedVariation: null,
      skuMap: [],
      newSkuMap: {
        variation1: null,
        variation1: null,
        price: null,
        promoPrice: null
      },
    };
  },
  methods: {
    openNewVariationModal() {
      this.newVariationModalOpened = true;
    },
    closeNewVariationModal() {
      this.newVariationModalOpened = false;
    },
    openCategoryModal() {
      this.categoryModalOpened = true;
    },
    closeCategoryModal() {
      this.categoryModalOpened = false;
    },
    openVariationOptionModal(idx, variation) {
      this.focusedVariation = { idx, variation };
      this.variationOptionModalOpened = true;
    },
    closeVariationOptionModal() {
      this.variationOptionModalOpened = false;
    },
    handleMainImageSelected(e) {
      const image = e.target.files[0];
      this.loading = true;
      this.product.mainImage = image;
      this.product.mainImage.path = URL.createObjectURL(this.product.mainImage);
      this.loading = false;
    },
    addListImage(e) {
      const image = e.target.files[0];
      image.path = URL.createObjectURL(image);
      this.product.listImage = [...this.product.listImage, image];
    },
    removeListImage(idx) {
      this.product.listImage = this.product.listImage.filter(
        (l, index) => (index, idx)
      );
    },
    addVariation(variation) {
      this.product.variations = [...this.product.variations, variation];
    },
    removeVariation(idx) {
      this.product.variations = this.product.variations.filter(
        (v, index) => idx !== index
      );
    },
    addOption(option) {
      if (
        this.product.variations[this.focusedVariation.idx].data
          .map((d) => d.value)
          .includes(option.value)
      ) {
        alert("Option already exist");
      } else {
        this.product.variations[this.focusedVariation.idx].data = [
          ...this.product.variations[this.focusedVariation.idx].data,
          option,
        ];
      }
    },
    removeOptions(vIdx, idx) {
      this.product.variations[vIdx].data = this.product.variations[
        vIdx
      ].data.filter((o, index) => idx !== index);
    },
    addSkuMap(skuMap) {
      this.skuMap = [...this.skuMap, this.newSkuMap];
      this.newSkuMap = {
        variation1: null,
        variation1: null,
        price: null,
        promoPrice: null
      };
    },
    deleteSkuMap(idx) {
      this.skuMap = this.skuMap.filter((m, index) => idx !== index);
    },
    selectCategory(category) {
      this.product.category = category;
    },
    async createProduct() {
      this.uploadingImage = true;
      const mainImageFile = await FileUpload.uploadFile(this.product.mainImage);
      this.product.mainImagePath = mainImageFile.path;
      for (let [idx, i] of this.product.listImage.entries()) {
        const file = await FileUpload.uploadFile(i);
        this.product.listImagePath[idx] = file.path;
      }
      for (let [vIdx, v] of this.product.variations.entries()) {
        if (v.hasImage) {
          for (let [oIdx, o] of v.data.entries()) {
            const optionImage = await FileUpload.uploadFile(o.image);
            this.product.variations[vIdx].data[oIdx].imagePath =
              optionImage.path;
          }
        }
      }
      const data = {
        name: this.product.name,
        mainImage: this.product.mainImagePath,
        listImage: this.product.listImagePath,
        price: {
          minPrice: this.product.price.maxPrice,
          maxPrice: this.product.price.maxPrice,
        },
        promoPrice: {
          minPrice: this.product.promoPrice.maxPrice,
          maxPrice: this.product.promoPrice.maxPrice
        },
        merchant: this.merchantId,
        tag: this.product.tag,
        type: this.product.type,
        category: this.product.category?._id,
        variations: this.product.variations.map((v) => {
          return {
            name: v.name,
            data: v.data.map((o) => ({
              value: o.value,
              image: o.imagePath ? o.imagePath : undefined,
            })),
          };
        }),
        skuMap: this.skuMap.map((m) => {
          let skuMap = m.variation2
            ? `${m.variation1.toString()};${m.variation2.toString}`
            : m.variation1.toString();
          return {
            skuMap,
            price: m.price,
            promoPrice: m.promoPrice
          };
        }),
      };
      const done = await this.$store.dispatch("productsModule/createProduct", {
        data,
      });

      this.uploadingImage = false;
      if (done) {
        this.$router.go(-1);
      }
    },
  },
  computed: {
    merchantId() {
      return this.$route.query.merchantId;
    },
  },
};
</script>

<style></style>
