import { HttpRequest, httpErrorHandler } from "../../handler"
import { update } from "lodash"

export default {
  namespaced: true,
  state: {
    exchangeRates: {
      pageMeta: null, methods: []
    }
  },
  mutations: {
    setExchangeRates(state, payload) {
      state.exchangeRates = payload
    }
  },
  actions: {
    async getExchangeRates(context, payload) {
      const url = 'exchangeRate'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "GET", {}, query, {})
      if (success) {
        context.commit('setExchangeRates', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updateExchangeRate(context, payload) {
      const { _id, data } = payload
      const url = `exchangeRate/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", data, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
      }
    }
  }
}