<template>
  <div class="page dashboard order-dashboard">
    <div class="content">
      <div class="title-bar">
        <p class="title">Customer Subscription</p>
      </div>
      <ListingComponent
          model="data"
          :isLoading="isLoadingItem"
          :data="items"
          :query.sync="query"
          :refresh="getItems"
          :listingOptions="listingOptions"
          :deleteRecord="deleteRequest"
          :acceptRecord="acceptRecord"
          :rejectRecord="rejectRecord"
          :imageColumn="true"
      />
    </div>
  </div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import moment from "moment";
export default {
  components: {
    ListingLoadingComponent,
    ListingComponent
  },
  data() {
    return {
      isLoadingItem: false,
      reject:"reject",
      accept:"accept",
      hasImage:true,
      enableAcceptButton:true,
      enableRejectButton:true,
      url: 'https://development-api.nokorshop.com/',
      query: {
        order: "DESC",
        page: 1,
        limit: 10,
        sortBy: "createdAt",
        status: 0,
      }
    };
  },
  created(){this.getItems()},
  methods: {
    async getItems() {
      await this.$store.dispatch("getCustomerSubscription",this.query);
    },
    async acceptRecord(item){
      const confirmation = confirm(
          `Are you sure your want to accept this request?`
      )
      if(confirmation){
        const res = await this.$store.dispatch('acceptRequest',{
          _id:item._id
        })
        if(res){
            this.getItems()
        }
      }
    },
    async rejectRecord(item){
      const confirmation = confirm(
          `Are you sure your want to reject this request?`
      )
      if(confirmation){
        const res = await this.$store.dispatch('rejectRequest',{
          _id:item._id
        })
        if(res){
          this.getItems()
        }
      }
    },
    async deleteRequest(){
      console.log("Want to delete request ?")
    },
    imageUrl(item){
      const url = item.images[0].image
      return url
    }
  },

  watch: {
    query: {
      deep: true,
      handler: function() {
        if (this.query.page >= 1) {
          this.getItems();
        }
      },
    },
  },

  computed: {
    items() {
      const item = this.$store.state.customerSubscriptionModule.customerSubscription
      return item
    },
    listingOptions(){
      return {
        hasSelected: true,
        hasNo:true,
        hasImage:true,
        isLoading:true,
        actions: [this.reject ,this.accept],
        status:'',
        queryOptions: {
          sortBy: [{ label: "CreatedAt", value: "createdAt" }],
          sortDirection: [
            { label: "Ascending", value: "ASC" },
            { label: "Descending", value: "DESC" },
          ],
        },
        schema: [
          {
            label: "Name",
            key: "name",
            value: (record) => record.creator ? record.creator.name : 'N/A',
          },
          {
            label: "Phone Number",
            key: "phoneNumber",
            value: (record) => record.creator ? record.creator.phoneNumber : "N/A",
          },
          {
            label: "From Type",
            key: "buyerType",
            value: (record) => record.from_type || "N/A",
          },
          {
            label: "To Type",
            key: "type",
            value: (record) => record.type.toUpperCase() || "N/A",
          },
          {
            label: "Receipt",
            key: "image",
            customImageUrl: (record) => record.images
          },
          {
            label: "Request At",
            key: "type",
            value: (record) => moment(record.createdAt).format('DD/MM/YYYY HH:mm') || "N/A",
          },
          {
            label: "Status",
            key: "type",
            value: (record) => record.status.toUpperCase() || "N/A",
          },
          {
            label: "Action",
            key: "action",
          },
        ],
      }
    }
  }
}
</script>

<style></style>
