<template>
  <div class="page dashboard percentage-cut-page">
    <div class="content">
      <div class="title-bar">
        <p class="title">Percentage Cut</p>
      </div>
      <form v-on:submit.prevent="updatePercentageCut">
        <div class="input-ctn">
          <div class="label">Service Cut (in Percentage)</div>
          <input 
            type="text" 
            placeholder="Service cut" 
            class="input"
            v-model="servicePercentage"
          >
        </div>
        <div class="input-ctn">
          <div class="label">Insurance Cut (in Percentage)</div>
          <input 
            type="text" 
            placeholder="Insurance cut" 
            class="input"
            v-model="insurancePercentage"
          >
        </div>
        <button class="btn submit">Save</button>
      </form>
    </div>
  </div>
</template>

<script>

import ListingComponent from '../../components/ListingComponent'

export default {
  name: "PercentageCutDashboard",
  components: {
    ListingComponent
  },
  data() {
    return {
      isActionMenuOpen: "",
      isLoading: true,
      servicePercentage: '',
      insurancePercentage: '',
    }
  },
  methods: {
    async getPercentageCut() {
      this.isLoading = true
      await this.$store.dispatch("percentageCutsModule/getPercentageCuts", {query:this.query})
      this.servicePercentage = this.percentageCut.servicePercentage
      this.insurancePercentage = this.percentageCut.insurancePercentage
    },
    async updatePercentageCut() {
      this.isLoading = true
      const success = await this.$store.dispatch('percentageCutsModule/updatePercentageCuts', {
        _id: this.percentageCut._id,
        servicePercentage: parseFloat(this.servicePercentage),
        insurancePercentage: parseFloat(this.insurancePercentage)
      })
      if(success) {
        alert("Updated")
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.getPercentageCut()
  },
  computed: {
    percentageCut() {
      return this.$store.state.percentageCutsModule.percentageCuts
    }
  }
}
</script>

<style scoped>
form {
  width: 30rem;

}
.input { 
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 1rem;
  line-height: 3rem;
  padding: 0 1rem;
}
.submit {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  line-height: 3rem;
  display: flex;
  justify-content: center;
}
</style>