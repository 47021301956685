<template>
	<div class="modal">
		<div class="modal-content update-cost-modal">
			<p class="btn" v-on:click="closeModal">x</p>
			<form v-on:submit.prevent="handleSubmit">
				<div class="input-ctn">
					<div class="label">Paid</div>
					<input
						type="text"
						class="input"
						placeholder="Paid"
						v-model="costs.paidAmount"
					/>
				</div>
				<div class="submit">
					<p v-if="loading" class="btn disabled-btn">Updating . . .</p>
					<button v-if="!loading" type="submit" class="btn">Update</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		"onSubmit",
		"closeModal",
		"paidAmount",
	],
	data() {
		return {
      loading: false,
			costs: {
				paidAmount: this.paidAmount,
			},
		};
	},
	methods: {
		async handleSubmit() {
            this.loading = true;
            const {paidAmount} = this.costs;
            this.onSubmit({paidAmount})
        },
	},
};
</script>

<style></style>
