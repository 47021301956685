<template>
  <div class="tab-bar-component">
    <div v-if="!isLoading" class="ctn">
      <div class="group left">
        <div v-if="hasAll" :class="`item ${query[queryKey] == 0 ? 'active' : ''}`" v-on:click="updateTab(0)">All</div>
        <div
          :class="`item ${query[queryKey] == item._id ? 'active' : ''}`"
          v-for="item in data"
          v-bind:key="item.id"
          v-on:click="updateTab(item._id)"
        >{{label(item)}}</div>
      </div>
      <div class="group right" v-if="textSearch">
        <div class="item input-ctn">
          <input
            type="text"
            class="input"
            v-model="localQ"
            :placeholder="`${placeholder}`"
            v-on:keyup.enter="updateSearch(localQ)"
          />
        </div>
        <div class="btn search" v-on:click="updateSearch(localQ)">search</div>
      </div>
    </div>
    <div v-else class="ctn">Loading</div>
  </div>
</template>


<script>
export default {
  name: "TabBarComponent",
  props: {
    textSearch: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    query: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    queryKey: {
      type: String,
      required: true
    },
    updateSearch: {
      type: Function,
      required: false
    },
    label: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Search by email"
    },
    hasAll: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      localQ: ""
    };
  },
  methods: {
    updateTab(tabCode) {
      console.log("run", tabCode);
      this.query[this.queryKey] = tabCode;
    }
  }
};
</script>
<style scoped>
</style>
