<template>
  <div class="page dashboard product-category-dashboard">
    <div class="content">
      <div
        v-if="isActionMenuOpen"
        class="action-menu-backdroup"
        v-on:click="toggleActionMenu('')"
      ></div>
      <div class="title-bar">
        <p class="title">Product Category Management</p>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">
              New Category
            </p>
          </div>
        </div>
      </div>
      <ListingComponent
        model="data"
        :query.sync="query"
        :isLoading="isLoadingProductCategory"
        :data="categoryGroups"
        :refresh="getCategoryGroups"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
        :deleteRecord="deleteCategoryGroup"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <NewCategory
        v-if="isActionMenuOpen === 'new'"
        :addCategoryGroup="addCategoryGroup"
        :addCategory="addCategory"
      />
      <EditCategory
        v-if="isActionMenuOpen === 'read'"
        :categoryGroup="focusedCategoryGroup"
        :editCategory="editCategory"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";

import EditCategory from "./EditCategory";
import NewCategory from "./NewCategory";
export default {
  name: "ProductCategoriesDashboard",
  components: {
    ListingLoadingComponent,
    TabBarLoadingComponent,
    ListingComponent,
    TabBarComponent,
    NewCategory,
    EditCategory,
  },
  data() {
    return {
      selected: [],
      focusedCategoryGroup: {},
      isActionMenuOpen: "",
      q: "",
      query: {
        order: "ASC",
        page: 1,
        limit: 10,
        sortBy: "",
        role: 0,
      },
    };
  },
  methods: {
    async getCategoryGroups() {
      const response = await this.$store.dispatch(
        "productCategoriesModule/getProductCategoryGroups"
      )
    },
    async addCategoryGroup(payload) {
      const { name } = payload
      const response = await this.$store.dispatch(
        "productCategoriesModule/addProductCategoryGroup", 
        payload
      )
      this.isActionMenuOpen = false
      this.getCategoryGroups()
    },
    
    async addCategory(payload) {
      const { category } = payload;
      const created = await this.$store.dispatch(
        "productCategoriesModule/addProductCategories",
        { category }
      );
      if (created) {
        this.getCategoryGroups();
        this.toggleActionMenu("");
      }
    },
    async editCategory(payload) {
      const { category } = payload;
      const edited = await this.$store.dispatch(
        "productCategoriesModule/editProductCategories",
        { ...payload, _id: this.focusedCategoryGroup._id }
      );
      if (edited) {
        this.getCategoryGroups();
        this.toggleActionMenu("");
      }
    },
    async deleteCategoryGroup(payload) {
      const { _id } = payload;
      const confirmed = confirm("Are you sure you want to delete this record?");
      if (confirmed) {
        const deleted = await this.$store.dispatch(
          "productCategoriesModule/deleteProductCategoryGroup",
          { _id }
        );
        deleted && this.getCategoryGroups();
      }
    },
    toggleActionMenu(menu, payload) {
      if (menu !== "") {
        if (payload) {
          this.focusedCategoryGroup = payload;
        }
      }
      this.isActionMenuOpen = menu;
    },
  },
  created() {
    this.getCategoryGroups()
    // this.getCategories();
  },
  computed: {
    listingOptions() {
      return {
        hasSelected: false,
        actions: ["delete"],
        queryOptions: {
          sortBy: [],
        },
        schema: [
          {
            label: "English",
            key: "en",
          },
          {
            label: "ភាសាខ្មែរ",
            key: "km",
          },
          {
            label: "中文",
            key: "cn",
          },
          {
            label: "Created At",
            key: "createdAt",
            value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
          },
        ],
      };
    },
    categoryGroups() {
      return this.$store.state.productCategoriesModule.categoryGroups;
    },
    categories() {
      return this.$store.state.productCategoriesModule.categories;
    },
    isLoadingProductCategory() {
      return this.$store.state.productCategoriesModule.isLoadingCategory;
    },
  },
};
</script>

<style>
</style>