<template>
  <div class="modal">
    <div class="modal-content product-pricing-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <p>Confirm?</p>
      <form v-on:submit.prevent="handleSubmit">
        <div class="submit">
          <button type="submit" class="btn">Submit</button>
          <button v-on:click="closeModal" class="close btn">Back</button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      default: ()=>{}
    },
    closeModal: {}
  },
  data() {
    return {}
  },
  methods: {
    handleSubmit() {
      this.onSubmit()
      this.closeModal()
    }
  }
}
</script>

<style>

</style>