<template>
	<div class="new-user">
		<p class="title">New User</p>
		<form v-on:submit="handleFormSubmit" autocomplete="off">
			<div class="input-ctn">
				<div class="label">Username</div>
				<input
					v-model="name"
					class="input"
					type="text"
					placeholder="Username"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">phoneNumber</div>
				<input
					v-model="phoneNumber"
					type="text"
					class="input"
					placeholder="Phone Number"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Password</div>
				<input
					v-model="password"
					type="password"
					class="input"
					placeholder="Password"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Confirm Password</div>
				<input
					v-model="confirmPassword"
					type="password"
					class="input"
					placeholder="Confirm Password"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Role</div>
				<input
					v-model="role"
					type="text"
					class="input"
					placeholder="Role"
                    @focus="openRoleModal"
				/>
			</div>
			<div class="input-ctn" v-if="role == 'buyer'">
				<div class="label">Sale Person</div>
				<input
					v-model="recommender"
					type="text"
					class="input"
					placeholder="Sale Person"
                    @focus="openSaleModal"
				/>
			</div>
			<button class="submit btn">
				Add user
			</button>
		</form>
        <SelectRoleModal
        :closeModal="closeRoleModal"
        v-if="roleModalOpened"
        :onSubmit="selectedRole"
        />
        <SelectSaleModal
        :closeModal="closeSaleModal"
        v-if="saleModalOpened"
        :onSubmit="selectedSale"
        />
	</div>
</template>

<script>
import SelectSaleModal from "./SelectSaleModal";
import SelectRoleModal from "./SelectRoleModal";
export default {
	name: "NewUserForm",
	props: ["successAddUser"],
    components: {
        SelectSaleModal,
        SelectRoleModal
    },
	data() {
		return {
			name: "",
			phoneNumber: "",
			password: "",
			confirmPassword: "",
            saleModalOpened: false,
            roleModalOpened: false,
            recommender: null,
            role: 'buyer'
		};
	},
    created() {
      this.$store.dispatch("salePersonModule/getSalePeople", {
        query: this.query
      })
      this.$store.dispatch("userRoleModule/getRoles", {
        query: this.query
      })
    },
	methods: {
        openSaleModal() {
            this.saleModalOpened = true;
        },
        closeSaleModal() {
            this.saleModalOpened = false;
        },
        selectedSale(sale) {
            this.recommender = sale.code + ' - ' + sale.name;
        },
        openRoleModal() {
            this.roleModalOpened = true;
        },
        closeRoleModal() {
            this.roleModalOpened = false;
        },
        selectedRole(role) {
            this.role = role;
        },
		async handleFormSubmit(e) {
			e.preventDefault();
			const {
				password,
				name,
				phoneNumber,
                recommender,
                role
			} = this;
			if (this.password === this.confirmPassword) {
                const replacer = ['+855', '855', '0']
                let formatedPhoneNumber = phoneNumber;
                replacer.map(r => {
                    if(phoneNumber.startsWith(r)) {
                    formatedPhoneNumber = phoneNumber.replace(r, '')
                    }
                })
                const response = await this.$store.dispatch("usersModule/createUser", {
					password,
					name,
					phoneNumber: `855${formatedPhoneNumber}`,
                    recommender,
                    role
				});

                if (response) {
                    this.name = "";
                    this.phoneNumber = "";
                    this.password = "";
                    this.confirmPassword = "";
                    this.saleModalOpened = false;
                    this.recommender = "";
                    this.role = "";
				    this.successAddUser();
                }
			} else {
				alert("Passwords does not match");
			}
		},
	}
};
</script>

<style></style>
