<template>
  <div class="not-found-page">Not Found</div>
</template>


<script>

export default {
  name: 'NotFoundPage'
};
</script>
<style scoped>

</style>
