<template>
    <div>
	    <div class="sale-person-form" v-show="!isCreatingCustomerType">
            <p class="title">New VIP Customer</p>
            <form v-on:submit.prevent="handleFormSubmitted">
                <div class="input-ctn">
                    <div class="label">Name</div>
                    <input v-model="name" type="text" class="input" placeholder="Name" />
                    <input v-model="phoneNumber" type="text" class="input" placeholder="Phone Number" />
                    <input v-model="address" type="text" class="input" placeholder="Address" />
                </div>
                <div class="input-ctn">
                    <div class="label">Sale Person</div>
                    <input
                        v-model="recommender"
                        type="text"
                        class="input"
                        placeholder="Sale Person"
                        @focus="openSalePersonModal"
                    />
                </div>
                <div class="input-ctn">
                    <div class="label">Customer Type <span class="btn add-new" @click="isCreatingCustomerType = true">+</span></div>
                    <input
                        v-model="customerTypeName"
                        type="text"
                        class="input"
                        placeholder="Customer Type"
                        @focus="openCustomerTypeModal"
                    />
                </div>
                <button type="submit" class="btn submit">Create</button>
            </form>
            <SelectSalePersonModal
                :closeModal="closeSalePersonModal"
                v-show="salePersonModalOpened"
                :onSubmit="selectedSalePerson"
            />
            <SelectCustomerTypeModal
            :closeModal="closeCustomerTypeModal"
            v-if="customerTypeModalOpened"
            :onSubmit="selectedCustomerType"
            />
        </div>
        <div  class="sale-person-form" v-show="isCreatingCustomerType">
            <div class="btn close" v-on:click="isCreatingCustomerType = false"></div>
            <NewCustomerTypeForm 
                :addCustomerType="addCustomerType"
            />
        </div>
	</div>
</template>

<script>
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
import SelectCustomerTypeModal from "../../components/SelectCustomerTypeModal";
import NewCustomerTypeForm from '../customerType/NewCustomerTypeForm';
export default {
	name: "NewCustomerForm",
	props: ["addCustomer"],
    components: {
        SelectSalePersonModal,
        SelectCustomerTypeModal,
        NewCustomerTypeForm
    },

	data() {
		return {
			loading: false,
            customerTypeModalOpened: false,
            salePersonModalOpened: false,
			name: null,
            phoneNumber: null,
            address: null,
            salePerson: null,
            customerType: null,
            customerTypeName: null,
            recommender: null,
            isCreatingCustomerType: false
		};
	},
	methods: {
        async addCustomerType(payload) {
            const created = await this.$store.dispatch("customerTypeModule/createCustomerType", payload)
            if(created) {
                this.selectedCustomerType(created);
                this.isCreatingCustomerType = false;
            }
        },
        openSalePersonModal() {
            this.salePersonModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectSalePersonModelSearchInput").focus();
            })
        },
        closeSalePersonModal() {
            this.salePersonModalOpened = false;
        },
        selectedSalePerson(sale) {
            if(sale) {
                this.recommender = sale.code + ' - ' + sale.name;
                this.salePerson = sale._id;
            } else {
                this.recommender = null;
                this.salePerson = null;
            }
        },
        openCustomerTypeModal() {
            this.customerTypeModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectCustomerTypeModelSearchInput").focus();
            })
        },
        closeCustomerTypeModal() {
            this.customerTypeModalOpened = false;
        },
        selectedCustomerType(customerType) {
            this.customerType = customerType?._id;
            this.customerTypeName = customerType?.name;
        },
		async handleFormSubmitted() {
			this.loading = true;
			await this.addCustomer({ name: this.name, phoneNumber: this.phoneNumber, address: this.address, salePerson: this.salePerson, customerType: this.customerType });
            this.loading = false,
            this.customerTypeModalOpened = false,
            this.salePersonModalOpened = false,
            this.name = null;
            this.phoneNumber = null;
            this.address = null;
            this.salePerson = null;
            this.customerType = null;
            this.customerTypeName = null;
            this.recommender = null;
		},
	},
};
</script>

<style>
    .add-new {
        background-color: #000;
        color: #fff;
        line-height: 2rem;
        padding: 0 1rem;
    }
</style>
