<template>
	<div class="page log-in-page">
		<p class="title">Welcome to Dashboard</p>
		
		<form v-on:submit="logIn">
			<div class="input-ctn">
				<div class="label">Phone Number</div>
				<input
          required
					type="text"
					class="input"
					v-model="phoneNumber"
					placeholder="Phone number"
				/>
			</div>
			<div class="input-ctn">
				<div class="label">Password</div>
				<input
          required
					type="password"
					class="input"
					v-model="password"
					placeholder="Password"
				/>
			</div>

			<button type="submit" class="submit btn" >
				<div class="sign-in-btn btn">Log In</div>
				<!-- <div class="icon"></div> -->
			</button>
		</form>
	</div>
</template>

<script>
import { TOKEN_LABEL } from "../../const";

export default {
	name: "LogInPage",
	data() {
		return {
			phoneNumber: "",
			password: "",
		};
	},
	methods: {
		forgotPassword() {
			this.$router.push({ path: "/auth/forgot-password" });
		},
		async logIn(e) {
      e.preventDefault()
			const response = await this.$store.dispatch("usersModule/logIn", {
				phoneNumber: this.phoneNumber,
				password: this.password,
			});

			if (response) {
				this.$router.push({ path: "/" });
			}
		},
	},
};
</script>
<style scoped></style>
