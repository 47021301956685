<template>
  <div class="adjust-user-balance">
    <p class="title">Change Password</p>
    <p>User: {{user.name}} ({{user.phoneNumber}})</p>
    <form v-on:submit="handleFormSubmit">
        <div class="input-ctn">
            <div class="label">New Password</div>
            <input
                v-model="password"
                type="password"
                class="input"
                placeholder="Password"
            />
        </div>
        <div class="input-ctn">
            <div class="label">Confirm Password</div>
            <input
                v-model="confirmPassword"
                type="password"
                class="input"
                placeholder="Confirm Password"
            />
        </div>
        <button class="submit btn">
            Save
        </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ChangePassword',
  props: ['user', 'toggleActionMenu'],
  data() {
    return {
        password: "",
        confirmPassword: ""
    }
  },
  methods: {
    async handleFormSubmit(e) {
        e.preventDefault();
        const {
            password
        } = this;
        if (this.password && this.password === this.confirmPassword) {
            const response = await this.$store.dispatch("usersModule/changePassword", {
                password,
                id: this.user._id
            });

            if (response) {
                alert('Change password Successfully')
                this.toggleActionMenu('edit')
            }
        } else {
            alert("Passwords does not match");
        }
    },
  },
  computed: {
    currentUser() {
      return this.user
    }
  }
}
</script>

<style>

</style>
