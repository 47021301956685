<template>
	<div class="modal">
		<div class="modal-content variation-option-modal">
			<p class="btn" v-on:click="closeModal">x</p>
			<div class="label">New Option</div>
			<form v-on:submit.prevent="handleSubmit">
				<label v-if="hasImage" for="image" class="image btn">
					<p v-if="!image">Select an image</p>
					<img v-if="image" :src="image.path" alt="" />
				</label>
				<input
					id="image"
					class="file-input"
					type="file"
					@change="handleImageSelected"
				/>
				<input type="text" placeholder="Value" v-model="value" class="input" />
				<div class="submit">
					<button class="btn">Add</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	props: ["closeModal", 'hasImage', 'onSubmit'],
	data() {
		return {
      value: "",
      image: null
		};
	},
	methods: {
		handleImageSelected(e) {
      const file = e.target.files[0]
      this.loading = true
      this.image = file
      this.image.path = URL.createObjectURL(this.image)
      this.loading = false
    },
    handleSubmit() {
      const {value, image} = this
      this.onSubmit({value, image})
      this.closeModal()
    }
	},
};
</script>

<style></style>
