import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    customerType: {
      pageMeta: null, data: []
    }
  }, 
  mutations: {
    setCustomerType(state, payload) {
      state.customerType = payload
    }
  },
  actions: {
    async getCustomerType(context, payload) {
      const url = 'customerType'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) { 
        context.commit('setCustomerType', result)
      } else {
        httpErrorHandler(message)
      }
    }, 
    async createCustomerType(context, payload) {
      const url = 'customerType'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', payload, {}, {})
      if(success) {
        return result
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async editCustomerType(context, payload) {
      const url = `customerType/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async deleteCustomerType(context, payload) {
      const url = `customerType/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    }
  }
}