<template>
  <div class="auth-layout">
    <div class="cover"></div>
    <div class="view-container">
      <!-- <div
        class="credit"
        v-on:click="window.open('http://www.rexakh.com')"
      >Powered by REXA Studios</div> -->
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
export default {
  name: "AuthLayout"
};
</script>
<style scoped>
</style>
