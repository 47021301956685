<template>
    <div class="page dashboard transactions-dashboard">
        <div class="content">
            <div class="title-bar">
                <p class="title">Transaction management</p>
            </div>
            <TabBarComponent :isLoading="false" :query.sync="query" :data="status" queryKey="status" :label="r=>r.label"
                :key="false" :hasAll="false" />
            <input placeholder="Search for transactions..." type="text" class="search" v-model="search" />
            <input
                v-model="buyerLabel"
                type="text"
                class="search"
                style="margin-left: 10px"
                placeholder="User"
                @focus="openBuyerModal"
            />
            <ListingComponent model="data" :isLoading="isLoadingTransaction" :data="transactions" :query.sync="query"
                :refresh="getTransactions" :toggleActionMenu="handleTransactionClick" :listingOptions="listingOptions"
                :deleteRecord="deleteTransaction" :recoverRecord="recoverTransaction"/>
        </div>
        <SelectUserModal
            :closeModal="closeBuyerModal"
            v-show="buyerModalOpened"
            :onSubmit="selectedBuyer"
            :roles="[buyerId]"
        />
    </div>
</template>

<script>
    import ListingLoadingComponent from "../../components/ListingLoadingComponent";
    import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
    import ListingComponent from "../../components/ListingComponent";
    import TabBarComponent from "../../components/TabBarComponent";
    import SelectUserModal from "../../components/SelectUserModal";

    import Searchbar from '../../components/Searchbar';

    import moment from "moment";
    export default {
        name: "TranactionsDashboard",
        components: {
            ListingLoadingComponent,
            TabBarLoadingComponent,
            ListingComponent,
            TabBarComponent,
            Searchbar,
            SelectUserModal
        },
        data: function () {
            return {
                buyer: null,
                buyerLabel: null,
                buyerModalOpened: false,
			    searchTimeout: null,
                search: null,
                query: {
                    order: "ASC",
                    page: 1,
                    limit: 10,
                    sortBy: "",
                    status: 0,
                }
            };
        },
        created() {
            this.getTransactions();
            this.$store.dispatch("usersModule/getUserRoles");
        },
        methods: {
            openBuyerModal() {
                this.buyerModalOpened = true;
                setTimeout(() => {
                    document.getElementById("selectUserModelSearchInput").focus();
                })
            },
            closeBuyerModal() {
                this.buyerModalOpened = false;
            },
            selectedBuyer(buyer) {
                if(this.buyer == buyer) {
                    return;
                }

                if(buyer) {
                    this.buyerLabel = buyer.name + ' - ' + buyer.phoneNumber;
                } else {
                    this.buyerLabel = null;
                }
                this.buyer = buyer;
                this.getTransactions();
            },
            handleTransactionClick(action, payload) {
			    window.open(`/transactions/${payload._id}`);
            },
            getTransactions() {
                this.$store.dispatch("transactionsModule/getTransactions", {
                    ...this.query, 
                    searchValue: this.search,
                    buyerId: this.buyer?._id
                });
            },
            deleteTransaction(item) {
                const confirmed = confirm('Are you sure you want to delete this?')
                
                if(confirmed) {
                    this.$store.dispatch("transactionsModule/delete", {
                        _id: item._id
                    });

                    this.$so
                }
            },
            recoverTransaction(item) {
                const confirmed = confirm('Are you sure you want to recover this?')
                
                if(confirmed) {
                    this.$store.dispatch("transactionsModule/recover", {
                        _id: item._id
                    });

                    this.$so
                }
            }
        },
        watch: {
            query: {
                deep: true,
                handler: function () {
                    if (this.query.page >= 1) {
                        this.getTransactions();
                    }
                },
            },
            search: function() {
                this.searchTimeout && clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(
                    () => this.getTransactions(), 500
                )
            }
        },
        computed: {
            transactions() {
                return this.$store.state.transactionsModule.transactions;
            },
            isLoadingTransaction() {
                return this.$store.state.transactionsModule.isLoadingTransaction;
            },
            status() {
                const status =  [
                    {
                        _id: -1,
                        label: "All"
                    },
                    {
                        _id: 0,
                        label: "Pending"
                    },
                    {
                        _id: 0.5,
                        label: "Confrim Payment"
                    },
                    {
                        _id: 1,
                        label: "Confirm"
                    },
                    {
                        _id: 2,
                        label: "Rejected"
                    }
                ];
                if(['admin', 'financer'].includes(this.$store.state.usersModule.currentUser.role.name)) {
                    return [
                        ...status, 
                        {
                            _id: 0.9,
                            label: "Deleted"
                        }
                    ];
                } else {
                    return status;
                }
            },
            listingOptions() {
                let actions = [];
                if(['admin', 'financer'].includes(this.$store.state.usersModule.currentUser.role.name)) {
                    if(this.query.status == 0) {
                        actions = ["delete"];
                    } else if (this.query.status == 0.9) {
                        actions = ["recover"];
                    }
                }

                let schema = [{
                            label: "ID",
                            key: "transactionId"
                        },
                        {
                            label: "Customer name",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.name : 'N/A'
                        },
                        {
                            label: "Customer phone",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.phoneNumber : 'N/A'
                        },
                        {
                            label: "Paid",
                            key: 'Paid',
                            value: record => record.paid_amount == 0 ? 'No' : 'Yes'
                        },
                        {
                            label: "Date",
                            key: "Date",
                            value: (record) => moment(record.createdAt).format('DD/MM/YYYY'),
                        },
                        {
                            label: "Created At",
                            key: "createdAt",
                            value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                        },
                    ];

                if(this.query.status == -1) {
                    schema = [{
                            label: "ID",
                            key: "transactionId"
                        },
                        {
                            label: "Customer name",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.name : 'N/A'
                        },
                        {
                            label: "Customer phone",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.phoneNumber : 'N/A'
                        },
                        {
                            label: "Paid",
                            key: 'Paid',
                            value: record => record.paid_amount == 0 ? 'No' : 'Yes'
                        },
                        {
                            label: "Status",
                            key: "status",
                            value: (record) => {
                                switch(record.status) {
                                    case 0:
                                        if(record.confirmedPaymentAt) {
                                            return 'Confrim Payment';
                                        }

                                        return 'Pending';
                                    case 1:
                                        return 'Confirmed'
                                    case 2:
                                        return 'Rejected';
                                    default:
                                        return 'N/A'
                                }
                            },
                        },
                        {
                            label: "Date",
                            key: "Date",
                            value: (record) => moment(record.createdAt).format('DD/MM/YYYY'),
                        },
                        {
                            label: "Created At",
                            key: "createdAt",
                            value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                        },
                    ];
                } else if(this.query.status == 0.9) {
                    schema = [{
                            label: "ID",
                            key: "transactionId"
                        },
                        {
                            label: "Customer name",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.name : 'N/A'
                        },
                        {
                            label: "Customer phone",
                            key: 'createdBy',
                            value: record => record.createBy ? record.createBy.phoneNumber : 'N/A'
                        },
                        {
                            label: "Paid",
                            key: 'Paid',
                            value: record => record.paid_amount == 0 ? 'No' : 'Yes'
                        },
                        {
                            label: "Date",
                            key: "Date",
                            value: (record) => moment(record.createdAt).format('DD/MM/YYYY'),
                        },
                        {
                            label: "Deleted By",
                            key: "Deleted By",
                            value: record => record.deletedBy ? record.deletedBy.name : 'N/A'
                        },
                        {
                            label: "Created At",
                            key: "createdAt",
                            value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                        },
                    ];
                }

                return {
                    hasSelected: false,
                    hasNo: true,
                    actions: actions,
                    queryOptions: {
                        sortBy: [{
                            label: "CreatedAt",
                            value: "createdAt"
                        }],
                        sortDirection: [{
                                label: "Ascending",
                                value: "ASC"
                            },
                            {
                                label: "Descending",
                                value: "DESC"
                            },
                        ],
                    },
                    schema,
                };
            },
            roles() {
                return this.$store.state.usersModule.userRoles;
            },
            buyerId() {
                const buyer = this.roles.data?.filter(r => (r.name === 'buyer'))
                return buyer ? buyer[0]._id : '';
            },
        },
    };
</script>

<style></style>