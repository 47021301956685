import { HttpRequest, httpErrorHandler } from "../../handler"
export default{
    state:{
        data: [],
        promotionProduct: [],
        suggestion: []
    },
    mutations:{
        setSettingsDefaultHomePageProduct(state,payload){
            state.data = payload
        },
        setSettingsPromotionProduct(state,payload){
            state.promotionProduct = payload
        },
        setSettingsSuggestion(state,payload){
            state.suggestion = payload
        }
    },
    actions:{
        async getSettingDefaultHomePageProduct(context,payload){
            const url = 'setting/defaultHomePageProduct'
            const {query} = payload
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'GET', {},query, {})
            if(success){
                context.commit('setSettingsDefaultHomePageProduct', result)
            }else{
                httpErrorHandler(message)
            }
        },
        async createSettingDefaultHomePageProduct(context, payload) {
            const url = `setting/defaultHomePageProduct`
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async updateSettingDefaultHomePageProduct(context, payload) {
            const { _id } = payload;
            const url = `setting/defaultHomePageProduct/${_id}`;
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'PATCH', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async deleteSettingDefaultHomePageProduct(context, payload) {
          const {
            _id
          } = payload
          const url = `setting/defaultHomePageProduct/${_id}`
          context.commit('setSettingsDefaultHomePageProduct', context.state.data.filter(item => item._id != _id));
          const {
            success,
            result,
            message
          } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
          if (success) {
            return true
          } else {
            httpErrorHandler(message)
          }
        },
        async getSettingPromotionProduct(context,payload){
            const url = 'setting/promotionProduct'
            const {query} = payload
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'GET', {},query, {})
            if(success){
                context.commit('setSettingsPromotionProduct', result)
            }else{
                httpErrorHandler(message)
            }
        },
        async createSettingPromotionProduct(context, payload) {
            const url = `setting/promotionProduct`
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async updateSettingPromotionProduct(context, payload) {
            const { _id } = payload;
            const url = `setting/promotionProduct/${_id}`;
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'PATCH', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async deleteSettingPromotionProduct(context, payload) {
          const {
            _id
          } = payload
          const url = `setting/promotionProduct/${_id}`
          context.commit('setSettingsPromotionProduct', context.state.promotionProduct.filter(item => item._id != _id));
          const {
            success,
            result,
            message
          } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
          if (success) {
            return true
          } else {
            httpErrorHandler(message)
          }
        },
        async getSettingSuggestion(context,payload){
            const url = 'setting/suggestion'
            const {query} = payload
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'GET', {},query, {})
            if(success){
                context.commit('setSettingsSuggestion', result)
            }else{
                httpErrorHandler(message)
            }
        },
        async createSettingSuggestion(context, payload) {
            const url = `setting/suggestion`
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async updateSettingSuggestion(context, payload) {
            const { _id } = payload;
            const url = `setting/suggestion/${_id}`;
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'PATCH', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
        async deleteSettingSuggestion(context, payload) {
          const {
            _id
          } = payload
          const url = `setting/suggestion/${_id}`
          context.commit('setSettingsSuggestion', context.state.suggestion.filter(item => item._id != _id));
          const {
            success,
            result,
            message
          } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
          if (success) {
            return true
          } else {
            httpErrorHandler(message)
          }
        },
        async importSettingSuggestion(context, payload) {
            const url = `setting/suggestion/import`
            const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
            if(success){
                return true
            }else{
                httpErrorHandler(message)
            }
        },
    }
}