<template>
	<div class="top-up-details">
		<p class="title">Top Up Details</p>
		<img :src="`${API_URL}/${request.image}`" alt="" class="preview" />
		<form v-on:submit.prevent="handleSubmit">
			<div class="input-ctn">
				<div class="label">Amount</div>
				<input
					v-model="amount"
					required
					type="text"
					class="input"
					placeholder="Amount"
				/>
			</div>
			<div class="row">
				<p v-if="request.status === 1" type="submit" class="submit btn">
					Accepted
				</p>
				<p v-if="request.status === 2" type="submit" class="reject btn">
					Accept
				</p>
				<button v-if="request.status === 0" type="submit" class="submit btn">
					Accept
				</button>
				<p
					v-if="request.status === 0"
					v-on:click="handleReject"
					class="reject btn"
				>
					Reject
				</p>
			</div>
		</form>
	</div>
</template>

<script>
import { API_URL } from "../../const";
export default {
	props: ["request", 'toggleActionMenu', 'getRequest'],
	data() {
		return {
			amount: "",
			accepting: false,
			rejecting: false,
		};
	},
	methods: {
		async handleSubmit() {
			this.accepting = true;
			const confirmed = confirm(
				`Are you sure you want to give user $${this.amount}?`
			);
			if (confirmed) {
				await this.$store.dispatch("usersModule/topUpUser", {
					id: this.request.createdBy._id,
					amount: this.amount,
					note: "Top Up (Balance)",
					method: 1,
				});
				await this.$store.dispatch("topupModule/updateStatus", {
					id: this.request._id,
					status: 1,
				});
        this.amount = 0;
        alert('Balance Added')
        this.toggleActionMenu('')
        this.getRequest()
			}
			this.accepting = false;
		},
		async handleReject() {
			this.rejecting = true;
			await this.$store.dispatch("topupModule/updateStatus", {
				id: this.request._id,
				status: 2,
			});
			this.toggleActionMenu("")
			this.getRequest()
			this.rejecting = false;
		},
	},
	computed: {
		API_URL() {
			return API_URL;
		},
	},
};
</script>

<style></style>
