<template>
    <div class="page dashboard percentage-cut-page">
        <div class="content">
            <div class="title-bar">
                <p class="title">VIP Quotation, {{id ? 'Edit' : 'Add New'}}</p>
            </div>
            <form v-on:submit.prevent="">
                <div class="quotation">
                    <div style="width: 30rem; display: inline-block; margin-right: 20px">
                        <div class="label required">Sale Person<span style="line-height: 2rem; opacity: 0; width: 0"></span></div>
                        <div class="input-ctn">
                            <input v-model="recommender" type="text" class="input" placeholder="Sale Person"
                                @focus="openSalePersonModal" />
                        </div>
                        <div class="label required">Quotation No</div>
                        <div class="input-ctn">
                            <input v-model="quotationNo" type="text" class="input" placeholder="Quotation No"/>
                        </div>
                        <div class="label required">Insurance</div>
                        <div class="input-ctn">
                            <select v-model="insurance" class="input">
                                <option :value="1">Yes</option>
                                <option :value="0">No</option>
                            </select>
                        </div>
                    </div>
                    <div style="width: 30rem; display: inline-block">
                        <div class="label required">VIP Customer <span class="btn" @click="isCreatingCustomer = true">+</span></div>
                        <div class="input-ctn" style="display: inline">
                            <input v-model="vipCustomerLabel" type="text" class="input" placeholder="Vip Customer"
                                @focus="openVipCustomerModal" />
                        </div>
                        <div class="label required">Shipping Method</div>
                        <div class="input-ctn">
                            <select v-model="shippingMethod" class="input">
                                <option v-for="item in shippingMethods.data" :key="item._id" :value="item._id">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="label required">Discount</div>
                        <div class="input-ctn">
                            <input v-model="discountAmount" type="number" step="0.01" class="input" placeholder="Discount Amount"/>
                        </div>
                        <!-- <div class="label required">Transport Method</div>
                        <div class="input-ctn">
                            <select v-model="transportMethod" class="input">
                                <option value="0">Self Pick-up</option>
                                <option value="1">Nokor Delivery</option>
                            </select>
                        </div> -->
                    </div>
                </div>
                <div class="quotationItems">
                    <h3 style="margin-left: 10px">
                        <span class="required" style="margin-right: 5px">Items</span>
                        <button class="btn btn-small" type="button" @click="addItem">Add Item</button>
                    </h3>

                    <table class="table">
                        <thead>
                            <th width="5%">#</th>
                            <th width="15%">Image</th>
                            <th width="15%">Check Product</th>
                            <th width="20%">Color & Size</th>
                            <th width="10%">QTY</th>
                            <th width="10%">Unit Price</th>
                            <th width="10%">China Fee</th>
                            <th width="10%">Total</th>
                            <th width="5%"></th>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in quotationItems" :key="key + index">
                                <td>{{ index + 1}}
                                    <span v-if="item.status == 2" style="color: red">Rejected</span>
                                </td>
                                <td  v-show="item.imageSrc">
                                    <img :src="item.imageSrc" style="width: 32px" data-lity :data-lity-target="item.imageSrc">
                                    <button class="btn btn-small" style="margin-left:10px" type="button" @click="clearImage(item)">Remove</button>
                                </td>
                                <td v-show="!item.imageSrc">
                                    <input type="text" style="width: 60px" placeholder="URL" @change="cheangeImageSrc(item)" v-model="item.imageSrc">
                                    <input style="display: none;" type="file" :value="null" :id="'file-' + index"
                                        @change="handleImageSelected($event, item)" />
                                    <label :for="'file-' + index" class="btn btn-small">upload</label>
                                </td>
                                <td>
                                    <input type="text" placeholder="URL" style="width: 120px" v-model="item.checkProductLink" ><a :href="item.checkProductLink" target="_blank" v-if="item.checkProductLink && item.checkProductLink.includes('http')">View</a>
                                </td>
                                <td>
                                    <textarea placeholder="Color & Size" v-model="item.variantNote" >
                                    </textarea>
                                </td>
                                <td>
                                    <input type="number" style="width: 80px" step="0.01" placeholder="QTY" v-model="item.amount">
                                </td>
                                <td>
                                    <input type="number" style="width: 80px" step="0.01" placeholder="Unit Price" v-model="item.price">
                                </td>
                                <td>
                                    <input type="number" style="width: 80px" step="0.01" placeholder="Shipping Cost" v-model="item.chinaDeliveryCost">
                                </td>
                                <td>
                                    {{ ((item.amount * item.price) + parseFloat(item.chinaDeliveryCost)).toFixed(2) }}
                                </td>
                                <td>
                                    <button type="button" class="btn btn-small" @click="removeItem(index)">x</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="result" style="">
                        <p>Total: $ {{total}}</p>
                    </div>
                </div>
                <button class="btn submit" @click="save" type="button" style="width: 30rem" :disabled="isLoading">Save</button>
            </form>
        </div>
        <div :class="`action-menu active`" v-show="isCreatingCustomer">
            <div class="btn close" v-on:click="isCreatingCustomer = false"></div>
            <NewCustomerForm 
                :addCustomer="addCustomer"
            />
        </div>
        <SelectSalePersonModal
            :closeModal="closeSalePersonModal"
            v-show="salePersonModalOpened"
            :onSubmit="selectedSalePerson"
        />
        <SelectVipCustomerModal :closeModal="closeVipCustomerModal" v-show="vipCustomerModalOpened"
            :onSubmit="selectedVipCustomer" />
    </div>
</template>
<script>
    import SelectSalePersonModal from "../../components/SelectSalePersonModal";
    import SelectVipCustomerModal from "../../components/SelectVipCustomerModal";
    import NewCustomerForm from '../customer/NewCustomerForm';
    import { FileUpload } from "../../handler";
    import { API_URL } from "../../const";

    export default {
        name: "QuotationNew",
        components: {
            SelectSalePersonModal,
            SelectVipCustomerModal,
            NewCustomerForm,
        },
        data() {
            return {
                id: null,
                quotationNo: null,
                customer: null,
                shippingMethod: null,
                salePerson: null,
                recommender: null,
                insurance: 0,
                isLoading: false,
                vipCustomerLabel: null,
                vipCustomerModalOpened: false,
                transportMethod: 1,
                quotationItems: [],
                isCreatingCustomer: false,
                salePersonModalOpened: false,
                discountAmount: 0,
                key: false
            }
        },
        async mounted() {
            this.$store.dispatch("shippingMethodModule/getShippingMethods", {});
        },
        async created() {
            if(this.$route.query.duplicate_id) {
                const quotation = await this.$store.dispatch("quotationModule/detail", {
                    _id: this.$route.query.duplicate_id,
                });

                if(quotation) {
                    this.quotationNo = quotation.quotationNo,
                    this.selectedVipCustomer(quotation.customer);
                    this.shippingMethod = quotation.shippingMethod?._id,
                    this.selectedSalePerson(quotation.salePerson);
                    this.insurance = quotation.insurance ? 1 : 0;
                    this.discountAmount = quotation.discountAmount;
                    this.quotationItems = quotation.quotationItems.map(item => {
                        item.imageSrc = item.image && (item.image.includes('http') || item.image.includes('data:image')) ? item.image : `${API_URL}/${item.image}`;
                        item.file = null;
                        item.image = null;
                        item.status = 0;
                        return item;
                    });
                }
            }

            if(this.$route.params.id) {
                this.id = this.$route.params.id;
                const quotation = await this.$store.dispatch("quotationModule/detail", {
                    _id: this.$route.params.id,
                });

                if(quotation) {
                    this.quotationNo = quotation.quotationNo,
                    this.selectedVipCustomer(quotation.customer);
                    this.shippingMethod = quotation.shippingMethod?._id,
                    this.selectedSalePerson(quotation.salePerson);
                    this.insurance = quotation.insurance ? 1 : 0;
                    this.discountAmount = quotation.discountAmount;
                    this.quotationItems = quotation.quotationItems.map(item => {
                        item.imageSrc = item.image && (item.image.includes('http') || item.image.includes('data:image')) ? item.image : `${API_URL}/${item.image}`;
                        item.file = null;
                        item.image = null;
                        return item;
                    });
                }
            }
        },
        methods: {
            cheangeImageSrc(item) {
                this.key = true;
                item.file = null;
                this.key = false;
            },
            clearImage(item) {
                this.key = true;
                item.file = null;
                item.imageSrc = null;
                this.key = false;
            },
            removeItem(index) {
                this.quotationItems.splice(index, 1);
            },
            openSalePersonModal() {
                this.salePersonModalOpened = true;
                setTimeout(() => {
                    document.getElementById("selectSalePersonModelSearchInput").focus();
                })
            },
            closeSalePersonModal() {
                this.salePersonModalOpened = false;
            },
            selectedSalePerson(sale) {
                if(sale) {
                    this.recommender = sale.code + ' - ' + sale.name;
                    this.salePerson = sale._id;
                } else {
                    this.recommender = null;
                    this.salePerson = null;
                }
            },
            async addCustomer(payload) {
                const customer = await this.$store.dispatch("vipCustomerModule/create", payload)
                if(customer) {
                    this.isCreatingCustomer = false;
                    this.selectedVipCustomer(customer);
                }
            },
            addItem() {
                this.quotationItems.push({
                    imageSrc: null,
                    file: null,
                    variantNote: null,
                    amount: 1,
                    price: 0,
                    chinaDeliveryCost: 0,
                })
            },
            openVipCustomerModal() {
                this.vipCustomerModalOpened = true;
                setTimeout(() => {
                    document.getElementById("selectVipCustomerModelSearchInput").focus();
                })
            },
            closeVipCustomerModal() {
                this.vipCustomerModalOpened = false;
            },
            selectedVipCustomer(vipCustomer) {
                const id = vipCustomer ? vipCustomer._id : null;
                if (this.customer == id) {
                    return;
                }

                if (vipCustomer) {
                    this.vipCustomerLabel = vipCustomer.name + ' - ' + vipCustomer.phoneNumber + " (" + vipCustomer
                        .code + ")";
                } else {
                    this.vipCustomerLabel = null;
                }
                this.customer = id;
            },
            selectedShippingMethod(shippingMethod) {
                const id = shippingMethod ? shippingMethod._id : null;
                if (this.customer == id) {
                    return;
                }

                if (shippingMethod) {
                    this.shippingMethodLabel = shippingMethod.name;
                } else {
                    this.shippingMethodLabel = null;
                }
                this.customer = id;
            },
            async save() {
                this.isLoading = true

                if(!this.customer || !this.shippingMethod || this.transportMethod === null || this.quotationItems.length == 0) {
                    alert("Please input all required data");
                    this.isLoading = false
                    return false;
                }

                const items = [];
                
                for (let [idx, i] of this.quotationItems.entries()) {
                    if(i.file) {
                        const file = await FileUpload.uploadFile(i.file);
                        i.image = file.path;
                    } else {
                        i.image = i.imageSrc;
                    }
                    items.push(i);
                }

                const success = await this.$store.dispatch(this.id ? 'quotationModule/update' :
                    'quotationModule/create', {
                        _id: this.id,
                        customer: this.customer,
                        quotationNo: this.quotationNo,
                        salePerson: this.salePerson,
                        insurance: this.insurance,
                        shippingMethod: this.shippingMethod,
                        transportMethod: this.transportMethod,
                        discountAmount: this.discountAmount,
                        quotationItems: items,
                    })

                if (success) {
                    alert("Quotation " + (this.id ? "Updated" : "Created") + "Successfully");
                    this.$router.push({
                        path: "/quotations/" + success._id
                    });
                }

                this.isLoading = false
            },
            async handleImageSelected(e, item) {
                this.key = true;
                const image = e.target.files[0];
                item.imageSrc = URL.createObjectURL(image);
                item.file = image;
                this.key = false;
            },
        },
        computed: {
            shippingMethods() {
                if(!this.shippingMethod && this.$store.state.shippingMethodModule.shippingMethods.data) {
                    this.shippingMethod = this.$store.state.shippingMethodModule.shippingMethods.data[0]._id;
                }
                return this.$store.state.shippingMethodModule.shippingMethods;
            },
            total() {
                return this.quotationItems.reduce((a, item) => (item.amount * item.price) + parseFloat(item.chinaDeliveryCost) + parseFloat(a), 0).toFixed(2);
            }
        }
    }
</script>

<style src="lity/dist/lity.min.css"></style>

<style scoped>
    form {
        height: 100%;
        justify-content: center;
    }

    .quotation {
        display: flex;
        /* width: 100rem; */
        /* text-align:center; */
        
    }

    .quotationItems {
        margin: 10px;
        padding-top: 20px;
        background-color: rgb(241, 241, 241);
        justify-content: center;
    }

    .input {
        width: 100%;
        border: 1px solid #000;
        margin-bottom: 1rem;
        line-height: 3rem;
        padding: 0 1rem;
    }

    .btn {
        background-color: #000;
        color: #fff;
        line-height: 2rem;
        padding: 0 1rem;
    }

    .submit {
        width: 100%;
        background-color: #000000;
        color: #ffffff;
        line-height: 3rem;
        display: flex;
        justify-content: center;
    }

    .table {
        margin-top: 10px;
        width: 99%;
        margin-left: auto;
        margin-right: auto;
    }

    table {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }

    table caption {
        font-size: 1.5em;
        margin: .5em 0 .75em;
    }

    table tr {
        background-color: #f8f8f8;
        border: 1px solid #ddd;
        padding: .35em;
    }

    table th,
    table td {
        padding: .625em;
        text-align: center;
        vertical-align: middle;
    }

    table th {
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    @media screen and (max-width: 600px) {
        table {
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
        }

        table td::before {
            /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
    }

    .result{
        border: 3px solid rgb(107, 107, 107); 
        height: 60px; 
        margin-top: 3px;
        width: 20%;
        align-items: center; 
        display: flex; 
        justify-content: center;
        float: right;
        color: #000;
        /* margin-bottom: 2rem; */
    }
</style>