<template>
	<div class="dashboard page top-up-request-page">
		<div class="content">
			<div class="title-bar">
				<p class="title">Top Up Request</p>
			</div>
      <TabBarComponent 
        :isLoading="false"
        :query.sync="query"
        :data="status"
        queryKey="status"
        :label="r => r.label"
        :key="false"
        :hasAll="false"
      />
			<ListingComponent
				model="data"
        :data="requests"
				:isLoading="loading"
				:query.sync="query"
				:refresh="getRequest"
				:toggleActionMenu="toggleActionMenu"
				:listingOptions="listingOptions"
        :deleteRecord="deleteRequest"
			/>
		</div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
			<div class="btn close" v-on:click="toggleActionMenu('')"></div>
      
      <TopUpRequestDetails 
        v-if="isActionMenuOpen === 'read'"
        :request="focusedRequest"
        :toggleActionMenu="toggleActionMenu"
        :getRequest="getRequest"
      />
    </div>
	</div>
</template>

<script>
import moment from "moment";
import TabBarComponent from "../../components/TabBarComponent";
import ListingComponent from '../../components/ListingComponent'
import TopUpRequestDetails from './TopUpRequestDetails'
import { API_URL } from "../../const";
export default {
  name: "TopUpRequestDashboard",
  components: {
    ListingComponent,
    TopUpRequestDetails,
    TabBarComponent,
  },
	data() {
		return {
			isActionMenuOpen: "",
      loading: false,  
      focusedRequest: {},
      status: [
        {_id: 0, label: 'Pending',},
        {_id: 1, label: 'Confirmed',},
        {_id: 2, label: 'Rejected',},
      ],
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
				status: 0,
			},
		};
	},
	created() {
		this.getRequest();
	},
	methods: {
		async getRequest() {
      this.loading = true
			await this.$store.dispatch("topupModule/getRequest", {
				query: this.query,
			});
			this.loading = false;
			console.log("done");
    },
    toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedRequest = payload;
				} 
			}
			this.isActionMenuOpen = menu;
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'Pending'
        case 1: 
          return 'Confirmed'
        case 2:
          return 'Rejected';
      }
    },
    deleteRequest() {

    }
	},
	computed: {
		listingOptions() {
			return {
				hasSelect: false,
				actions: [],
				queryOptions: {
					sortBy: [{ label: "createdAt", value: "createdAt" }],
					sortDirection: [
						{
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
					],
				},
				schema: [
					{
						label: "ID",
						key: "_id",
					},
					{
						label: "Name",
						key: "createdBy",
						value: (r) => (r.createdBy?.name),
					},
					{
						label: "Phone number",
						key: "createdBy",
						value: (r) => (r.createdBy?.phoneNumber),
					},
					{
						label: "Status",
						key: "status",
						value: (r) => (this.getStatus(r.status)),
          },
          {
            label: "Create At",
            key: 'createdAt',
            value: (r) => (moment(r.createdAt).format('YYYY-MM-DD HH:mm'))
          }
				],
			};
		},
		requests() {
			return this.$store.state.topupModule.requestList;
		},
		API_URL() {
			return API_URL;
		},
  },
  watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getRequest();
				}
			},
		},
	},
};
</script>

<style></style>
