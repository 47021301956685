import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    customer: {
      pageMeta: null, data: []
    }
  }, 
  mutations: {
    set(state, payload) {
      state.customer = payload
    }
  },
  actions: {
    async get(context, payload) {
      const url = 'customer'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) { 
        context.commit('set', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    }, 
    async create(context, payload) {
      const url = 'customer'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', payload, {}, {})
      if(success) {
        return result;
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async edit(context, payload) {
      const url = `customer/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async destroy(context, payload) {
      const url = `customer/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    }
  }
}