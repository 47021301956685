import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    quotations: { pageMeta: {}, data: [] },
    quotationDetails: {},
    isArrived: {},
    exportQuotations: [],
  },
  mutations: {
    setQuotations(state, payload) {
      state.quotations = payload
    },
    setExportQuotations(state, payload) {
      state.exportQuotations = payload.data
    },
    setQuotationDetails(state, payload) {
      state.quotationDetails = payload
    },
    setQuotationDetailStatus(state, payload) {
      state.quotationDetails.status = payload
    },
    setQuotationArrived(state, payload) {
      state.isArrived = payload
    },
    pushQuotationPaymentImage(state, payload) {
        if(state.quotationDetails._id == payload.id) {
            if(!state.quotationDetails.paymentImages) {
                state.quotationDetails.paymentImages = [];
            }
            state.quotationDetails.paymentImages.push({
                image: payload.image
            });
        }
    },
  },
  actions: {
    async detail(context, payload) {
      const { _id } = payload
      const url = `quotation/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        context.commit('setQuotationDetails', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },

    async rejectProduct(context, payload) {
      const { _id, itemId } = payload;

      const url = `quotation/${_id}/rejectProduct/${itemId}`;
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {});
      
      if (success) {
        const quotation = context.state.quotationDetails;

        quotation.quotationItems = quotation.quotationItems.map(item => {
            if(item.itemId == itemId) {
                item.status = 2;
            }
            
            return item;
        })

        context.commit('setQuotationDetails', quotation)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },

    async create(context, payload) {
        const url = `quotation`
        const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})

        if(success){
            return result;
        }else{
            httpErrorHandler(message)
        }
    },
    async update(context, payload) {
        const { _id } = payload;
        const url = `quotation/${_id}`;
        const {success,result,message} = await HttpRequest.authorizedRequest(url, 'PATCH', payload, {})
        
        if(success){
            return result;
        }else{
            httpErrorHandler(message)
        }
    },

    async delete(context, payload) {
      const {
        _id
      } = payload
      const url = `quotation/${_id}`
      const {
        success,
        message
      } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if (success) {
        context.commit('setQuotationDetails', context.state.quotations.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    },

    async recover(context, payload) {
      const {
        _id
      } = payload
      const url = `quotation/${_id}/recover`
      const {
        success,
        message
      } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
      if (success) {
        context.commit('setQuotationDetails', context.state.quotations.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    },

    async getQuotationByTrackingId(context, payload) {
      const { trackingId } = payload
      const url = `quotation/byTrackingId/${trackingId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        return result;
      } else {
        return false;
      }
    },
    async getQuotations(context, payload) {
      const query = payload
      const url = 'quotation'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setQuotations', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },
    async getComissionQuotations(context, payload) {
      const query = payload
      const url = 'quotation/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setQuotations', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async exportComissionQuotations(context, payload) {
      const query = payload
      const url = 'quotation/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setExportQuotations', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updateStatus(context, payload) {
      const { _id, status } = payload
      const url = `quotation/${_id}/status`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { status }, {}, {})
      if (success) {
        context.commit('setQuotationDetailStatus', status)
        context.dispatch('notificationModule/getNotification', {}, { root: true })
      } else {
        httpErrorHandler(message)
      }
    },
    async quotationArrived(context, payload) {
      const { _id, itemId } = payload
      const url = `quotation/${_id}/arrive/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {})
      if (success) {
        context.commit('setQuotationArrived', { _id: _id, itemId })
      } else {
        httpErrorHandler(message)
      }
    },
    async payQuotation(context, payload) {
      const { _id } = payload
      const url = `quotation/${_id}/pay`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async rejectQuotation(context, payload) {
      const { _id } = payload
      const url = `quotation/${_id}/reject`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async refundQuotationItem(context, payload) {
      const { quotationId, itemId } = payload
      const url = `quotation/${quotationId}/refund/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {})
      if (success) {
        context.dispatch('detail', { _id: quotationId })
      }
    },
    async noteQuotationItem(context, payload) {
      const { quotationId, itemId, note } = payload
      const url = `quotation/${quotationId}/note/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {note}, {})
      if (success) {
        context.dispatch('detail', { _id: quotationId })
      }
    },
    async nextStatusQuotationItem(context, payload) {
      const { quotationId, itemId, date, trackingId, chinaMoney } = payload
      const url = `quotation/${quotationId}/nextStatus/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
          date,
          trackingId,
          chinaMoney
      }, {})
      if (success) {
        context.dispatch('detail', { _id: quotationId })
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateCost(context, payload) {
      const url = `quotation/${payload._id}/servicePrice`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async updatePaidAmount(context, payload) {
      const url = `quotation/${payload._id}/paidAmount`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async uploadPaymentImage(context, payload) {
      const { id, image } = payload;
      const url = `quotation/${id}/upload_payment`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {image})
      if (success) {
        context.commit('pushQuotationPaymentImage', { image, id })
        return true
      } else {
        context.commit('appModule/alertMessage',
          {
            showing: true, color: '#dc3545', message
          }, { root: true })
        return false
      }
    }
  }
}