<template>
    <div class="page dashboard transactions-dashboard">
        <div class="content">
            <div class="title-bar">
                <p class="title">Setting, Suggestion</p>
				<div class="group">
					<div class="btn import" ref="import" @click="clickImport">
                        Import
					</div>
                    <input type="file" id="import" style="display: none" @change="selectImport" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
					<div class="btn new">
						<div class="icon"></div>
                        <router-link
                            :to="`/setting/edit-suggestion`"
                            class="label"
                        >
						    Add New
                        </router-link>
					</div>
				</div>
            </div>
            <input placeholder="Search..." type="text" class="search" v-model="search" />
            <ListingComponent model="data" :data="{data:data,pageMeta: {page: 1, size: data.length, totalEntries: data.length, totalPages: 1}}" :query.sync="query"
                :refresh="getData" :toggleActionMenu="handleDataClick" :listingOptions="listingOptions"
                :deleteRecord="deleteData" :isLoading="false"/>
        </div>
    </div>
</template>

<script>
    import ListingLoadingComponent from "../../components/ListingLoadingComponent";
    import ListingComponent from "../../components/ListingComponent";
    import Searchbar from '../../components/Searchbar';
    import readXlsxFile from 'read-excel-file';

    export default {
        name: "SettingSuggestionDashboard",
        components: {
            ListingLoadingComponent,
            ListingComponent,
            Searchbar,
        },
        data: function () {
            return {
                query: {
                    order: "ASC",
                    page: 1,
                    limit: 10,
                    sortBy: "",
                    status: 0,
                },
			    searchTimeout: null,
                search: null,
            };
        },
        created() {
            this.getData();
        },
        methods: {
            clickImport() {
                document.getElementById("import").click();
            },
            selectImport(event) {
                const file = event.target.files ? event.target.files[0] : null;
                
                if(file) {
                    readXlsxFile(file).then(async rows => {
                        rows.shift();

                        const success = await this.$store.dispatch('importSettingSuggestion', {
                            data: rows.map(row => {
                                return {
                                    en: row[1],
                                    km: row[2],
                                    cn: row[3],
                                }
                            }),
                        })
                        
                        if (success) {
                            alert("Successfully import");
                            this.getData();
                        }
                    });
                }
             },
            handleDataClick(action, payload) {
                if(action == 'edit') {
                    this.$router.push({ path: "/setting/edit-suggestion", query: payload });
                }
            },
            getData() {
                this.$store.dispatch("getSettingSuggestion", {
                    query: {
                        search: this.search,
                    }
                });
            },
            deleteData(item) {
                const confirmed = confirm('Are you sure you want to delete this?')
                
                if(confirmed) {
                    this.$store.dispatch("deleteSettingSuggestion", {
                        _id: item._id
                    });
                }
            },
        },
        watch: {
            query: {
                deep: true,
                handler: function () {
                    if (this.query.page >= 1) {
                        this.getData();
                    }
                },
            },
            search: function() {
                this.searchTimeout && clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(
                    () => this.getData(), 500
                )
            }
        },
        computed: {
            data() {
                return this.$store.state.setting.suggestion || [];
            },
            listingOptions() {
                return {
                    hasSelected: false,
                    hasNo: true,
                    actions: ['delete', 'edit'],
                    queryOptions: {
                        sortBy: [],
                        sortDirection: [],
                    },
                    schema: [
                        {
                            label: "English",
                            key: 'en'
                        },
                        {
                            label: "Khmer",
                            key: 'km'
                        },
                        {
                            label: "China",
                            key: 'cn'
                        }
                    ],
                };
            }
        },
    };
</script>

<style></style>