<template>
  <div class="search-bar">
    <form v-on:submit.prevent="handleSubmit" class="search-form">
      <input 
        type="text" 
        class="input" 
        :placeholder="placeholder"
        v-model="searchInput"
        :required="required"
      >
      <div class="submit">
        <button class='btn' type="submit">Search</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput:''
    }
  },
  props: {
    placeholder: {
      type: String,
      default: 'Enter keywords',
      required: false
    },
    onSubmit: {
      type: Function,
      default: function() {
        console.log(this.searchInput)
      },
      required: false
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.searchInput)
    }
  }
}
</script>

<style>

</style>