<template>
  <div class="new-category-form">
    <p class="title">New Group</p>
    <form v-on:submit="handleFormSubmitted">
      <div class="input-ctn">
        <div class="label" >Name</div>
        <input 
          type="text" 
          class="input"
          v-model="en"
          placeholder="English"
        >
        <input 
          type="text" 
          class="input"
          v-model="km"
          placeholder="ភាសាខ្មែរ"
        >
        <input 
          type="text" 
          class="input"
          v-model="cn"
          placeholder="中文"
        >
      </div>
      <p v-if="loading" class="disabled-btn">
        Creating Category Group
      </p>
      <button v-if="!loading" type="submit" class="submit btn">
        Add Category Group
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "NewCategory",
  props: ['addCategoryGroup'],
  data() {
    return {
      loading: false,
      en: "",
      cn: "",
      km: ""
    }
  },
  methods: {
    async handleFormSubmitted(e) {
      e.preventDefault();
      this.loading = true
      let {en, cn, km} = this
      await this.addCategoryGroup({en, cn, km})
      this.loading = false
    }
  }
}
</script>

<style>

</style>