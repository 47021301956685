<template>
  <div class="main-layout">
    <SideMenuComponent />
    <div class="sub-ctn">
      <TopBarComponent />
      <div class="view-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>


<script>
import SideMenuComponent from "../components/SideMenuComponent";
import TopBarComponent from "../components/TopBarComponent";

export default {
  name: "MainLayout",
  components: {
    SideMenuComponent,
    TopBarComponent
  }
};
</script>

<style scoped>
</style>
