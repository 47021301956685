<template>
  <div class="listing-component">
    <table class="standard">
      <tr>
        <th
          v-if="listingOptions.hasSelect"
          class="select"
          v-on:click="toggleSelectAll()"
        >
          <div :class="`select-item ${isSelectAll ? 'active' : ''}`"></div>
        </th>
        <th v-if="listingOptions.hasNo">
          <div class="ctn">
            <p class="label">No</p>
          </div>
        </th>
        <th
          v-for="(column, index) in listingOptions.schema"
          v-bind:key="index"
          :class="`${
            listingOptions.queryOptions.sortBy
              .map((e) => e.value)
              .indexOf(column.key) != -1
              ? 'sort'
              : ''
          } ${
            query.sortBy == column.key && query.order == 'ASC' ? 'asc' : ''
          } ${
            query.sortBy == column.key && query.order == 'DESC' ? 'desc' : ''
          }`"
          v-on:click="toggleSort(column)"
        >
          <div class="ctn">
            <p class="label">{{ column.label }}</p>
          </div>
        </th>
        <th v-if="listingOptions.options" class="option"></th>
      </tr>
      <tbody v-if="!isLoading">
        <tr v-bind:key="record.id" v-for="(record, index) in data[model]">
          <td
            v-if="listingOptions.hasSelect"
            class="select"
            v-on:click="toggleSelect(record.id)"
          >
            <div
              :class="`select-item ${
                selected.indexOf(record.id) != -1 ? 'active' : ''
              }`"
            ></div>
          </td>
          <td v-if="listingOptions.hasNo">
            <p class="value">
              {{ (query.page - 1) * query.limit + index + 1 }}
            </p>
          </td>
          <td
            v-for="(column, index) in listingOptions.schema"
            v-bind:key="index"
            :style="{ width: `${column.width ? column.width : ''}` }"
            :class="`${column.hasImage ? 'has-image' : ''}`"
            v-on:click="toggleActionMenu('read', record)"
          >
            <img
              v-for="(img, i) in record.images"
              :src="`${
                column.customImageUrl ? API_URL + '/' + img.image : false
              }`"
              :key="i"
              style="
                width: 100%;
                height: auto;
                max-width: 50px;
                cursor: pointer;
              "
              v-if="imageColumn"
              data-lity
              :data-lity-target="`${API_URL}/${img.image}`"
              alt=""
            />
            <div
              v-if="column.hasImage"
              class="image"
              :style="{
                'background-image': `url(${
                  column.imageUrl(record)
                    ? column.imageUrl(record)
                    : 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png'
                })`,
              }"
            ></div>
            <div
              class="value"
              :style="colorStatus(column.value ? column.value(record) : '')"
              v-html="column.value ? column.value(record) : record[column.key]"
            >
            </div>
          </td>
          <td v-if="listingOptions.actions" class="option">
            <div class="option-menu">
              <div
                v-if="
                  listingOptions.actions.indexOf('called') == -1 ? false : true && !record['isFollowedUp']
                "
                class="btn edit"
                v-on:click="calledRecord(record)"
              >
                Called
              </div>
              <div
                v-if="
                  listingOptions.actions.indexOf('edit') == -1 ? false : true
                "
                class="btn edit"
                v-on:click="toggleActionMenu('edit', record)"
              >
                Edit
              </div>
              <div
                v-if="
                  listingOptions.actions.indexOf('delete') == -1 ? false : true
                "
                class="btn delete"
                v-on:click="deleteRecord(record)"
              >
                Delete
              </div>
              <div
                v-if="
                  listingOptions.actions.indexOf('recover') == -1 ? false : true
                "
                class="btn delete"
                v-on:click="recoverRecord(record)"
              >
                Recover
              </div>
              <div
                v-if="listingOptions.actions.indexOf('accept') == -1 ? false : true"
                class="btn accept"
                v-for="(column, index) in listingOptions.schema"
                v-show="enableActionLabel(column.value ? column.value(record) : record[column.key])"
                v-on:click="acceptRecord(record)"
              >
                Accept
              </div>
              <div v-if="listingOptions.actions.indexOf('reject') == -1 ? false : true"
                v-for="(column, index) in listingOptions.schema"
                v-show="enableActionLabel(column.value ? column.value(record) : record[column.key])"
                class="btn delete"
                v-on:click="rejectRecord(record)"
              >
                Reject
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody class="loading" v-else>
        <tr v-bind:key="item" v-for="item in new Array(query.limit)"></tr>
        <tr v-bind:key="record.id" v-for="record in data[model]">
          <td v-if="listingOptions.hasSelect" class="select">
            <div class="select-item"></div>
          </td>
          <td v-if="listingOptions.hasNo">
            <p class="value"></p>
          </td>
          <td
            v-for="(column, index) in listingOptions.schema"
            v-bind:key="index"
            :style="{ width: `${column.width ? column.width : ''}` }"
            class="item"
          >
            <div
              v-if="column.hasImage"
              class="image"
              :style="{ 'background-image': `url(${column.imageUrl(record)})` }"
            ></div>
          </td>
          <td v-if="listingOptions.actions" class="option">
            <div class="icon"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <div class="group">
        <div class="label">Page:</div>
        <input
          type="number"
          class="input small"
          min="1"
          v-model="query.page"
          placeholder="1"
        />
      </div>
      <div class="info">
        Showing:
        {{
          `${(query.page - 1) * query.limit + 1} - ${
            (query.page - 1) * query.limit +
            (data[model] ? data[model].length : 0)
          } of ${data.pageMeta ? data.pageMeta.totalEntries : "--"}`
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../const";
export default {
  name: "Listing-Component",
  data() {
    return {
      API_URL,
    };
  },
  computed: {
    isSelectAll() {
      return (
        this.data[this.model] &&
        JSON.stringify(this.data[this.model].map((u) => u.id).sort()) ===
          JSON.stringify(this.selected.sort())
      );
    },
  },
  props: {
    enableAcceptButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableRejectButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    model: {
      type: String,
      required: false,
      default: "",
    },
    data: {
      type: Object,
      required: true,
      default: {},
    },
    refresh: {
      type: Function,
      required: false,
      default: () => {},
    },
    toggleActionMenu: {
      type: Function,
      required: false,
      default: () => {},
    },
    selected: {
      type: Array,
      required: false,
      default: () => [],
    },
    listingOptions: {
      type: Object,
      default: {},
    },
    deleteRecord: {
      type: Function,
      required: false,
    },
    recoverRecord: {
      type: Function,
      required: false,
    },
    calledRecord: {
      type: Function,
      required: false,
    },
    query: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    imageColumn: {
      type: Boolean,
      required: false,
    },
    rejectRecord:{
      type:Function,
      required:false
    },
    acceptRecord:{
      type:Function,
      required:false
    }
  },
  methods: {
    toggleSelect(itemId) {
      const index = this.selected.indexOf(itemId);
      if (index == -1) {
        this.selected.push(itemId);
      } else {
        this.selected.splice(index, 1);
      }
    },
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.selected = [];
      } else {
        this.selected = this.data[this.model].map((u) => u.id);
      }
    },
    toggleSort(column) {
      if (this.query.sortBy == column.key) {
        if (this.query.order == "ASC") {
          this.query.order = "DESC";
        } else {
          this.query.order = "ASC";
        }
      } else {
        this.query.sortBy = column.key;
      }
    },
    colorStatus(status) {
      if (status === "ACCPETED") {
        const color = "color:green";
        return color;
      }
      if (status === "REJECTED") {
        const color = "color:red";
        return color;
      }
      if (status === "PENDING") {
        const color = "color:orange";
        return color;
      }
      return "";
    },
    enableActionLabel(actionlabel) {
      if (actionlabel === "PENDING") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped></style>
