import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    percentageCuts: {
      
    }
  },
  mutations: {
    setPercentageCuts(state, payload) {
      state.percentageCuts = payload
    }
  }, 
  actions: {
    async getPercentageCuts(context, payload) {
      const url = 'percentageCut'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) {
        context.commit('setPercentageCuts', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updatePercentageCuts(context, payload) {
      const { _id, servicePercentage, insurancePercentage } = payload
      const url = `percentageCut/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", {servicePercentage, insurancePercentage}, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
      }
    }
  }
}