import { HttpRequest, httpErrorHandler } from "../../handler"

const SET_MERCHANTS = 'setMerchants'
const SET_LOADING_MERCHANTS = 'setLoadingMerchants'
const SET_CREATING_MERCHANT = 'setCreatingMerchant'

export default {
  namespaced: true,
  state: {
    isLoadingMerchant: false,
    isCreatingMerchant: false,
    merchants: { pageMeta: null, merchants: []},
  },
  mutations: {
    [SET_MERCHANTS](state, payload) {
      state.merchants = payload
    },
    [SET_LOADING_MERCHANTS](state, payload) {
      state.isLoadingMerchant = payload
    },
    [SET_CREATING_MERCHANT](state, payload) {
      state.isCreatingMerchant = payload
    },
  },
  actions: {
    async createMerchant(context, payload) {
      context.commit(SET_CREATING_MERCHANT, true)
      const url = 'merchant'
      console.log(payload);
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
      context.commit(SET_CREATING_MERCHANT, false)
      if(success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async getMerchantDetails(context, payload) {
      const {id} = payload
      const url = `merchant/${id}`
      const {success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if(success) {
        return result
      } else {
        httpErrorHandler(message)
      }
    },
    async getMerchants(context, payload) {
      context.commit(SET_LOADING_MERCHANTS, true) 
      const {query} = payload
      const {name, ...rest} = query
      const url = "merchant"
      const {success, result, message } = await HttpRequest.authorizedRequest(url, "GET", {}, {...rest,name: name? name: undefined})
      if(success) {
        console.log(result);
        context.commit(SET_MERCHANTS, result)
        context.commit(SET_LOADING_MERCHANTS, false)
      } else {
        httpErrorHandler(message)
        context.commit(SET_LOADING_MERCHANTS, false)
        return false
      }
    },
    async editMerchant(context, payload) {
      context.commit(SET_LOADING_MERCHANTS, true)
      const {merchantId, logo, name, slogan} = payload
      console.log(payload);
      const url = `merchant/${merchantId}`
      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'PATCH', {logo, name, slogan}, {})
      if(success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async deleteMerchant(context, payload) {
      const {_id} = payload
      const url = `merchant/${_id}`
      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'DELETE', {}, {})
      if(success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}