<template>
  <div class="page dashboard top-up-method-page">
    <div class="content">
      <div class="title-bar">
        <div class="title">Top Up Method</div>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">New method</p>
          </div>
        </div>
      </div>
      <ListingComponent
        model="data"
        :isLoading="isLoading"
        :data="topUpInfo"
        :query.sync="query"
        :refresh="getInfo"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions ="listingOptions"
        :deleteRecord="deleteMethod"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active': ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <NewMethodForm
        :onMethodCreated="onMethodCreated"
        v-if="isActionMenuOpen === 'new'"
      />
      <EditMethodForm 
        :onMethodUpdated="onMethodUpdated"
        v-if="isActionMenuOpen === 'read'"
        :topUpInfo="focusedMethod"
      />
    </div>
  </div>
</template>

<script>
import ListingComponent from '../../components/ListingComponent'
import NewMethodForm from './NewMethodForm'
import EditMethodForm from './EditMethodForm'
export default {
  name: 'TopUpMethod',
  components: {
    ListingComponent,
    NewMethodForm,
    EditMethodForm
  }, 
  data() {
    return {
      focusedMethod: {},
      isActionMenuOpen: "",
      isLoading: true,
      query: {
        order: "ASC",
        page: 1,
        limit: 10
      }
    }
  }, 
  mounted() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      this.isLoading = true
      await this.$store.dispatch("topupModule/getInfo", {
        query: this.query
      })
      this.isLoading = false
    },
    toggleActionMenu(menu, payload = {}) {
			if (menu !== "") {
				if (menu === "edit" || menu === "read") {
					this.focusedMethod = payload;
				}
			}
			this.isActionMenuOpen = menu;
    },
    onMethodCreated() {
      this.isLoading = false
      this.toggleActionMenu('')
      this.getInfo()
    },
    onMethodUpdated() {
      this.isLoading = false
      this.toggleActionMenu('')
      this.getInfo() 
    },
    async deleteMethod(method) {
      this.isLoading = true
      const deteled = await this.$store.dispatch('topupModule/deleteMethod', {
        _id: method._id
      })
      this.toggleActionMenu('')
      this.getInfo()
    }
  }, 
  computed: {
    listingOptions() {
      return {
        hasSelect: false,
        actions: ['delete'],
        queryOptions: {
          sortBy: [],
          sortDirection: [
            {
              label: "Ascending",
              value: "ASC"
            }, 
            {
              label: "Descending",
              value: "DESC"
            }
          ]
        },
        schema: [
          { label: "Title", key: 'title'},
          { label: "Description", key: 'description'},
        ]
      }
    }, 
    topUpInfo() {
      return this.$store.state.topupModule.infoList
    }
  }
}
</script>

<style>

</style>