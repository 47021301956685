<template>
  <div class="page dashboard sale-person-dashboard">
    <div class="content">
      <div
				v-if="isActionMenuOpen"
				class="action-menu-backdrop"
				v-on:click="toggleActionMenu('')"
			></div>

      <div class="title-bar">
        <div class="title">Sale Person</div>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">New Sale Person</p>
          </div>
        </div>
      </div>
        <input placeholder="Search by name,code,phone number..." type="text" class="search" v-model="search" />
      <ListingComponent 
        model="data"
        :isLoading="isLoading"
        :data="salePeople"
        :query.sync="query"
        :refresh="getListing"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
        :deleteRecord="deleteSalePerson"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active': '' }`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <NewSalePersonForm 
        v-if="isActionMenuOpen === 'new'"
        :addSalePerson="addSalePerson"
      />
      <EditSalePersonForm 
        v-if="isActionMenuOpen === 'read'"
        :editSalePerson="editSalePerson"
        :salePerson="focusedPerson"
      />
    </div>
  </div>
</template>

<script>
import ListingComponent from '../../components/ListingComponent'

import NewSalePersonForm from './NewSalePersonForm'
import EditSalePersonForm from './EditSalePersonForm'
import { WEBSITE_URL } from "../../const";

export default {
  name: "SalePersonDashboard",
  components: {
    ListingComponent,
    NewSalePersonForm,
    EditSalePersonForm
  },
  data() {
    return {
      focusedPerson: {},
      isActionMenuOpen: "",
      isLoading: true,
      search: null,
      searchTimeout: null,
      query: {
        order: "ASC",
        page: 1,
        limit: 10
      }
    }
  },
  mounted() {
    this.getListing()
  },
  methods: {
    async getListing() {
      this.isLoading = true
      await this.$store.dispatch("salePersonModule/getSalePeople", {
        query: {
            ...this.query,
            search: this.search
        }
      })
      this.isLoading = false
    },
    async addSalePerson(payload) {
      const created = await this.$store.dispatch("salePersonModule/createSalePerson", payload)
      if(created) {
        this.getListing()
        this.toggleActionMenu("")
      }
    },
    async editSalePerson(payload) {
      const { name, phoneNumber } = payload
      const updated = await this.$store.dispatch("salePersonModule/editSalePerson", {
        _id: this.focusedPerson._id,
        name,
        phoneNumber
      })
      if(updated) {
        this.getListing() 
        this.toggleActionMenu("")
      }
    },
    async deleteSalePerson(item) {
        const confirmed = confirm('Are you sure you want to delete this?')
        
        if(confirmed) {
            const res = await this.$store.dispatch("salePersonModule/deleteSalePerson", {
                _id: item._id
            })
            if(res) {
                this.getListing() 
                this.toggleActionMenu("")
            }
        }
    },
    toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedPerson = {...payload};
				}
			}
			this.isActionMenuOpen = menu;
		},
    },
    watch: {
        query: {
            deep: true,
            handler: function () {
                if (this.query.page >= 1) {
                    this.getListing();
                }
            },
        },
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getListing(), 500
            )
        }
    },
    computed: {
        listingOptions() {
        return {
            hasSelect: false,
            actions: ['delete'],
            queryOptions: {
                sortBy: [],
                sortDirection: [
                    {
                    label: "Ascending",
                    value: "ASC"
                    }, 
                    { 
                    label: "Descending",
                    value: "DESC"
                    }
                ]
            }, 
            schema: [
                { label: "Name", key: "name" },
                { label: "phoneNumber", key: "phoneNumber" },
                { label: "Code", key: "code"},
                {
                    label: "Sign Up Link",
                    key: "link",
                    value: (record) => {
                        const web_url = WEBSITE_URL || 'https://nokorshop.com';
                        return '<a target="_blank" style="color: blue" href="' + web_url + '/signup?saleId=' + record.code + '">' + web_url + '/signup?saleId=' + record.code + '</a>'
                    },
                }
            ]
        }
    },
    salePeople() {
        return this.$store.state.salePersonModule.salePeople
    }
  }
}
</script>

<style>

</style>