<template>
	<div class="sale-person-form">
		<p class="title">Edit Sale Person</p>
		<form v-on:submit.prevent="handleFormSubmitted">
			<div class="input-ctn">
				<div class="label">Name</div>
				<input v-model="name" type="text" class="input" placeholder="Name" />
				<input v-model="phoneNumber" type="text" class="input" placeholder="Phone Number" />
			</div>
			<button type="submit" class="btn submit">Edit</button>
		</form>
	</div>
</template>

<script>
export default {
	name: "EditSalePersonForm",
	props: ["editSalePerson", "salePerson"],

	data() {
		return {
			loading: false,
			name: this.salePerson.name,
			phoneNumber: this.salePerson.phoneNumber,
		};
	},
	methods: {
		async handleFormSubmitted() {
			this.loading = true;
			await this.editSalePerson({ name: this.name, phoneNumber: this.phoneNumber });
			this.loading = false;
		},
	},
};
</script>

<style></style>
