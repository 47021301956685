<template>
    <div class="page dashboard transaction-details">
        <div class="content">
            <div class="title-bar" style="justify-content: normal;">
                <p class="title">Transaction ({{transaction.transactionId}}) </p>
                <div class="icon" style="
                    background-image: url(/icons/copy.png); 
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-left: 10px;
                    cursor: pointer;
                    width: 2rem; 
                    height: 2rem;"

                    @click="copy(transaction.transactionId, 'ID ')"
                >
                </div>
            </div>
            <router-link to="/transactions">Back</router-link>
            <p v-if="isLoading">Fetching Data</p>
            <div class="page-content" v-if="!isLoading">
                <div class="transaction-info">
                    <div class="user-info">
                        <p class="bold">Username:
                            {{ transaction.createBy ? transaction.createBy.name :'(Deleted Account)' }}</p>
                        <p>Receiver Name: {{ transaction.shippingDetail.name }}</p>
                        <p>
                            Phone number:
                            {{ transaction.shippingDetail.phoneNumber }}
                        </p>
                        <div class="icon" style="
                            background-image: url(/icons/copy.png); 
                            background-size: contain;
                            background-repeat: no-repeat;
                            margin-left: 10px;
                            cursor: pointer;
                            width: 1rem; 
                            height: 1rem;"

                            @click="copy(transaction.shippingDetail.phoneNumber, 'Phone Number ')"
                        >
                        </div>
                    </div>
                    <div class="shipping-info">
                        <p class="bold">Shipping:</p>
                        <p>Address: {{ transaction.shippingDetail.address }}</p>
                        <p>Region Code: {{ transaction.shippingDetail.region }}</p>
                        <p>Region: {{getRegionName(transaction.shippingDetail.region)}}</p>
                    </div>
                    <div class="shipping-methods">
                        <p class="bold">Shipping Setting</p>
                        <p>Shipping Method: {{transaction.shippingMethod.name}}</p>
                        <p>Delivery Method: {{transaction.transportMethod === 0 ? 'Self-pickup': 'Nokor delivery'}}
                        </p>
                        <p>Insurance: {{transaction.insurance ? 'Yes': 'No'}}</p>
                    </div>
                    <div class="payment">
                        <p class="bold">
                            <span style="float: left;"> Payment </span>                            
                            <span style="float: right;"><p class="btn confirm" @click="confirmPaymentModalOpen = true" v-show="transaction.paid_amount == 0 && !transaction.confirmedPaymentAt && !isConfirmPayment &&  !transaction.deletedAt">Confirm</p></span>
                        </p>
                        <br>
                        <p>Status: 
                            <span v-if="transaction.paid_amount == 0" :class="transaction.confirmedPaymentAt || isConfirmPayment ? 'text-success' : 'text-warning'">{{ transaction.confirmedPaymentAt || isConfirmPayment ? "Confirmed" : "Pending" }}</span>
                            <span v-else class="text-success">Paid</span>
                        </p>
                        <p>Total Insurance: {{(transaction.totalInsurance).toFixed(2)}}</p>
                        <p>Total Service Cost: {{(transaction.totalServiceCost).toFixed(2)}}</p>
                        <p>Total: {{(transaction.total).toFixed(2)}}</p>
                        <img
                            v-for="image in transaction.transactionUploadImages" :key="image"
                            class="order-image"
                            :src="`${API_URL}/${image.image}`"
                            data-lity 
                            :data-lity-target="`${API_URL}/${image.image}`"
                            width="50px"
                            style="cursor: pointer;"
                            alt=""
                        />
                    </div>
                </div>
                <div class="items-list">
                    <p class="bold big">Items</p>
                    <div class="product-list">
                        <div class="product-item" v-for="(product, index) in orderItems" :key="index">
                            <div class="text_num" style="background: #333; color: #fff; padding: .1rem .4rem; border-radius: .2rem;">
                                <ul>
                                    <li>{{ index + 1 }}</li>
                                </ul>
                            </div>
                            <img :src="product.product.mainImage" style="margin-left: 1rem;" alt class="image" />
                            <div class="product-info">
                                
                                <div style="width: 80%;">
                                    <p class="small bold">{{ product.product.name }}</p>
                                </div>
                                

                                <p class="small" v-for="variation in product.product.variations" :key="variation.name">
                                    {{ variation.name }}: {{ variation.data[0].value }}
                                    <img :src="variation.data[0].image" v-if="variation.data[0].image" data-lity :data-lity-target="variation.data[0].image" alt style="width:32px; height: 32px" />
                                </p>
                                <p class="small bold">Price:
                                    {{product.product.price.maxPrice ? `$ ${product.product.price.maxPrice}/per unit (x ${product.amount}) = $ ${(product.product.price.maxPrice * product.amount).toFixed(2)}`: 'Unknown'}}
                                </p>
                                <p class="small bold">Service Charge: $ {{product.serviceCost}}</p>
                                <p class="small bold" v-if="product.insuranceCost">Insurance Charge: $
                                    {{product.insuranceCost}}</p>
                                <p class="small bold">Total: $ {{((product.product.price.maxPrice * product.amount) + product.serviceCost + product.insuranceCost).toFixed(2)}}</p>
                                <a v-if="product.product.url? !product.product.url.includes('localhost'): false"
                                    target="_blank" :href="product.product.url" class="link bold">Check Product</a>
                            </div>
                            <div class="btn-group" v-if="transaction.status === 0 && !transaction.deletedAt">
                                <template v-if="product.isApproved === undefined">
                                    <p class="btn confirm" v-on:click="availableProduct(product.product)">Available</p>
                                    <p class="btn reject" v-on:click="openRejectProduct(product.product)">Reject</p>
                                </template>
                                <p v-if="product.isApproved === true" class="approved">Approved</p>
                                <p v-if="product.isApproved === false" class="rejected">Rejected</p>
                            </div>
                        </div>
                    </div>
                    <div class="submit-order" v-if="!transaction.deletedAt">
                        <p v-if="!allowSubmit()" class="disabled" style="cursor: pointer">Add order</p>
                        <p v-if="allowSubmit()" class="add-order" style="cursor: pointer" v-on:click="openOthercostModal">Add order</p>
                    </div>
                </div>
            </div>
        </div>
        <ProductPricingModal :onSubmit="rejectProduct" :closeModal="closeRejectModal" v-if="rejectModalOpened"/>
        <OtherCostModal :closeModal="clostOthercostModal" v-if="otherCostModalOpened" :onSubmit="createOrder"
            :needInsurance="transaction.insurance" />
        <ProductPricingModal :onSubmit="confirmPayment" :closeModal="clostConfirmPaymentModal" v-if="confirmPaymentModalOpen"/>
    </div>
</template>

<script>
    import ProductPricingModal from './ProductPricingModal'
    import OtherCostModal from './OtherCostModal'
    import { API_URL } from "../../../src/const";
    var _ = require("lodash");
    require('lity');
    import {
        REGION_CODE
    } from '../../enums'

    export default {
        name: "TransactionDetails",
        components: {
            ProductPricingModal,
            OtherCostModal
        },
        data() {
            return {
                isLoading: true,
                orderItems: [],
                rejectModalOpened: false,
                otherCostModalOpened: false,
                focusedRejectProduct: {},
                API_URL: API_URL,
                confirmPaymentModalOpen: false,
                isConfirmPayment: false
            };
        },
        methods: {
            copy(text, label) {
                if (window.clipboardData && window.clipboardData.setData) {
                    alert('Copy ' + label + 'successfully');
                    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
                    return window.clipboardData.setData("Text", text);
                } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                    var textarea = document.createElement("textarea");
                    textarea.textContent = text;
                    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                        alert('Copy ' + label + 'successfully');
                        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
                    } catch (ex) {
                        alert('Copy failed');
                        console.warn("Copy to clipboard failed.", ex);
                        return false;
                    } finally {
                        document.body.removeChild(textarea);
                    }
                }
            },
            getRegionName(key) {
                return _.invert(REGION_CODE)[key] || key
            },
            async getTransactionDetails() {
                await this.$store.dispatch("transactionsModule/getTransactionDetails", {
                    _id: this.transactionId,
                });
                this.isLoading = false;
            },
            openRejectProduct(product) {
                this.focusedRejectProduct = product;
                this.rejectModalOpened = true
            },
            closeRejectModal() {
                this.rejectModalOpened = false
            },
            openOthercostModal() {
                this.otherCostModalOpened = true
            },
            clostOthercostModal() {
                this.otherCostModalOpened = false
            },
            rejectProduct() {
                this.orderItems = this.orderItems.map(i => {
                    if (i.product._id === this.focusedRejectProduct._id) {
                        i.isApproved = false
                    }
                    return i
                })
            },
            allowSubmit() {
                let allow = true
                this.orderItems.map(i => {
                    if (i.isApproved === undefined) {
                        allow = false
                    }
                })
                return allow && (this.transaction.paid_amount != 0 || this.transaction.confirmedPaymentAt || this.isConfirmPayment);
            },
            availableProduct(product) {
                this.orderItems = this.orderItems.map(i => {
                    if (i.product._id === product._id) {
                        // i.shippingCost = shippingCost
                        // i.product.price.maxPrice = productPrice
                        i.isApproved = true
                    }
                    return i;
                })
            },
            async createOrder({
                otherCost,
                localDeliveryCost,
                internationalShippingCost,
                chineseShippingCost
            }) {
                const {
                    orderItems
                } = this
                const {
                    shippingDetail,
                    shippingMethod,
                    transportMethod
                } = this.transaction
                const createBy = this.transaction.createBy._id
                const status = 0
                await this.$store.dispatch('orderModule/createOrder', {
                    orderId: this.transaction.transactionId,
                    transaction: this.transaction._id,
                    orderItems,
                    shippingDetail,
                    shippingMethod,
                    transportMethod,
                    createBy,
                    otherCost,
                    localDeliveryCost,
                    internationalShippingCost,
                    chineseShippingCost,
                    status
                })
                alert('Order has been created')
                this.$router.replace('/orders')
            },
            async confirmPayment() {
                await this.$store.dispatch('transactionsModule/confirmPayment', {
                    _id: this.transaction._id
                });
                this.isConfirmPayment = true;
                alert('Order has been confirm payment');
            },
            clostConfirmPaymentModal() {
                this.confirmPaymentModalOpen = false
            },
        },
        created() {
            this.getTransactionDetails();
        },
        computed: {
            transactionId() {
                return this.$route.params.id;
            },
            transaction() {
                let transaction = this.$store.state.transactionsModule.transactionDetails;
                this.orderItems = transaction.transactionItems
                let total = 0;
                let totalInsurance = 0;
                let totalServiceCost = 0;
                transaction.transactionItems?.map((i) => {
                    total += ((i.product.price.maxPrice * i.amount) + i.serviceCost + i.insuranceCost);
                    totalInsurance += i.insuranceCost;
                    totalServiceCost += i.serviceCost;
                });
                transaction.total = total;
                transaction.totalInsurance = totalInsurance;
                transaction.totalServiceCost = totalServiceCost;
                return transaction
            },
        },
    };
</script>

<style src="lity/dist/lity.min.css"></style>
