<template>
  <div class="new-merchant-form">
    <p class="btn" v-on:click="toggleActionMenu('edit')">Back</p>
    <p class="title">New Merchant</p>
    <p>User: {{user.name}}</p>
    <form v-on:submit="handleFormSubmit">
      <label for="merchant-img" class="image-preview btn">
        <p v-if='!file._id'>Select an image</p>
        <img class="image" v-if='file._id' :src="imageSrc" alt="">
      </label>
      <input id="merchant-img" class="file-input" type="file" @change="handleImageSelected">
      <div class="input-ctn">
        <div class="label">Name</div>
        <input required type="text" class="input" v-model="name" placeholder="Name">
      </div>
      <div class="input-ctn">
        <div class="label">Slogan</div>
        <input required type="text" class="input" v-model="slogan" placeholder="Slogan">
      </div>
      <p v-if="loading" class="disabled-btn">
        {{loading}}
      </p>
      <button v-if="!loading" class="submit btn">
        Save
      </button>
    </form>
  </div>
</template>

<script>
import { FileUpload } from "../../handler";
import { API_URL } from "../../const";
export default {
  name: "NewMerchantForm",
  props: ['toggleActionMenu', 'createMerchant', 'user'],
  data() {
    return {
      name: "",
      slogan: "",
      logo: "",
      loading: false,
      file: {},
      imageSrc: null
    }
  },
  methods: {
    async handleFormSubmit(e) {
      e.preventDefault();
      this.loading = 'Creating Merchant . . .'
      const created = await this.$store.dispatch('merchantModule/createMerchant', {
        owner: this.user._id,
        name: this.name, 
        slogan: this.slogan,
        logo: this.logo
      })
      this.loading = false
      created && alert('Merchant Created')
      this.toggleActionMenu('edit')
    },
    async handleImageSelected(e) {
      const image = e.target.files[0]
      this.loading = 'Uploading Image . . .'
      this.imageSrc = URL.createObjectURL(image)
      const file = await FileUpload.uploadFile(image)
      this.loading = false
      this.file=file
      this.logo=file.path
      
    }
  },
  computed: {
    API_URL() {
      return API_URL
    }
  }
}
</script>

<style>

</style>