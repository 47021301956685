export const REGION_CODE = {
  'Phnom Penh, 7 Makara': 'PPA',
  'Phnom Penh, Khan Toul Kork': 'PPB',
  'Phnom Penh, Daun Penh': 'PPC',
  'Phnom Penh, Dong Kao': 'PPD',
  'Phnom Penh, Mean Chey': 'PPE',
  'Phnom Penh, Russey keo': 'PPF',
  'Phnom Penh, Por Sen Chey': 'PPG',
  'Phnom Penh, Sen Sok': 'PPH',
  'Phnom Penh, Chamkarmon': 'PPI',
  'Phnom Penh, Chbar Ampov': 'PPJ',
  'Phnom Penh, Chrouy Changva': 'PPK',
  'Phnom Penh, Preaek Pnov': 'PPL',
  'Banteay Meanchey': 'BTM',
  'Battambang': 'BTB',
  'Kampong Cham': 'KCM',
  'Kampong Chhnang': 'KCG',
  'Kampong Speu': 'KPS',
  'Kampong Thom': 'KPT',
  'Kampot': 'KMT',
  'Kandal': 'KDL',
  'Koh Kong': 'KKG',
  'Kratié': 'KTE',
  'Mondulkiri': 'MDK',
  'Preah Vihear': 'PVR',
  'Prey Veng': 'PVG',
  'Pursat': 'PST',
  'Ratanak Kiri': 'RTK',
  'Siem Reap': 'SRP',
  'Preah Sihanouk': 'PSK',
  'Stung Treng': 'STT',
  'Svay Rieng': 'SRG',
  'Takéo': 'TKO',
  'Oddar Meanchey': 'ODM',
  'Kep': 'KEP',
  'Pailin': 'PLN',
  'Tboung Khmum': 'TKM',
  'Other': "OTH"
}
