<template>
	<div class="page dashboard quotation-dashboard">
		<div class="content">
			<div class="title-bar">
				<p class="title">VIP Quotation Management</p>
				<div class="group" v-if="['admin', 'financer'].includes(currentUser.role.name)">
					<div class="btn new">
						<div class="icon"></div>
                        <router-link
                            :to="`/quotations/new`"
                            class="label"
                        >
						    Add New
                        </router-link>
					</div>
				</div>
			</div>
			<TabBarComponent
				:isLoading="false"
				:query.sync="query"
				:data="status"
				queryKey="status"
				:label="(r) => r.label"
				:key="false"
				:hasAll="false"
			/>
            <input placeholder="Search for quotations..." type="text" class="search" v-model="search" />
            <input
                v-model="vipCustomerLabel"
                type="text"
                class="search"
                style="margin-left: 10px"
                placeholder="Vip Customer"
                @focus="openVipCustomerModal"
            />
            <br>
            <input type="date" class="search" style="width: 20%; margin-top:10px" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; margin-bottom: 10px; width: 20%" v-model="query.toDate">
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
			<ListingComponent
				model="data"
				:isLoading="isLoadingQuotation"
				:data="quotations"
				:query.sync="query"
				:refresh="getQuotations"
				:toggleActionMenu="handleQuotationClick"
				:listingOptions="listingOptions"
				:deleteRecord="deleteQuotation"
                :recoverRecord="recoverQuotation"
			/>
		</div>
        <SelectVipCustomerModal :closeModal="closeVipCustomerModal" v-show="vipCustomerModalOpened"
            :onSubmit="selectedVipCustomer" />
	</div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";
import moment from "moment";
import SelectVipCustomerModal from "../../components/SelectVipCustomerModal";

export default {
	name: "QuotationDashboard",
	components: {
		ListingLoadingComponent,
		TabBarLoadingComponent,
		ListingComponent,
		TabBarComponent,
        SelectVipCustomerModal
	},
	data() {
        const status = [
            { _id: -1, label: "All" },
            { _id: 0, label: "Pending" },
            { _id: 1, label: "Confrimed" },
            { _id: 2, label: "Rejected" },
            { _id: 3, label: "Canceled" },
        ];

        if(['admin', 'financer'].includes(this.$store.state.usersModule.currentUser.role.name)) {
            status.push({ _id: 0.9, label: "Delete" });
        }
    
		return {
            searchTimeout: null,
            search: null,
			isLoadingQuotation: false,
			query: {
				quotation: "ASC",
				page: 1,
				limit: 10,
				sortBy: "createdAt",
				status: 0,
                fromDate: null,
                toDate: null
			},
			status,
            customer: null,
            vipCustomerLabel: null,
            vipCustomerModalOpened: false,
            excelName: 'Quotation-'+moment().format('YYYY-MM-DD')+'.xls'
		};
	},
	created() {
		this.getQuotations();
        this.$store.dispatch("usersModule/getUserRoles");
	},
	methods: {
        openVipCustomerModal() {
            this.vipCustomerModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectVipCustomerModelSearchInput").focus();
            })
        },
        closeVipCustomerModal() {
            this.vipCustomerModalOpened = false;
        },
        selectedVipCustomer(vipCustomer) {
            const id = vipCustomer ? vipCustomer._id : null;
            if (this.customer == id) {
                return;
            }

            if (vipCustomer) {
                this.vipCustomerLabel = vipCustomer.name + ' - ' + vipCustomer.phoneNumber + " (" + vipCustomer
                    .code + ")";
            } else {
                this.vipCustomerLabel = null;
            }
            this.customer = id;
            this.getQuotations();
        },
        async excelData() {
            const res = await this.$store.dispatch("quotationModule/getQuotations", {
                export: true,
                ...this.query, 
                searchValue: this.search,
                customerId: this.customer
            });

            return res.data.map((record, index) => {
                let total = 0;
                let totalChinaMoney = 0;
                record.quotationItems?.map((i) => {
                    const productPrice = i.price * i.amount;
                    total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                    totalChinaMoney += i.chinaMoney;
                });

                total += record.internationalShippingCost - record.discountAmount 

                return {
                    '#': index + 1,
                    'ID': record.quotationId,
                    'Quotation No': record.quotationNo,
                    'Customer': record.customer ? record.customer.name + " - " + record.customer.phoneNumber + " (" + record.customer.code + ")" : 'N/A',
                    // 'Total ¥': totalChinaMoney.toFixed(2),
                    'Total': total.toFixed(2),
                    // 'Paid': record.paidAmount.toFixed(2),
                    // 'Due': (total - record.paidAmount).toFixed(2),
                    'Date': moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                    'Status': this.getStatus(record.status)
                };
            });
        },
		async getQuotations() {
			this.isLoadingQuotation = true;
            await this.$store.dispatch("quotationModule/getQuotations", {
                ...this.query, 
                searchValue: this.search,
                customerId: this.customer
            });
			this.isLoadingQuotation = false;
		},
		handleQuotationClick(action, payload) {
			window.open(`/quotations/${payload._id}`);
		},
		deleteQuotation(item) {
            const confirmed = confirm('Are you sure you want to delete this?')
            
            if(confirmed) {
                this.$store.dispatch("quotationModule/delete", {
                    _id: item._id
                });

                this.getQuotations();
            }
        },
        recoverQuotation(item) {
            const confirmed = confirm('Are you sure you want to recover this?')
            
            if(confirmed) {
                this.$store.dispatch("quotationModule/recover", {
                    _id: item._id
                });
                
                this.getQuotations();
            }
        },
		getStatus(status) {
			switch (status) {
				case -1:
					return "All";
				case 0:
					return "Pending";
				case 1:
					return "Confirmed";
				case 2:
					return "Rejected";
				case 3:
					return "Canceled";
			}
		},
  },
  watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getQuotations();
				}
			},
		},
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getQuotations(), 500
            )
        }
	},
	computed: {
		quotations() {
			return this.$store.state.quotationModule.quotations;
		},
		listingOptions() {
            let actions = [];
            if(['admin', 'financer'].includes(this.$store.state.usersModule.currentUser.role.name)) {
                if(this.query.status == 0) {
                    actions = ["delete"];
                } else if (this.query.status == 0.9) {
                    actions = ["recover"];
                }
            }

			return {
				hasSelected: false,
				hasNo: true,
				actions,
				queryOptions: {
					sortBy: [{ label: "CreatedAt", value: "createdAt" }],
					sortDirection: [
						{ label: "Ascending", value: "ASC" },
						{ label: "Descending", value: "DESC" },
					],
				},
				schema: [
					{ label: "ID", key: "quotationId" },
					{ label: "Quotation No", key: "quotationNo" },
					{
						label: "VIP Customer",
						key: "createBy",
						value: (record) => record.customer ? record.customer.name + " - " + record.customer.phoneNumber + " (" + record.customer.code + ")" : 'N/A',
					},
					// {
					// 	label: "Total ¥",
					// 	value: (record) => {
                    //         let total = 0;
                    //         record.quotationItems?.map((i) => {
                    //             total += i.chinaMoney;
                    //         });
                    //         return total.toFixed(2);
                    //     },
					// },
					{
						label: "Total",
						value: (record) => {
                            let total = 0;
                            record.quotationItems?.map((i) => {
                                const productPrice = i.price * i.amount;
                                total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                            });
                            total += record.internationalShippingCost - record.discountAmount 
                            return total.toFixed(2);
                        },
					},
					// {
					// 	label: "Piad",
					// 	value: (record) => {
                    //         return record.paidAmount.toFixed(2);
                    //     },
					// },
					// {
					// 	label: "Due",
					// 	value: (record) => {
                    //         let total = 0;
                    //         record.quotationItems?.map((i) => {
                    //             const productPrice = i.price * i.amount;
                    //             total += productPrice + (record.insurance ? productPrice * 0.05 : 0) + i.chinaDeliveryCost;
                    //         });
                    //         total += record.internationalShippingCost - record.discountAmount - record.paidAmount;
                    //         return total.toFixed(2);
                    //     },
					// },
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
					},
					{
						label: "Status",
						key: "status",
						value: (record) => this.getStatus(record.status),
					},
				],
			};
		},
        roles() {
            return this.$store.state.usersModule.userRoles;
        },
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        }
	},
};
</script>

<style></style>
