export default {
  namespaced: true,
  state: {
    topBarRoutes: [
      {
        title: 'Dashboard',
        route: '/dashboard'
      },
    ]
  },
  mutations: {
    setTobBarRoutes(state, payload) {
      state.topBarRoutes = payload;
    },
  },
  actions: {
    updateTopBarRoutes(context, payload) {
      try {
        context.commit('setTobBarRoutes', payload);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
