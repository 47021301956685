import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
    name:'CustomerSubscription',
    state: {
        customerSubscription:{ pageMeta: {}, data: []}
    },
    mutations: {
        setCustomerSubscription(state, payload) {
            state.customerSubscription = payload
        }
    },
    actions: {
        async getCustomerSubscription(context, payload) {
            const query = payload
            const url = 'user/subscription/listAdmin'
            const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
            console.log(result)
            if (success) {
                context.commit('setCustomerSubscription', result)
            } else {
                httpErrorHandler(message)
            }
        },
        async acceptRequest(context, payload){
            const {_id} = payload
            const url = `user/subscription/${_id}/accpeted`
            console.log("hi accept")
            const {
                success,
                result,
                message
            } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
            if (success) {
                return true
            } else {
                httpErrorHandler(message)
            }
        },
        async rejectRequest(context, payload){
            const {_id} = payload
            const url = `user/subscription/${_id}/rejected`
            const {
                success,
                result,
                message
            } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
            if (success) {
                return true
            } else {
                httpErrorHandler(message)
            }
        },
    }
}