<template>
    <div class="page dashboard percentage-cut-page">
        <div class="content">
            <div class="title-bar">
                <p class="title">Setting, {{id ? 'Edit' : 'Add New'}} Home Page Product</p>
            </div>
            <div>
                <form v-on:submit.prevent="editSetting">
                    <div class="label required">Title</div>
                    <div class="input-ctn">
                        <input type="text" v-model="title" placeholder="Title" class="input">
                    </div>
                    <div class="label required">Start Price($)</div>
                    <div class="input-ctn">
                        <input type="number" min="0" step="0.01" v-model="start_price" placeholder="Start Price" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <div class="label required">End Price($)</div>
                    <div class="input-ctn">
                        <input type="number" min="0" step="0.01" v-model="end_price" placeholder="Input End Price" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <div class="label">Limit</div>
                    <div class="input-ctn">
                        <input type="number" min="0" v-model="pageSize" placeholder="Input limit" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <div class="label">Order</div>
                    <div class="input-ctn">
                        <input type="number" min="0" v-model="order" placeholder="Order" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <div class="label required">Product</div>
                    <div class="input-ctn">
                        <input type="text" v-model="search" placeholder="Product" class="input">
                    </div>
                    <button class="btn submit" :disabled="isLoading">Save</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SettingHomePageNew",
        components: {},
        data() {
            return {
                id: null,
                title: null,
                start_price: null,
                end_price: null,
                pageSize: 20,
                search: null,
                order: 20,
                isLoading: false
            }

        },
        mounted() {
            this.id = this.$route.query._id;
            this.title = this.$route.query.title;
            this.start_price = this.$route.query.start_price;
            this.end_price = this.$route.query.end_price;
            this.pageSize = this.$route.query.pageSize || 20;
            this.order = this.$route.query.order || 20;
            this.search = this.$route.query.search;
        },
        methods: {
            async editSetting() {
                this.isLoading = true

                const success = await this.$store.dispatch(this.id ? 'updateSettingDefaultHomePageProduct' : 'createSettingDefaultHomePageProduct', {
                    _id: this.id,
                    title: this.title,
                    start_price: this.start_price,
                    end_price: this.end_price,
                    pageSize: this.pageSize,
                    order: this.order,
                    search: this.search
                })
                if (success) {
                    alert("Successfully");
                    this.$router.push({ path: "/setting" });
                }
                this.isLoading = false
            }
        }
    }
</script>
<style scoped>
    form {
        width: 30rem;

    }

    .input {
        width: 100%;
        border: 1px solid #000;
        margin-bottom: 1rem;
        line-height: 3rem;
        padding: 0 1rem;
    }

    .btn {
        background-color: #000;
        color: #fff;
        line-height: 2rem;
        padding: 0 1rem;
    }

    .submit {
        width: 100%;
        background-color: #000000;
        color: #ffffff;
        line-height: 3rem;
        display: flex;
        justify-content: center;
    }
</style>