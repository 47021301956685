<template>
	<div class="page dashboard merchant-dashboard-page">
		<div class="content">
			<div
				v-if="isActionMenuOpen"
				class="action-menu-backdrop"
				v-on:click="toggleActionMenu('')"
			></div>
			<div class="title-bar">
				<p class="title">Merchant Management</p>
				<div class="group">
					<div class="icon"></div>
				</div>
			</div>
			<ListingComponent
				model="data"
				:isLoading="isLoadingMerchant"
				:data="merchants"
				:query.sync="query"
				:refresh="getMechants"
				:toggleActionMenu="toggleActionMenu"
				:selected.sync="selected"
				:listingOptions="listingOptions"
				:deleteRecord="deleteMerchant"
			/>
		</div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <EditMerchant 
        :getMerchant="getMechants" 
        :merchant="focusedMerchant" 
        v-if="isActionMenuOpen === 'read'"
      />
    </div>
	</div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";
import FormItemComponent from "../../components/FormItemComponent";

import EditMerchant from './EditMerchant'

import moment from 'moment'

export default {
	name: "MerchantsDashboard",
	components: {
		ListingLoadingComponent,
		TabBarLoadingComponent,
		ListingComponent,
    TabBarComponent,

    EditMerchant,
	},
	data() {
		return {
			selected: [],
			isActionMenuOpen: "",
			focusedMerchant: {},
			q: "",
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
				sortBy: "",
				role: 0,
			},
		};
	},
	created() {
		this.getMechants();
	},
	methods: {
		async getMechants() {
			const response = await this.$store.dispatch(
				"merchantModule/getMerchants",
				{
					query: {},
				}
			);
		},
		async deleteMerchant(merchant) {
      const confirmed = confirm('Are you sure you want to delete merchant?')
      if(confirmed) {
        const { _id } = merchant
        const deleted = await this.$store.dispatch('merchantModule/deleteMerchant', {_id})
        if(deleted) {
          this.getMechants()
        }
      }
    },
		toggleActionMenu(menu, payload = {}) {
			if (menu !== "") {
				if (menu === "edit" || menu === "read") {
					this.focusedMerchant = payload;
				}
			}
			this.isActionMenuOpen = menu;
		},
	},
	computed: {
		listingOptions() {
			return {
				hasSelect: true,
				actions: ["edit", "delete"],
				queryOptions: {
					sortBy: [
						{
							label: "Name",
							value: "name",
            }
					],
					sortDirection: [
						{
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
					],
				},
				schema: [
					{
						label: "Name",
						key: "name",
					},
					{
						label: "Slogan",
						key: "slogan",
					},
					{
						label: "Owner",
						key: "owner",
						value: (record) => {
							return record.owner.name;
						},
					},
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => {
							return moment(record.createdAt).format('YYYY-MM-DD HH:mm')
						}
					},
				],
			};
		},
		merchants() {
			console.log(this.$store.state.merchantModule.merchants);
			return this.$store.state.merchantModule.merchants;
		},
		isLoadingMerchant() {
      console.log(this.$store.state.merchantModule.isLoadingMerchant)
			return this.$store.state.merchantModule.isLoadingMerchant;
		},
	},
};
</script>

<style></style>
