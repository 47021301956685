import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    voucherHistory: {
      pageMeta: null, data: []
    }
  }, 
  mutations: {
    setVoucherHistory(state, payload) {
      state.voucherHistory = payload
    }
  },
  actions: {
    async getVoucherHistory(context, payload) {
      const query = payload
      const url = 'claimVoucherHistory'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) {
          context.commit('setVoucherHistory', result)
      } else {
          httpErrorHandler(message)
      }
    },

    async updateStatus(context, payload) {
      const { id, status } = payload
      const url = `topupRequest/${id}/status`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { status })
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, {root:true})
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
  }
}