<template>
  <div class="modal">
    <div class="modal-content new-category-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <p>New Category</p>
      <form v-on:submit.prevent="handleSubmit">
        <div class="input-ctn">
          <input 
            required
            type="text" 
            placeholder="English" 
            class="input"
            v-model="en"
          >
        </div>
        <div class="input-ctn">
          <input 
            required
            type="text" 
            placeholder="ភាសាខ្មែរ" 
            class="input"
            v-model="km"
          >
        </div>
        <div class="input-ctn">
          <input 
            required
            type="text" 
            placeholder="中文" 
            class="input"
            v-model="cn"
          >
        </div>
        <button type="submit" class="btn submit">Submit</button>
      </form>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    onSubmit: {
      type: Function,
      default: ()=>{}
    },
    closeModal: {}
  },
  data() {
    return {
      en: "",
      cn: "",
      km: "",
    }
  },
  methods: {
    handleSubmit() {
      let {cn, en, km } = this
      this.onSubmit({cn, en, km})
      this.closeModal()
    }
  }
}
</script>

<style>

</style>