<template>
  <div class="modal">
    <div class="modal-content select-category-modal">
      <p class="btn" v-on:click="handleSubmit(null)">x</p>
      <form @submit.prevent>
        <input type="text" id="selectVipCustomerModelSearchInput" v-model="search" class="input" placeholder="Search VIP Customer">
      </form>
      <div class="category-list">
          <div>
              
          </div>
        <div 
          v-for="item in customers.data" 
          :key="item._id"
          v-on:click="handleSubmit(item)"
          class="category btn"
        >
          <p>{{item.name + ' - ' + item.phoneNumber + " ("+item.code+")"}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit'],
  data() {
    return {
        searchTimeout: null,
        search: ''
    }
  },
  watch: {
    search: function() {
        this.searchTimeout && clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(
            () => this.getCustomers(), 500
        )
    },
  },
  mounted() {
      this.getCustomers();
  },
  methods: {
    handleSubmit(customer) {
        this.onSubmit(customer)
        this.closeModal()
    },
    getCustomers: function() {
        this.$store.dispatch("vipCustomerModule/get", {
            query: {
                search: this.search,
            }
        });
    }
  },
  computed: {
    customers() {
        return this.$store.state.vipCustomerModule.customer;
    }
  }
}
</script>

<style>

</style>