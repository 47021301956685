<template>
    <div class="page dashboard transactions-dashboard">
        <div class="content">
            <div class="title-bar">
                <p class="title">Balance Transaction management</p>
            </div>
            <TabBarComponent :isLoading="false" :query.sync="query" :data="method" queryKey="method" :label="r=>r.label"
                :key="false" :hasAll="false" />
            <input type="date" class="search" style="width: 20%" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; width: 20%" v-model="query.toDate">
            <input
                v-model="buyerLabel"
                type="text"
                class="search"
                style="margin-left: 10px; width: 20%"
                placeholder="Customer"
                @focus="openBuyerModal"
            />
            <input
                v-model="backendUserLabel"
                type="text"
                class="search"
                style="margin-left: 10px; width: 20%; margin-bottom: 10px"
                placeholder="Create By"
                @focus="openBackendUserModal"
            />
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
            <p class="title" v-if="query.method != 0">Total Add: {{parseFloat(balanceTransactions.totalAdd).toFixed(2)}}</p>
            <p class="title" v-if="query.method != 1">Total Subtract: {{parseFloat(balanceTransactions.totalSubtract).toFixed(2)}}</p>
            <p class="title" v-if="query.fromDate && query.toDate">Starting Balance: {{blanceHistory.startBalance == "N/A" ? "N/A" : parseFloat(blanceHistory.startBalance).toFixed(2)}}</p>
            <p class="title" v-if="query.fromDate && query.toDate">Ending Balance: {{blanceHistory.endBalance == "N/A" ? "N/A" : parseFloat(blanceHistory.endBalance).toFixed(2)}}</p>
            
            <br v-if="buyer">
            <p class="title" v-if="buyer">Buyer Balance: {{ parseFloat(balanceTransactions.buyerBalance).toFixed(2) }}</p>
            
            <ListingComponent model="data" :isLoading="isLoadingBalanceTransaction" :data="balanceTransactions" :query.sync="query"
                :refresh="getBalanceTransactions" :toggleActionMenu="handleTransactionClick" :listingOptions="listingOptions" :deleteRecord="deleteRecord"/>
        </div>
        <SelectUserModal
            :closeModal="closeBuyerModal"
            v-show="buyerModalOpened"
            :onSubmit="selectedBuyer"
            :roles="[buyerId]"
        />
        <SelectUserModal
            :closeModal="closeBackendUserModal"
            v-show="backendUserModalOpened"
            :onSubmit="selectedBackendUser"
            :roles="backendUserIds"
        />
    </div>
</template>

<script>
    import ListingLoadingComponent from "../../components/ListingLoadingComponent";
    import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
    import ListingComponent from "../../components/ListingComponent";
    import TabBarComponent from "../../components/TabBarComponent";
    import SelectUserModal from "../../components/SelectUserModal";

    import Searchbar from '../../components/Searchbar';

    import moment from "moment";

    import Vue from 'vue';
    import JsonExcel from "vue-json-excel";
    Vue.component("downloadExcel", JsonExcel);

    export default {
        name: "BalanceTransaction",
        components: {
            ListingLoadingComponent,
            TabBarLoadingComponent,
            ListingComponent,
            TabBarComponent,
            Searchbar,
            SelectUserModal,
            JsonExcel
        },
        data: function () {
            return {
                buyer: null,
                buyerLabel: null,
                buyerModalOpened: false,
                backendUser: null,
                backendUserLabel: null,
                backendUserModalOpened: false,
			    searchTimeout: null,
                search: null,
                query: {
                    order: "ASC",
                    page: 1,
                    limit: 10,
                    sortBy: "",
                    method: null,
                    fromDate: moment().format('YYYY-MM-DD'),
                    toDate: moment().format('YYYY-MM-DD'),
                },
                excelName: 'Balance Transaction-'+moment().format('YYYY-MM-DD')+'.xls'
            };
        },
        created() {
            this.getBalanceTransactions();
            this.$store.dispatch("usersModule/getUserRoles");
        },
        methods: {
            async excelData() {
                await this.$store.dispatch("balanceTransactionsModule/exportBalanceTransactions", {
                    export: true,
                    ...this.query, 
                    searchValue: this.search,
                    buyerId: this.buyer?._id,
                    createById: this.backendUser?._id,
                });

                const data = this.$store.state.balanceTransactionsModule.exportedBalanceTransactions;

                return data.map((item, index) => {
                    return {
                        '#': index + 1,
                        'Amount': item.amount * (item.method ? 1 : -1),
                        'Note': item.note,
                        'customer': item.customer ? (item.customer.name + '(' + item.customer.phoneNumber + ')') : 'N/A',
                        'Create By': item.createBy ? (item.createBy.name + '(' + item.createBy.phoneNumber + ')') : 'N/A',
                        'Date': moment(item.createdAt).format('DD/MM/YYYY H:mm')
                    };
                });
            },

            deleteRecord() {

            },
            
            openBuyerModal() {
                this.buyerModalOpened = true;
                setTimeout(() => {
                    document.getElementById("selectUserModelSearchInput").focus();
                })
            },
            closeBuyerModal() {
                this.buyerModalOpened = false;
            },
            selectedBuyer(buyer) {
                if(this.buyer == buyer) {
                    return;
                }

                if(buyer) {
                    this.buyerLabel = buyer.name + ' - ' + buyer.phoneNumber;
                } else {
                    this.buyerLabel = null;
                }
                this.buyer = buyer;
                this.getBalanceTransactions();
            },
            openBackendUserModal() {
                this.backendUserModalOpened = true;
                setTimeout(() => {
                    document.getElementById("selectUserModelSearchInput").focus();
                })
            },
            closeBackendUserModal() {
                this.backendUserModalOpened = false;
            },
            selectedBackendUser(backendUser) {
                if(this.backendUser == backendUser) {
                    return;
                }

                if(backendUser) {
                    this.backendUserLabel = backendUser.name + ' - ' + backendUser.phoneNumber;
                } else {
                    this.backendUserLabel = null;
                }
                this.backendUser = backendUser;
                this.getBalanceTransactions();
            },
            handleTransactionClick(action, payload) {
                // this.$router.push(`/balanceTransaction/${payload._id}`)
            },
            getBalanceTransactions() {
                this.$store.dispatch("balanceTransactionsModule/getBalanceTransactions", {
                    ...this.query, 
                    searchValue: this.search,
                    buyerId: this.buyer?._id,
                    createById: this.backendUser?._id
                });

                if(this.query.fromDate && this.query.toDate) {
                    this.$store.dispatch("balanceTransactionsModule/getHistory", {
                        fromDate: this.query.fromDate,
                        toDate: this.query.toDate
                    });
                }
            },
        },
        watch: {
            query: {
                deep: true,
                handler: function () {
                    if (this.query.page >= 1) {
                        this.getBalanceTransactions();
                    }
                },
            },
            search: function() {
                this.searchTimeout && clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(
                    () => this.getBalanceTransactions(), 500
                )
            }
        },
        computed: {
            balanceTransactions() {
                return this.$store.state.balanceTransactionsModule.balanceTransactions;
            },
            isLoadingBalanceTransaction() {
                return this.$store.state.balanceTransactionsModule.isLoadingBalanceTransaction;
            },
            method() {
                return  [
                    {
                        _id: null,
                        label: "All"
                    },
                    {
                        _id: 1,
                        label: "Add"
                    },
                    {
                        _id: 0,
                        label: "Subtract"
                    }
                ];
            },
            listingOptions() {
                let actions = [];

                let schema = [
                    {
                        label: "Amount",
                        key: 'Amount',
                        value: record => (record.method == 0 && record.amount != 0 ? '-' : '' ) + record.amount.toFixed(2)
                    },
                    {
                        label: "Note",
                        key: 'Note',
                        value: record => record.note
                    },
                    {
                        label: "Customer",
                        key: 'Customer',
                        value: record => record.customer ? (record.customer.name + '(' + record.customer.phoneNumber + ')') : 'N/A'
                    },
                    {
                        label: "Create By",
                        key: 'createdBy',
                        value: record => record.createBy ? (record.createBy.name + '(' + record.createBy.phoneNumber + ')') : 'N/A'
                    },
                    {
                        label: "Date",
                        key: "Date",
                        value: (record) => moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                    },
                    {
                        label: "Created At",
                        key: "createdAt",
                        value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                    },
                ];

                return {
                    hasSelected: false,
                    hasNo: true,
                    actions: actions,
                    queryOptions: {
                        sortBy: [],
                        sortDirection: [{
                                label: "Ascending",
                                value: "ASC"
                            },
                            {
                                label: "Descending",
                                value: "DESC"
                            },
                        ],
                    },
                    schema,
                };
            },
            roles() {
                return this.$store.state.usersModule.userRoles;
            },
            buyerId() {
                const buyer = this.roles.data?.filter(r => (r.name === 'buyer'))
                return buyer ? buyer[0]._id : ''
            },
            backendUserIds() {
                const users = this.roles.data?.filter(r => (r.name !== 'buyer'))
                return users?.map(user => {
                    return user._id;
                })
            },
            blanceHistory() {
                return this.$store.state.balanceTransactionsModule.blanceHistory;
            }
        },
    };
</script>

<style>
    .export-btn {
        line-height: 3rem;
        background-color: #000000;
        color: #ffffff;
        padding: 0 2rem;
        cursor: pointer;
        text-align: center;
        width: 160px;
    }
</style>