import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    banners: {}
  }, 
  mutations: {
    setBanners(state, payload) {
      state.banners = payload
    }
  },
  actions: {
    async getBanners(context, payload) {
      const url = 'banner'
      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        context.commit('setBanners', result)
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async createBanner(context, payload) {
      const url = 'banner'
      const { bgColor, image } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {bgColor, image})
      if(success) {
        context.dispatch('getBanners')
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async deleteBanner(context, payload) {
      const {_id} = payload
      const url = `banner/${_id}`
      const {success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if(success) {
        context.dispatch('getBanners')
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}