<template>
  <div class="modal">
    <div class="modal-content upload-image-modal">
      <p class="btn" @click="closeModal">x</p>
      <form v-on:submit.prevent="submit">
        <label for="image" class="preview">
          <p v-if="!filePreview">Select Image</p>
          <img v-if="filePreview" :src="filePreview" alt="">
        </label>
        <input
          @change="handleImageSelected" 
          type="file" 
          class="image-input" 
          id="image"
        >
        <div class="submit-group">
          <button type="submit" class="btn submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'handleFormSubmit'],
  data() {
    return {
      file: null,
      filePreview: ''
    }
  },
  methods: {
    submit() {
        if(this.file) {
            this.handleFormSubmit(this.file)
        } else {
            alert('Please select iamge')
        }
    },

    handleImageSelected(e) {
        const file = e.target.files[0]
        this.file = file
        this.filePreview = URL.createObjectURL(file)
    }
  }
}
</script>

<style>

</style>