<template>
  <div class="dashboard page top-up-request-page">
    <div class="content">
      <div class="title-bar">
        <p class="title">Voucher History</p>
      </div>
      <TabBarComponent
        :isLoading="false"
        :query.sync="query"
        :data="status"
        queryKey="status"
        :label="(r) => r.label"
        :key="false"
        :hasAll="false"
      />
      <input placeholder="Search for orders..." type="text" class="search" />
      <input
        type="date"
        class="search"
        style="margin-left: 10px; width: 20%; margin-top: 10px"
        v-model="query.fromDate"
      />
      <input
        type="date"
        class="search"
        style="margin-left: 10px; margin-bottom: 10px; width: 20%"
        v-model="query.toDate"
      />

      <ListingComponent
        model="data"
        :data="requests"
        :isLoading="isLoading"
        :query.sync="query"
        :refresh="getItems"
        :toggleActionMenu="toggleActionMenu"
        :listingOptions="listingOptions"
      />
    </div>
    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <TopUpRequestDetails
        v-if="isActionMenuOpen === 'read'"
        :request="focusedRequest"
        :toggleActionMenu="toggleActionMenu"
        :getItems="getItems"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TabBarComponent from "../../components/TabBarComponent";
import ListingComponent from "../../components/ListingComponent";
import { API_URL } from "../../const";
export default {
  name: "VoucherDashboard",
  components: {
    ListingComponent,
    TabBarComponent,
  },
  data() {
    return {
      isLoading: false,
      reject: "reject",
      accept: "accept",
      hasImage: true,
      enableAcceptButton: true,
      enableRejectButton: true,
      isActionMenuOpen: "",
	  status: 0,
      url: "http://127.0.0.1:3000",
      query: {
        order: "DESC",
        page: 1,
        limit: 10,
        sortBy: "createdAt",
        fromDate: null,
        toDate: null,
      },
    };
  },

  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("getVoucherHistory", this.query);
    },

    toggleActionMenu(menu, payload) {
      if (menu !== "") {
        if (payload) {
          this.focusedRequest = payload;
        }
      }
      this.isActionMenuOpen = menu;
    },
    // getStatus(status) {
    //   switch (status) {
    //     case 0:
    //       return "Pending";
    //     case 1:
    //       return "Confirmed";
    //     case 2:
    //       return "Rejected";
    //   }
    // },
  },
  computed: {
    listingOptions() {
      return {
        hasSelect: false,
        actions: [],
        queryOptions: {
          sortBy: [{ label: "createdAt", value: "createdAt" }],
          sortDirection: [
            {
              label: "Ascending",
              value: "ASC",
            },
            {
              label: "Descending",
              value: "DESC",
            },
          ],
        },
        schema: [
          {
            label: "ID",
            key: "_id",
          },
          {
            label: "Username",
            key: "user",
            value: (r) => r.user?.name,
          },
          {
            label: "Code",
            key: "voucher",
            value: (r) => r.voucher?.code,
          },
          {
            label: "Top Up Balance",
            key: "top_up_balance",
            value: (r) => r.top_up_balance?.balance,
          },
          {
            label: "Voucher Balance",
            key: "voucher",
            value: (r) => r.voucher?.balance,
          },
          {
            label: "Status",
            key: "status",
            value: (r) => this.getStatus(r.status),
          },
          {
            label: "Create At",
            key: "createdAt",
            value: (r) => moment(r.createdAt).format("YYYY-MM-DD HH:mm"),
          },
        ],
      };
    },
    requests() {
      return this.$store.state.topupModule.requestList;
    },
    API_URL() {
      return API_URL;
    },
  },
  watch: {
    query: {
      deep: true,
      handler: function () {
        if (this.query.page >= 1) {
          this.getRequest();
        }
      },
    },
  },
};
</script>

<style></style>
