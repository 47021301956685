<template>
  <div class="page dashboard order-details">
    <div class="content">
      <div class="title-bar" style="justify-content: normal;">
        <p class="title">VIP Quotation Details ({{ quotation.quotationId }})</p>
        <div class="icon" style="
            background-image: url(/icons/copy-text.png); 
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem; 
            height: 2rem;"
            @click="copy(quotation.quotationId, 'ID ')"
        >
        </div>  
        <div class="icon" style="
            background-image: url(/icons/printer.png); 
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem; 
            height: 2rem;"
            @click="printQuotation()"
        >
        </div>
        <div class="icon" style="
            background-image: url(/icons/duplicate.png); 
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem; 
            height: 2rem;"
            v-if="['admin', 'financer'].includes(currentUser.role.name)"
            @click="duplicateQuotation()"
        >
        </div>
        <div class="icon" style="
            background-image: url(/icons/edit.png); 
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem; 
            height: 2rem;"
            v-if="['admin', 'financer'].includes(currentUser.role.name)"
            @click="editQuotation()"
        >
        </div>
      </div>
      <router-link to="/quotations">Back</router-link>
      <p v-if="isLoading">Fetching Data . . .</p>
      <div class="page-content" v-if="!isLoading">
        <div class="order-info">
            <div class="user-info">
                <p class="bold">
                UserName:
                {{ quotation.customer ? quotation.customer.name + " - " + quotation.customer.phoneNumber + "("+quotation.customer.code+")" : "(Deleted Account)" }}
                </p>
                <p>
                Address: 
                {{ quotation.customer ? quotation.customer.address : null }}
                </p>
            </div>
            <div class="user-info">
                <p class="bold">
                Sale Person:
                {{ quotation.salePerson ? quotation.salePerson.name + " - " + (quotation.salePerson.phoneNumber || 'N/A') + "("+quotation.salePerson.code+")" : "(Deleted Account)" }}
                </p>
            </div>
            <div class="shipping-methods">
                <p class="bold">Shipping Setting</p>
                <p>Shipping Method: {{ quotation.shippingMethod.name }}</p>
                <p>
                Delivery Method:
                {{
                quotation.transportMethod === 0 ? "Self-pickup" : "Nokor delivery"
                }}
                </p>
            </div>
            <div class="payment-confirmation">
                <p class="bold">Payment</p>
                <p>
                Other Cost: $
                {{ quotation.internationalShippingCost }}
                </p>
                <p >Total China Fee: $ {{ (total_china_fee).toFixed(2) }}</p>
                <p>Total Product Price: $ {{ (total_product_price).toFixed(2) }}</p>
                <p v-if="quotation.insurance">Insurance: {{ insuranceLabel }} = $ {{ (insurance).toFixed(2) }}</p>
                <p v-if="quotation.discountAmount" class="bold">Discount: $ {{ (quotation.discountAmount).toFixed(2) }}</p>
                <br>
                <p class="bold big">Total: $ {{ (total).toFixed(2) }}</p>
                <!-- <p class="bold big">Paid: $ {{ quotation.paidAmount ? quotation.paidAmount.toFixed(2) : 0}}</p>
                <p class="bold big">Due: $ {{ (total - (quotation.paidAmount || 0)).toFixed(2) }}</p> -->
                <br>
                <!-- <button
                v-on:click="openUpdateCostModal"
                class="btn pay"
                v-if="quotation.status < 5"
                >Update Cost</button>
                <button
                v-on:click="openUpdatePaidModal"
                class="btn pay"
                style="margin-left: 5px"
                v-if="quotation.status < 5"
                >Update Payment</button> -->
            </div>
            <!-- <div class="payment-confirmation">
                <p class="bold text-danger">
                    <span style="float: left;"> Payment Images</span>                            
                </p>
                <br>
                <img
                    v-for="(image, index) in quotation.paymentImages" :key="index"
                    class="order-image"
                    :src="`${API_URL}/${image.image}`"
                    data-lity 
                    :data-lity-target="`${API_URL}/${image.image}`"
                    width="50px"
                    style="cursor: pointer; margin-right: 5px"
                    alt=""
                />
                <br>
                <button
                @click="openUploadPaymentImageModel"
                class="btn pay"
                v-if="quotation.status < 5"
                >Upload Image</button>
            </div> -->
        </div>
        <div class="items-list">
          <!-- <div class="status">
            <p class="bold">Quotation Status:</p>
            <p v-if="updatingStatus">Updating Status . . .</p>
            <div class="status-list" v-if="quotation.status === 9">
              <p>Rejected</p>
            </div>
            <div class="status-list" v-if="!updatingStatus && quotation.status !== 9">
              <p v-on:click="updateQuotationStatus(0)" :class="`btn active`">Pending</p>
              <p
                :class="`btn ${quotation.status > 0 ? 'active' : 'not-allow'}`"
              >Ordering</p>
              <p
                :class="`btn ${quotation.status > 1 ? 'active' : 'not-allow'}`"
              >In China</p>
              <p
                :class="`btn ${quotation.status > 2 ? 'active' : 'not-allow'}`"
              >Shipping</p>
              <p
                :class="`btn ${quotation.status > 3 ? 'active' : 'not-allow'}`"
              >In Cambodia</p>
              <p
                v-on:click="updateQuotationStatus(5)"
                :class="`btn ${quotation.status > 4 ? 'active' : ''}`"
              >Wait For Payment</p>
              <p
                v-on:click="updateQuotationStatus(6)"
                :class="`btn ${quotation.status > 5 ? 'active' : ''}`"
              >Paid</p>
              <p
                v-on:click="updateQuotationStatus(7)"
                :class="`btn ${quotation.status > 6 ? 'active' : ''}`"
              >Delivering</p>
              <p
                v-on:click="updateQuotationStatus(8)"
                :class="`btn ${quotation.status > 7 ? 'active' : ''}`"
              >Completed</p>
            </div>
          </div> -->
            <div class="submit-order" v-if="quotation.deletedAt || quotation.status !== 0">
                <p v-if="quotation.deletedAt" class="rejected" style="margin-right: 5px">Deleted</p>
                <p v-else-if="quotation.status == 1" class="approved" style="margin-right: 5px">Confrimed</p>
                <p v-else-if="quotation.status == 2" class="rejected" style="margin-right: 5px">Rejected</p>
                <p v-else class="rejected">Canceled</p>
            </div>
            <p class="bold big">
              Items 
            </p>
                
          <div class="product-list">
            <div class="product-item" :style="product.itemId == itemId ? 'border: 1px solid red;' : null" v-for="(product, index) in items" :key="index">
              <div class="text_num" style="background: #333; color: #fff; padding: .1rem .4rem; border-radius: .2rem;">
                <ul>
                  <li>{{ index + 1 }}</li>
                </ul>
              </div>
              <img :src="product.image && (product.image.includes('http') || product.image.includes('data:image')) ? product.image : `${API_URL}/${product.image}`" 
                    data-lity 
                    :data-lity-target="product.image && (product.image.includes('http') || product.image.includes('data:image'))  ? product.image : `${API_URL}/${product.image}`"
              alt class="image" />
              <div class="product-info">
                  <div style="width:60%; display:inline-block;">
                    <p class="big bold">
                        {{ product.variantNote }}
                    </p>
                    <p class="small bold">
                        Unit Price: {{ product.price }} (x{{product.amount}}) = $ {{ parseFloat(product.price * product.amount).toFixed(2) }}
                    </p>
                    <p class="small bold">China Fee: $ {{ product.chinaDeliveryCost }}</p>
                    <p class="bold">Total: $ {{ parseFloat(product.price * product.amount + product.chinaDeliveryCost).toFixed(2) }}</p>
                    <a target="_blank" v-if="product.checkProductLink && product.checkProductLink.includes('http')" :href="product.checkProductLink" class="link bold">Check Product</a>
                  </div>
                  <div class="btn-group" style="width:40%; display:inline-block;vertical-align: top;">
                    <textarea v-model="product.note" rows="3" style="width: 170px; margin-top: 5px; border: 1px solid #6c757d;border-radius: 5px; heigth: 60px; padding: .3rem">

                    </textarea>
                    <p
                    v-on:click="noteQuotationItem(product.itemId, product.note)"
                    v-if="['admin', 'financer', 'order'].includes(currentUser.role.name)"
                    class="btn info" style="width: 100px;"
                    >Update Note</p>

                  </div>
              </div>
                <div class="btn-group" v-if="quotation.status === 0 && !quotation.deletedAt">
                    <template v-if="product.status === 0">
                        <p class="btn reject" style="padding-left: 10px; padding-right: 10px;" v-on:click="openRejectProduct(product)">Reject</p>
                    </template>
                    <p v-if="product.status === 2" class="rejected">Rejected</p>
                </div>
              <!-- <div class="btn-group"> -->
                <!-- <div>
                  <input
                  type="checkbox"
                  v-model="product.isArrived"
                  @click="updateQuotationArrival(product.itemId)"
                  class="checkbox"
                />
                <span>
                    {{product.isArrived? 'Arrive': "Not Arrived"}}
                </span>
                </div> -->
                
                <!-- <label v-if="product.orderingAt">Quotationing At: {{product.orderingAt}}</label>
                <br v-if="product.orderingAt">
                <label v-if="product.inChinaAt">In China At: {{product.inChinaAt}}</label>
                <br v-if="product.inChinaAt">
                <label v-if="product.shippingAt">Shipping At: {{product.shippingAt}}</label>
                <br v-if="product.shippingAt">
                <label v-if="product.inCambodiaAt">In Cambodia At: {{product.inCambodiaAt}}</label>
                <br v-if="product.inCambodiaAt">
                <label v-if="product.trackingId && (quotation.status > 3 || product.status > quotation.status)">Tracking ID: {{product.trackingId}}</label>
                <br v-if="product.trackingId && (quotation.status > 3 || product.status > quotation.status)">
                <label v-if="product.chinaMoney && product.status > 0">China Money: {{product.chinaMoney}}</label>
                <br v-if="product.chinaMoney && product.status > 0">
                <input v-if="quotation.status < 4 && product.status < 4" placeholder="Tracking ID" type="text" style="border: 1px solid #6c757d;border-radius: 5px; width: 200px" v-model="product.trackingId" />
                <br v-if="quotation.status < 4 && product.status < 4">
                <input v-if="quotation.status == 0 && quotation.status >= product.status" placeholder="China Money" type="number" setp="0.01" style="border: 1px solid #6c757d;border-radius: 5px; width: 200px" v-model="product.chinaMoney" />
                <br v-if="quotation.status == 0 && quotation.status >= product.status">
                <input v-if="quotation.status < 4 && product.status < 4" type="datetime-local" style="border: 1px solid #6c757d;border-radius: 5px; width: 200px" v-model="product.nextStatusDate">
                <p
                v-if="quotation.status < 4 && product.status < 4"
                v-on:click="nextStatusQuotationItem(product.itemId, product.trackingId, product.nextStatusDate, product.chinaMoney)"
                class="btn info" style="margin-top: 5px; width: 90px"
                >{{ getNextStatus(product.status) }}</p>
              </div> -->
            </div>
            <div class="btn-group submit-order" v-if="!quotation.deletedAt && quotation.status === 0">
                <p @click="openUpdateStatus(1)" class="btn approve" style="margin-right: 5px">Confrimed</p>
                <p @click="openUpdateStatus(2)" class="btn reject" style="margin-right: 5px">Rejected</p>
                <p @click="openUpdateStatus(3)" class="btn reject">Canceled</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <UpdateCostModal
      v-if="updateCostModalOpened"
      :closeModal="closeUpdateCostModal"
      :onSubmit="updateExtraPrice"
      :chineseShippingCost="quotation.chineseShippingCost"
      :internationalShippingCost="quotation.internationalShippingCost"
    />
    <UpdatePaidModal
      v-if="updatePaidModalOpened"
      :closeModal="closeUpdatePaidModal"
      :onSubmit="updatePaidAmount"
      :paidAmount="quotation.paidAmount ? quotation.paidAmount : 0"
    />
    <UploadFileModal
        v-if="uploadPaymentImageModelOpened" 
        :closeModal="closeUploadPaymentImageModel"
        :handleFormSubmit="uploadPaymentImageModelSubmit"
    /> -->
    <ConfirmModal :onSubmit="rejectProduct" :closeModal="closeRejectModal" v-if="rejectModalOpened"/>
    <ConfirmModal :onSubmit="updateStatus" :closeModal="closeUpdateStatus" v-if="focusedUpdateStatus != -1"/>
  </div>
</template>

<script>
// import UpdateCostModal from './UpdateCostModal'
// import UpdatePaidModal from './UpdatePaidModal'
require('lity');
import { API_URL } from "../../const";
// import { FileUpload } from "../../handler";
// import UploadFileModal from '../../components/UploadFileModal'
import ConfirmModal from '../../components/ConfirmModal'
import moment from "moment";
export default {
    components: {
        // FileUpload,
        // UploadFileModal,
        // UpdateCostModal,
        // UpdatePaidModal,
        ConfirmModal
    },
	data() {
		return {
			isLoading: true,
            updatingStatus: false,
            API_URL:API_URL,
            updateCostModalOpened: false,
            updatePaidModalOpened: false,
            itemId: this.$route.query.itemId,
            uploadPaymentImageModelOpened: false,
            rejectModalOpened: false,
            focusedRejectProduct: {},
            focusedUpdateStatus: -1
		};
	},
	async created() {
		await this.getQuotationDetails();
        if(this.itemId) {
            do{
                this.quotation && setTimeout(() => this.arrivedCambodia(this.itemId), 1000);
            } while(!this.quotation);
        }
	},
	methods: {
        openUpdateStatus(status) {
            this.focusedUpdateStatus = status
        },
        closeUpdateStatus() {
            this.focusedUpdateStatus = -1;
        },
        async updateStatus() {
            if(this.focusedUpdateStatus == 1) {
                this.$router.push({ path: `/invoices/new?quotation_id=${this.quotation._id}` });
            } else {
                await this.$store.dispatch("quotationModule/updateStatus", {
                    status: this.focusedUpdateStatus,
                    _id: this.quotation._id
                });
            }
        },
        openRejectProduct(product) {
            this.focusedRejectProduct = product;
            this.rejectModalOpened = true
        },
        closeRejectModal() {
            this.rejectModalOpened = false
        },
        async rejectProduct() {
            await this.$store.dispatch("quotationModule/rejectProduct", {
                itemId: this.focusedRejectProduct.itemId,
                _id: this.quotation._id
            });
        },
        duplicateQuotation() {
            this.$router.push({ path: `/quotations/new?duplicate_id=${this.quotation._id}` });
            // window.open(`/quotations/${this.quotation._id}/print`);
        },
        printQuotation() {
            this.$router.push({ path: `/quotations/${this.quotation._id}/print` });
            // window.open(`/quotations/${this.quotation._id}/print`);
        },
        editQuotation() {
            this.$router.push({ path: `/quotations/${this.quotation._id}/edit` });
            // window.open(`/quotations/${this.quotation._id}/print`);
        },
        async uploadPaymentImageModelSubmit(file) {
            if(file) {
                this.uploadPaymentImageModelOpened = false;
                const fileObj = await FileUpload.uploadFile(file);
                const success = await this.$store.dispatch("quotationModule/uploadPaymentImage", {
                    image: fileObj.path,
                    id: this.quotation._id
                });
                if(success) {
                    alert('Upload payment image successfully');
                } else {
                    alert('Error something went wrong.');
                }
            }
        },
        openUploadPaymentImageModel() {
            this.uploadPaymentImageModelOpened = true;
        },
        closeUploadPaymentImageModel() {
            this.uploadPaymentImageModelOpened = false;
        },
        async arrivedCambodia(itemId) {
            const item = this.items.find(item => item.itemId == itemId);

            if(item && item.status == 3) {
			    const confirmed = confirm('Are you sure this item arrived in cambodia?')
                
                if (confirmed) {
                    this.$store.dispatch('quotationModule/nextStatusQuotationItem', {
                        quotationId: this.quotationId, 
                        itemId,
                        date: moment().format('YYYY-MM-DD h:mm A')
                    });
                }
            }
        },
		async payQuotation() {
			const confirmed = confirm(
				"Are you sure, you want to update payment for this quotation?"
			);
			if (confirmed) {
				const done = await this.$store.dispatch("quotationModule/payQuotation", {
					_id: this.quotationId,
                    piad: this.quotation.paid
				});
				if (done) {
					this.getQuotationDetails();
				}
			}
        },
        closeUpdateCostModal() {
            this.updateCostModalOpened = false
        },
        openUpdateCostModal() {
            this.updateCostModalOpened = true
        },
		async updateExtraPrice(payload) {
            const done = await this.$store.dispatch("quotationModule/updateCost", {
                _id: this.quotationId,
                ...payload
            });
            if (done) {
                this.getQuotationDetails()
                this.closeUpdateCostModal()
                alert("Updated")
            }
		},
        closeUpdatePaidModal() {
            this.updatePaidModalOpened = false
        },
        openUpdatePaidModal() {
            this.updatePaidModalOpened = true
        },
		async updatePaidAmount(payload) {
            const done = await this.$store.dispatch("quotationModule/updatePaidAmount", {
                _id: this.quotationId,
                ...payload
            });
            if (done) {
                this.getQuotationDetails()
                this.closeUpdatePaidModal()
                alert("Updated")
            }
		},
        // getNextStatus(status) {
        //     switch(status) {
        //         case 0:
        //             return 'Ordering';
        //         case 1:
        //             return 'In China';
        //         case 2:
        //             return 'Shipping';
        //         case 3:
        //             return 'In Cambodia';
        //     }
        //     return null;
        // },

        copy(text, label) {
            if (window.clipboardData && window.clipboardData.setData) {
                alert('Copy ' + label + 'successfully');
                // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
                return window.clipboardData.setData("Text", text);
            } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                var textarea = document.createElement("textarea");
                textarea.textContent = text;
                textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    alert('Copy ' + label + 'successfully');
                    return document.execCommand("copy"); // Security exception may be thrown by some browsers.
                } catch (ex) {
                    alert('Copy failed');
                    console.warn("Copy to clipboard failed.", ex);
                    return false;
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        },

        async updateQuotationArrival(itemId) {
            const confirmed = confirm("Are you sure this quotation has arrived?");
            if(confirmed) {
                this.$store.dispatch('quotationModule/quotationArrived', {_id: this.quotationId, itemId})
            }
        },

		async getQuotationDetails() {
			await this.$store.dispatch("quotationModule/detail", {
				_id: this.quotationId,
			});
			this.isLoading = false;
		},

		async updateQuotationStatus(status) {
			const confirmed = confirm("Are you sure you want to updated status?");
			if (confirmed) {
				this.updatingStatus = true;
				await this.$store.dispatch("quotationModule/updateStatus", {
					_id: this.quotation._id,
					status,
				});
				this.updatingStatus = false;
			}
		},

		async noteQuotationItem(itemId, note) {
			const confirmed = confirm('Are you sure you want to note this item?')
			if (confirmed) {
				this.$store.dispatch('quotationModule/noteQuotationItem', {quotationId: this.quotationId, itemId, note})
			}
		},

		async nextStatusQuotationItem(itemId, trackingId, date, chinaMoney) {
			const confirmed = confirm('Are you sure you want to update status this item?')
			if (confirmed) {
                date = date ? moment(date) : moment();
				this.$store.dispatch('quotationModule/nextStatusQuotationItem', {
                    quotationId: this.quotationId, 
                    itemId,
                    date: date.format('YYYY-MM-DD h:mm A'),
                    trackingId,
                    chinaMoney: chinaMoney || 0
                })
			}
		},
	},
	computed: {
		quotationId() {
			return this.$route.params.id;
		},
		items() {
			return this.$store.state.quotationModule.quotationDetails ? this.$store.state.quotationModule.quotationDetails.quotationItems : [];
		},
		quotation() {
			return this.$store.state.quotationModule.quotationDetails;
		},
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        },
        total_product_price() {
            return _.sumBy(this.items, (o) => {
                return o.price * o.amount;
            })
        },
        total_china_fee() {
            return _.sumBy(this.items, (o) => {
                return o.chinaDeliveryCost;
            })
        },
        insurance() {
            return this.quotation.insurance ? this.total_product_price * 0.05 : 0;
        },
        insuranceLabel() {
            return this.total_product_price.toFixed(2) + ' x 5%';
        },
        total() {
            return this.total_product_price + this.insurance + this.total_china_fee + this.quotation.internationalShippingCost - this.quotation.discountAmount;
        },
	},
};
</script>

<style src="lity/dist/lity.min.css"></style>
<style scoped>
    .not-allow {
        cursor: not-allowed;
    }
</style>
