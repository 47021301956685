import { HttpRequest, httpErrorHandler } from "../../handler"

const SET_CATEGORIES = 'setCategories'
const SET_LOADING_PRODUCTS_CATEGORY = 'setLoadingCategory'
const SET_CATEGORIES_GROUP = 'setCategoryGroup'

export default {
  namespaced: true,
  state: {
    isLoadingCategory: false,
    categoryGroups: {
      pageMeta: null, data: []
    },
    categories: {
      pageMeta: null, data: []
    }
  },
  mutations: {
    [SET_CATEGORIES](state, payload) {
      state.categories = payload
    },
    [SET_LOADING_PRODUCTS_CATEGORY](state, payload) {
      state.isLoadingCategory = payload
    },
    [SET_CATEGORIES_GROUP](state, payload) {
      state.categoryGroups = payload
    }
  },
  actions: {
    async getProductCategoryGroups(context, payload) {
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, true)
      const url = 'localProductCategoryGroup'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {})
      if (success) {
        context.commit(SET_CATEGORIES_GROUP, result)
      } else {
        httpErrorHandler(message)
      }
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, false)
    },
    async addProductCategoryGroup(context, {cn, km, en}) {
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, true)
      const url = 'localProductCategoryGroup'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {cn, en, km}, {})
      if (success) {
        context.commit(SET_CATEGORIES_GROUP, result)
      } else {
        httpErrorHandler(message)
      }
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, true)
    },
    async getProductCategoryGroupById(context, {id}) {
      const url = `localProductCategoryGroup/${id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "GET", {}, {})
      if (success) {
        return result
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async getProductCategories(context, payload) {
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, true)
      const url = 'localProductCategory'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {})
      if (success) {
        context.commit(SET_CATEGORIES, result)
      } else {
        httpErrorHandler(message)
      }
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, false)
    },
    async addProductCategory(context, { id, cn, en, km }) {
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, true)
      const url = `localProductCategoryGroup/${id}/modify`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', { cn, en, km }, {})
      context.commit(SET_LOADING_PRODUCTS_CATEGORY, false)
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async editProductCategories(context, payload) {
      const { cn, en, km, _id } = payload
      const url = `localProductCategoryGroup/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", { cn, en, km }, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async deleteProductCategoryGroup(context, payload) {
      const { _id } = payload
      const url = `localProductCategoryGroup/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async deleteProductCategories(context, payload) {
      const { _id, category } = payload
      const url = `localProductCategoryGroup/${_id}/modify/${category}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}