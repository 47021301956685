import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    transactions: {pageMeta: {}, data:[]},
    transactionDetails: {},
    isLoadingTransaction: false,
  },
  mutations: {
    setTransactionsData(state, payload) {
      state.transactions.data = payload
    },
    setTransactions(state, payload) {
      state.transactions = payload
    },
    setIsLoadingTransaction(state, payload) {
      state.isLoadingTransaction = payload
    },
    setTransactionDetails(state, payload) {
      state.transactionDetails = payload
    }
  },
  actions: {
    async getTransactions(context, payload) {
      context.commit('setIsLoadingTransaction', true)
      const query = payload
      const url = 'transaction'
      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET',{}, query, {})
      console.log(result)
      if(success) {
        context.commit('setTransactions', result)
      } else {
        httpErrorHandler(message) 
      }
      context.commit('setIsLoadingTransaction', false)
    },
    async getTransactionDetails(context, payload) {
      const {_id} = payload
      const url = `transaction/${_id}`
      const {success, result, message} = await HttpRequest.authorizedRequest(url, "GET", {}, {}, {})
      if(success) {
        context.commit('setTransactionDetails', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async confirmPayment(context, payload) {
      const {
        _id
      } = payload
      const url = `transaction/${_id}/confirmPayment`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async delete(context, payload) {
      const {
        _id
      } = payload
      const url = `transaction/${_id}`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if (success) {
        context.commit('setTransactionsData', context.state.transactions.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async recover(context, payload) {
      const {
        _id
      } = payload
      const url = `transaction/${_id}/recover`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
      if (success) {
        context.commit('setTransactionsData', context.state.transactions.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    }
  }
}