<template>
  <div class="edit-merchant">
    <p class="title">Edit Merchant</p>
    <form v-on:submit="handleFormSubmit">
      <label for="merchant-img" class="image-preview btn">
        <p v-if='!logo'>Select an image</p>
        <img v-if='logo' :src="`${API_URL}/${logo}`" alt="">
      </label>
      <input id="merchant-img" class="file-input" type="file" @change="handleImageSelected">
      <div class="input-ctn">
        <div class="label">Name</div>
        <input 
          v-model="name"
          type="text" 
          class="input"
          placeholder="Name"
        >
      </div>
      <div class="input-ctn">
        <div class="label">Slogan</div>
        <input 
          v-model="slogan"
          type="text" 
          class="input"
          placeholder="Slogan"
        >
      </div>
      <button v-if="!loading" type="submit" class="submit btn">
        Save
      </button>
      <p v-if="loading" class="btn">Updating . . .</p>
      <router-link :to="`/merchants/${merchant._id}`" class='btn action-button'>View Dashboard</router-link>
    </form>

  </div>
</template>

<script>
import { FileUpload } from '../../handler';
import { API_URL } from "../../const";
export default {
  name: 'EditMerchant',
  props: ['merchant', 'getMerchant'],
  data(){
    return {
      loading: false,
      name: this.merchant.name,
      slogan: this.merchant.slogan,
      logo: this.merchant.logo ? this.merchant.logo : {},
      newFile: null,
    }
  },
  methods: {
    async handleFormSubmit(e){
      e.preventDefault();
      this.loading = true
      const created = await this.$store.dispatch('merchantModule/editMerchant', {
        merchantId: this.merchant._id,
        name: this.name, 
        slogan: this.slogan,
        logo: this.logo
      })
      this.loading = false
      created && alert('Merchant Updated')
      this.getMerchant()
    },
    async handleImageSelected(e) {
      const image = e.target.files[0]
      this.loading = true
      this.imageSrc = URL.createObjectURL(image)
      const file = await FileUpload.uploadFile(image)
      this.loading = false
      this.logo=file.path
    }
  },
  computed: {
    API_URL() {
      return API_URL;
    }
  }
}
</script>

<style>

</style>