import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    roles: {}
  }, 
  mutations: {
    setRoles(state, payload) {
      state.roles = payload
    }
  },
  actions: {
    async getRoles(context, payload) {
      const url = 'userRole'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) { 
        context.commit('setRoles', result)
      } else {
        httpErrorHandler(message)
      }
    }
  }
}