<template>
	<div class="page dashboard order-dashboard">
		<div class="content">
			<div class="title-bar">
				<p class="title">Order Management</p>
			</div>
			<TabBarComponent
				:isLoading="false"
				:query.sync="query"
				:data="status"
				queryKey="status"
				:label="(r) => r.label"
				:key="false"
				:hasAll="false"
			/>
            <input placeholder="Search for orders..." type="text" class="search" v-model="search" />
            <input
                v-model="buyerLabel"
                type="text"
                class="search"
                style="margin-left: 10px"
                placeholder="User"
                @focus="openBuyerModal"
            />
            <br>
            <input type="date" class="search" style="width: 20%; margin-top:10px" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; margin-bottom: 10px; width: 20%" v-model="query.toDate">
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
			<ListingComponent
				model="data"
				:isLoading="isLoadingOrder"
				:data="orders"
				:query.sync="query"
				:refresh="getOrders"
				:toggleActionMenu="handleOrderClick"
				:listingOptions="listingOptions"
				:deleteRecord="deleteOrder"
			/>
		</div>
        <SelectUserModal
            :closeModal="closeBuyerModal"
            v-show="buyerModalOpened"
            :onSubmit="selectedBuyer"
            :roles="[buyerId]"
        />
	</div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";
import moment from "moment";
import SelectUserModal from "../../components/SelectUserModal";
export default {
	name: "OrderDashboard",
	components: {
		ListingLoadingComponent,
		TabBarLoadingComponent,
		ListingComponent,
		TabBarComponent,
        SelectUserModal
	},
	data() {
		return {
            buyer: null,
            buyerLabel: null,
            buyerModalOpened: false,
            searchTimeout: null,
            search: null,
			isLoadingOrder: false,
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
				sortBy: "createdAt",
				status: 0,
                fromDate: null,
                toDate: null
			},
			status: [
				{ _id: -1, label: "All" },
				{ _id: 0, label: "Pending" },
				{ _id: 1, label: "Ordering" },
				{ _id: 2, label: "In China" },
				{ _id: 3, label: "Shipping" },
				{ _id: 4, label: "In Cambodia" },
				{ _id: 5, label: "Wait For Payment" },
				{ _id: 6, label: "Paid" },
				{ _id: 7, label: "Delivering" },
				{ _id: 8, label: "Completed" },
			],
            excelName: 'Order-'+moment().format('YYYY-MM-DD')+'.xls'
		};
	},
	created() {
		this.getOrders();
        this.$store.dispatch("usersModule/getUserRoles");
	},
	methods: {
        async excelData() {
            const res = await this.$store.dispatch("orderModule/getOrders", {
                export: true,
                ...this.query, 
                searchValue: this.search,
                buyerId: this.buyer?._id
            });

            return res.data.map((record, index) => {
                let refund = 0;
                let total = 0;
                let s_i_cost = 0;
                let totalChinaMoney = 0;
                let refundChinaMoney = 0;
                record.orderItems?.map((i) => {
                    if(i.refund) {
                        refund += (i.product.price.maxPrice * i.amount) + i.serviceCost + i.insuranceCost;
                        refundChinaMoney += i.chinaMoney;
                    } else {
                        s_i_cost += i.serviceCost + i.insuranceCost;
                        total += i.product.price.maxPrice * i.amount;
                        totalChinaMoney += i.chinaMoney;
                    }
                });
                return {
                    '#': index + 1,
                    'ID': record.orderId,
                    'Customer': record.createBy ? record.createBy.name + "(" + record.createBy.phoneNumber + ")" : 'N/A',
                    'Products Price': total.toFixed(2),
                    'S & I Cost': s_i_cost.toFixed(2),
                    'Refund': refund.toFixed(2),
                    'Total ¥': totalChinaMoney.toFixed(2),
                    'Refund ¥': refundChinaMoney.toFixed(2),
                    'Date': moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                    'Status': this.getStatus(record.status)
                };
            });
        },
        openBuyerModal() {
            this.buyerModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectUserModelSearchInput").focus();
            })
        },
        closeBuyerModal() {
            this.buyerModalOpened = false;
        },
        selectedBuyer(buyer) {
            if(this.buyer == buyer) {
                return;
            }

            if(buyer) {
                this.buyerLabel = buyer.name + ' - ' + buyer.phoneNumber;
            } else {
                this.buyerLabel = null;
            }
            this.buyer = buyer;
            this.getOrders();
        },
		async getOrders() {
			this.isLoadingOrder = true;
            await this.$store.dispatch("orderModule/getOrders", {
                ...this.query, 
                searchValue: this.search,
                buyerId: this.buyer?._id
            });
			this.isLoadingOrder = false;
		},
		handleOrderClick(action, payload) {
			window.open(`/orders/${payload._id}`);
		},
		deleteOrder() {},
		getStatus(status) {
			switch (status) {
				case -1:
					return "All";
				case 0:
					return "Pending";
				case 1:
					return "Ordering";
				case 2:
					return "In China";
				case 3:
					return "Shipping";
				case 4:
					return "In Cambodia";
				case 5:
					return "Wait For Payment";
				case 6:
                    return "Paid";
                case 7: 
                    return "Delivering";
                case 8: 
                    return "Completed"
			}
		},
  },
  watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getOrders();
				}
			},
		},
        search: function() {
            this.searchTimeout && clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(
                () => this.getOrders(), 500
            )
        }
	},
	computed: {
		orders() {
			return this.$store.state.orderModule.orders;
		},
		listingOptions() {
			return {
				hasSelected: false,
				hasNo: true,
				actions: [],
				queryOptions: {
					sortBy: [{ label: "CreatedAt", value: "createdAt" }],
					sortDirection: [
						{ label: "Ascending", value: "ASC" },
						{ label: "Descending", value: "DESC" },
					],
				},
				schema: [
					{ label: "ID", key: "orderId" },
					{
						label: "Customer name",
						key: "createBy",
						value: (record) => record.createBy ? record.createBy.name : 'N/A',
					},
					{
						label: "Customer phone",
						key: "createBy",
						value: (record) => record.createBy ? record.createBy.phoneNumber : "N/A",
					},
					{
						label: "Products Price",
						value: (record) => {
                            let total = 0;
                            record.orderItems?.map((i) => {
                                if(!i.refund) {
                                    total += i.product.price.maxPrice * i.amount;
                                }
                            });
                            return total.toFixed(2);
                        },
					},
					{
						label: "S & I Cost",
						value: (record) => {
                            let total = 0;
                            record.orderItems?.map((i) => {
                                if(!i.refund) {
                                    total += i.serviceCost + i.insuranceCost;
                                }
                            });
                            return total.toFixed(2);
                        },
					},
					{
						label: "Refund",
						value: (record) => {
                            let refund = 0;
                            record.orderItems?.map((i) => {
                                if(i.refund) {
                                    refund += (i.product.price.maxPrice * i.amount) + i.serviceCost + i.insuranceCost;
                                }
                            });
                            return refund.toFixed(2);
                        },
					},
					{
						label: "Total ¥",
						value: (record) => {
                            let total = 0;
                            record.orderItems?.map((i) => {
                                if(!i.refund) {
                                    total += i.chinaMoney;
                                }
                            });
                            return total.toFixed(2);
                        },
					},
					{
						label: "Refund ¥",
						value: (record) => {
                            let total = 0;
                            record.orderItems?.map((i) => {
                                if(i.refund) {
                                    total += i.chinaMoney;
                                }
                            });
                            return total.toFixed(2);
                        },
					},
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
					},
					{
						label: "Status",
						key: "status",
						value: (record) => this.getStatus(record.status),
					},
				],
			};
		},
        roles() {
            return this.$store.state.usersModule.userRoles;
        },
        buyerId() {
            if(!this.roles.data) {
                return null;
            }
            const buyer = this.roles.data.filter(r => (r.name === 'buyer'))
            return buyer ? buyer[0]._id : ''
        },
	},
};
</script>

<style></style>
