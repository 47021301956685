import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    orders: { pageMeta: {}, data: [] },
    orderDetails: {},
    isArrived: {},
    exportOrders: [],
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload
    },
    setExportOrders(state, payload) {
      state.exportOrders = payload.data
    },
    setOrderDetails(state, payload) {
      state.orderDetails = payload
    },
    setOrderDetailStatus(state, payload) {
      state.orderDetails.status = payload
    },
    setOrderArrived(state, payload) {
      state.isArrived = payload
    },
    pushOrderRefundImage(state, payload) {
        if(state.orderDetails._id == payload.id) {
            if(!state.orderDetails.refundImages) {
                state.orderDetails.refundImages = [];
            }
            state.orderDetails.refundImages.push({
                image: payload.image
            });
        }
    },
  },
  actions: {

    async getOrderDetails(context, payload) {
      const { _id } = payload
      const url = `order/${_id}`
      const { success, result, message, userBalance } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      result.userBalance = userBalance;
      if (success) {
        context.commit('setOrderDetails', result)
      } else {
        httpErrorHandler(message)
      }
    },

    async getOrderByTrackingId(context, payload) {
      const { trackingId } = payload
      const url = `order/byTrackingId/${trackingId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        return result;
      } else {
        return false;
      }
    },
    async getOrders(context, payload) {
      const query = payload
      const url = 'order'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setOrders', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },
    async getComissionOrders(context, payload) {
      const query = payload
      const url = 'order/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setOrders', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async exportComissionOrders(context, payload) {
      const query = payload
      const url = 'order/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setExportOrders', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updateStatus(context, payload) {
      const { _id, status } = payload
      const url = `order/${_id}/status`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { status }, {}, {})
      if (success) {
        context.commit('setOrderDetailStatus', status)
        context.dispatch('notificationModule/getNotification', {}, { root: true })
      } else {
        httpErrorHandler(message)
      }
    },
    async orderArrived(context, payload) {
      const { _id, itemId } = payload
      const url = `order/${_id}/arrive/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {})
      if (success) {
        context.commit('setOrderArrived', { _id: _id, itemId })
      } else {
        httpErrorHandler(message)
      }
    },
    async payOrder(context, payload) {
      const { _id } = payload
      const url = `order/${_id}/pay`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async rejectOrder(context, payload) {
      const { _id } = payload
      const url = `order/${_id}/reject`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async refundOrderItem(context, payload) {
      const { orderId, itemId } = payload
      const url = `order/${orderId}/refund/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {})
      if (success) {
        context.dispatch('getOrderDetails', { _id: orderId })
      }
    },
    async noteOrderItem(context, payload) {
      const { orderId, itemId, note } = payload
      const url = `order/${orderId}/note/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {note}, {})
      if (success) {
        context.dispatch('getOrderDetails', { _id: orderId })
      }
    },
    async nextStatusOrderItem(context, payload) {
      const { orderId, itemId, date, trackingId, chinaMoney } = payload
      const url = `order/${orderId}/nextStatus/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
          date,
          trackingId,
          chinaMoney
      }, {})
      if (success) {
        context.dispatch('getOrderDetails', { _id: orderId })
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async trackingIdOrderItem(context, payload) {
      const { orderId, itemId, trackingId } = payload
      const url = `order/${orderId}/trackingId/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
          trackingId,
      }, {})
      if (success) {
        context.dispatch('detail', { _id: orderId })

        return true;
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async createOrder(context, payload) {
      const {
        orderId,
        transaction,
        createBy,
        shippingDetail,
        orderItems,
        shippingMethod,
        transportMethod,
        otherCost,
        localDeliveryCost,
        internationalShippingCost,
        chineseShippingCost,
        status
      } = payload
      const url = 'order'
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(
        url,
        "POST",
        {
          orderId,
          transaction,
          createBy,
          shippingDetail,
          orderItems,
          shippingMethod,
          transportMethod,
          otherCost,
          localDeliveryCost,
          internationalShippingCost,
          chineseShippingCost,
          status
        },
        {},
        {}
      )
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateCost(context, payload) {
      const {
        _id,
        otherCost,
        localDeliveryCost,
        internationalShippingCost,
        chineseShippingCost
      } = payload
      const url = `order/${_id}/servicePrice`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "PATCH", {
        otherCost,
        localDeliveryCost,
        internationalShippingCost,
        chineseShippingCost
      }, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async uploadRefundImage(context, payload) {
      const { id, image } = payload;
      const url = `order/${id}/upload_refund`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {image})
      if (success) {
        context.commit('pushOrderRefundImage', { image, id })
        return true
      } else {
        context.commit('appModule/alertMessage',
          {
            showing: true, color: '#dc3545', message
          }, { root: true })
        return false
      }
    }
  }
}