<template>
  <div class="page dashboard percentage-cut-page">
    <div class="content">
      <div class="title-bar" style="display: -webkit-inline-box;">
        <p class="title">Delivery Note</p>
        <div
          class="icon"
          style="
            background-image: url(/icons/printer.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            margin-top: 6px
          "
          @click="printData()"
        ></div>
      </div>
      
      <form v-on:submit.prevent="">
        <div class="invoice">
          <div style="width: 30rem; display: inline-block; margin-right: 20px">
            <div class="label">
              Sale Person<span
                style="line-height: 2rem; opacity: 0; width: 0"
              ></span>
            </div>
            <div class="input-ctn">
              <input
                v-model="recommender"
                type="text"
                class="input"
                placeholder="Sale Person"
                @focus="openSalePersonModal"
                disabled
              />
            </div>
            <div class="label">Invoice No</div>
            <div class="input-ctn">
              <input
                v-model="invoiceNo"
                type="text"
                class="input"
                placeholder="Invoice No"
                disabled
              />
            </div>
            <div class="label">Insurance</div>
            <div class="input-ctn">
              <select v-model="insurance" class="input" disabled>
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
          </div>
          <div style="width: 30rem; display: inline-block">
            <div class="label">
              VIP Customer
              <!-- <span class="btn" @click="isCreatingCustomer = true">+</span> -->
            </div>
            <div class="input-ctn" style="display: inline">
              <input
                v-model="vipCustomerLabel"
                type="text"
                class="input"
                placeholder="Vip Customer"
                @focus="openVipCustomerModal"
                disabled
              />
            </div>
            <div class="label">Shipping Method</div>
            <div class="input-ctn">
              <select v-model="shippingMethod" class="input" disabled>
                <option
                  v-for="item in shippingMethods.data"
                  :key="item._id"
                  :value="item._id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="label">Discount</div>
            <div class="input-ctn">
              <input
                v-model="discountAmount"
                type="number"
                step="0.01"
                class="input"
                placeholder="Discount Amount"
                disabled
              />
            </div>
            <!-- <div class="label">Transport Method</div>
                        <div class="input-ctn">
                            <select v-model="transportMethod" class="input">
                                <option value="0">Self Pick-up</option>
                                <option value="1">Nokor Delivery</option>
                            </select>
                        </div> -->
          </div>
        </div>
        <div class="invoiceItems">
          <h3 style="margin-left: 10px">
            <span class="required" style="margin-right: 5px">Items</span>
            <!-- <button class="btn btn-small" type="button" @click="addItem">
              Add Item
            </button> -->
          </h3>

          <table class="table">
            <thead>
              <th width="5%">#</th>
              <th width="5%">Image</th>
              <th width="10%">Check Product</th>
              <th width="30%">Color & Size</th>
              <th width="10%">QTY</th>
              <th width="10%">Unit Price</th>
              <th width="10%">China Fee</th>
              <th width="10%">Delivery Fee</th>
              <!-- <th width="10%">Total</th> -->
              <!-- <th width="5%"></th> -->
            </thead>
            <tbody>
              <tr v-for="(item, index) in invoiceItems" :key="key + index">
                <td>{{ index + 1 }}</td>
                <td v-show="item.imageSrc">
                  <img
                    :src="item.imageSrc"
                    style="width: 32px"
                    data-lity
                    :data-lity-target="item.imageSrc"
                  />
                  <!-- <button
                    class="btn btn-small"
                    style="margin-left: 10px"
                    type="button"
                    @click="clearImage(item)"
                  >
                    Remove
                  </button> -->
                </td>
                <!-- <td v-show="!item.imageSrc">
                  <input
                    type="text"
                    style="width: 60px"
                    placeholder="URL"
                    @change="cheangeImageSrc(item)"
                    v-model="item.imageSrc"
                  />
                  <input
                    style="display: none"
                    type="file"
                    :value="null"
                    :id="'file-' + index"
                    @change="handleImageSelected($event, item)"
                  />
                  <label :for="'file-' + index" class="btn btn-small"
                    >upload</label
                  >
                </td> -->
                <td>
                  <!-- <input
                    type="text"
                    placeholder="URL"
                    style="width: 120px"
                    v-model="item.checkProductLink"
                  /> -->
                  <a
                    :href="item.checkProductLink"
                    target="_blank"
                    v-if="
                      item.checkProductLink &&
                      item.checkProductLink.includes('http')
                    "
                    >View</a
                  >
                </td>
                <td>
                  <textarea
                    placeholder="Color & Size"
                    v-model="item.variantNote"
                    readonly 
                  >
                  </textarea>
                </td>
                <td>
                  {{item.amount}}
                  <!-- <input
                    type="number"
                    style="width: 80px"
                    step="0.01"
                    placeholder="QTY"
                    v-model="item.amount"
                    readonly 
                  /> -->
                </td>
                <td>
                  {{item.price}}
                  <!-- <input
                    type="number"
                    style="width: 80px"
                    step="0.01"
                    placeholder="Unit Price"
                    v-model="item.price"
                    readonly 
                  /> -->
                </td>
                <td>
                  {{item.chinaDeliveryCost}}
                  <!-- <input
                    type="number"
                    style="width: 80px"
                    step="0.01"
                    placeholder="Shipping Cost"
                    v-model="item.chinaDeliveryCost"
                    readonly 
                  /> -->
                </td>
                <td>
                  <input
                    type="number"
                    style="width: 80px"
                    step="0.01"
                    placeholder="Delivery Fee"
                    v-model="item.delivery_fee"
                  />
                </td>
                <!-- <td>
                  {{
                    (item.amount * item.price + parseFloat(item.chinaDeliveryCost) + parseFloat(item.delivery_fee)).toFixed(2)
                  }}
                </td> -->
                <!-- <td>
                  <button
                    type="button"
                    class="btn btn-small"
                    @click="removeItem(index)"
                  >
                    x
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
          <!-- <div class="result">
            <p>Total: $ {{ total }}</p>
          </div> -->
        </div>
        <!-- <button
          class="btn submit"
          @click="save"
          type="button"
          style="width: 30rem"
          :disabled="isLoading"
        >
          Save
        </button> -->
      </form>
    </div>
    <div :class="`action-menu active`" v-show="isCreatingCustomer">
      <div class="btn close" v-on:click="isCreatingCustomer = false"></div>
      <NewCustomerForm :addCustomer="addCustomer" />
    </div>
    <SelectSalePersonModal
      :closeModal="closeSalePersonModal"
      v-show="salePersonModalOpened"
      :onSubmit="selectedSalePerson"
    />
    <SelectVipCustomerModal
      :closeModal="closeVipCustomerModal"
      v-show="vipCustomerModalOpened"
      :onSubmit="selectedVipCustomer"
    />
  </div>
</template>
<script>
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
import SelectVipCustomerModal from "../../components/SelectVipCustomerModal";
import NewCustomerForm from "../customer/NewCustomerForm";
import { FileUpload } from "../../handler";
import { API_URL } from "../../const";

export default {
  name: "InvoiceNew",
  components: {
    SelectSalePersonModal,
    SelectVipCustomerModal,
    NewCustomerForm,
  },
  data() {
    return {
        invoce: {},
        id: null,
        invoiceNo: null,
        customer: null,
        shippingMethod: null,
        salePerson: null,
        recommender: null,
        insurance: 0,
        isLoading: false,
        vipCustomerLabel: null,
        vipCustomerModalOpened: false,
        transportMethod: 1,
        invoiceItems: [],
        isCreatingCustomer: false,
        salePersonModalOpened: false,
        discountAmount: 0,
        key: false,
        quotation_id: null,
    };
  },
  async mounted() {
    this.id = this.$route.query._id;

    if (this.id) {
      const data = await this.$store.dispatch("invoiceModule/detail", {
        _id: this.id,
      });

      this.shippingMethod = data.shippingMethod?._id;
    }

    this.$store.dispatch("shippingMethodModule/getShippingMethods", {});
  },
  async created() {
      const invoice = await this.$store.dispatch("invoiceModule/detail", {
        _id: this.$route.query.duplicate_id,
      });

      if (invoice) {
        this.invoice = invoice;
        this.invoiceNo = invoice.invoiceNo
        this.selectedVipCustomer(invoice.customer);
        this.shippingMethod = invoice.shippingMethod?._id;
        this.selectedSalePerson(invoice.salePerson);
        this.insurance = invoice.insurance ? 1 : 0;
        this.discountAmount = invoice.discountAmount;
        this.invoiceItems = invoice.invoiceItems.map((item) => {
          item.delivery_fee = 0;
          item.imageSrc =
            item.image &&
            (item.image.includes("http") || item.image.includes("data:image"))
              ? item.image
              : `${API_URL}/${item.image}`;
          item.file = null;
          item.image = null;
          return item;
        });
    } else {
        this.$router.back();
    }
  },
  methods: {
    async printData() {
        await this.$store.dispatch("invoiceModule/printDeliveryNote", {
            invoiceItems: this.invoiceItems,
            ...this.invoice
        });

        this.$router.push({ path: `/invoices/print/driver-note` });
    },
    cheangeImageSrc(item) {
      this.key = true;
      item.file = null;
      this.key = false;
    },
    clearImage(item) {
      this.key = true;
      item.file = null;
      item.imageSrc = null;
      this.key = false;
    },
    removeItem(index) {
      this.invoiceItems.splice(index, 1);
    },
    openSalePersonModal() {
      this.salePersonModalOpened = true;
      setTimeout(() => {
        document.getElementById("selectSalePersonModelSearchInput").focus();
      });
    },
    closeSalePersonModal() {
      this.salePersonModalOpened = false;
    },
    selectedSalePerson(sale) {
      if (sale) {
        this.recommender = sale.code + " - " + sale.name;
        this.salePerson = sale._id;
      } else {
        this.recommender = null;
        this.salePerson = null;
      }
    },
    async addCustomer(payload) {
      const customer = await this.$store.dispatch(
        "vipCustomerModule/create",
        payload
      );
      if (customer) {
        this.isCreatingCustomer = false;
        this.selectedVipCustomer(customer);
      }
    },
    addItem() {
      this.invoiceItems.push({
        imageSrc: null,
        file: null,
        variantNote: null,
        amount: 1,
        price: 0,
        chinaDeliveryCost: 0,
      });
    },
    openVipCustomerModal() {
      this.vipCustomerModalOpened = true;
      setTimeout(() => {
        document.getElementById("selectVipCustomerModelSearchInput").focus();
      });
    },
    closeVipCustomerModal() {
      this.vipCustomerModalOpened = false;
    },
    selectedVipCustomer(vipCustomer) {
      const id = vipCustomer ? vipCustomer._id : null;
      if (this.customer == id) {
        return;
      }

      if (vipCustomer) {
        this.vipCustomerLabel =
          vipCustomer.name +
          " - " +
          vipCustomer.phoneNumber +
          " (" +
          vipCustomer.code +
          ")";
      } else {
        this.vipCustomerLabel = null;
      }
      this.customer = id;
    },
    selectedShippingMethod(shippingMethod) {
      const id = shippingMethod ? shippingMethod._id : null;
      if (this.customer == id) {
        return;
      }

      if (shippingMethod) {
        this.shippingMethodLabel = shippingMethod.name;
      } else {
        this.shippingMethodLabel = null;
      }
      this.customer = id;
    },
    async save() {
      this.isLoading = true;

      if (
        !this.customer ||
        !this.shippingMethod ||
        this.transportMethod === null ||
        this.invoiceItems.length == 0
      ) {
        alert("Please input all required data");
        this.isLoading = false;
        return false;
      }

      const items = [];

      for (let [idx, i] of this.invoiceItems.entries()) {
        if (i.file) {
          const file = await FileUpload.uploadFile(i.file);
          i.image = file.path;
        } else {
          i.image = i.imageSrc;
        }
        items.push(i);
      }

      const success = await this.$store.dispatch(
        this.id ? "invoiceModule/update" : "invoiceModule/create",
        {
          _id: this.id,
          customer: this.customer,
          invoiceNo: this.invoiceNo,
          salePerson: this.salePerson,
          insurance: this.insurance,
          shippingMethod: this.shippingMethod,
          transportMethod: this.transportMethod,
          discountAmount: this.discountAmount,
          invoiceItems: items,
        }
      );

      if (success) {
        if (this.quotation_id) {
          await this.$store.dispatch("quotationModule/updateStatus", {
            status: 1,
            _id: this.quotation_id,
          });
        }
        alert("Invoice Create Successfully");
        this.$router.push({
          path: "/invoices/" + success._id,
        });
      }

      this.isLoading = false;
    },
    async handleImageSelected(e, item) {
      this.key = true;
      const image = e.target.files[0];
      item.imageSrc = URL.createObjectURL(image);
      item.file = image;
      this.key = false;
    },
  },
  computed: {
    shippingMethods() {
      if (
        !this.shippingMethod &&
        this.$store.state.shippingMethodModule.shippingMethods.data
      ) {
        this.shippingMethod =
          this.$store.state.shippingMethodModule.shippingMethods.data[0]._id;
      }
      return this.$store.state.shippingMethodModule.shippingMethods;
    },
    total() {
      return this.invoiceItems
        .reduce(
          (a, item) =>
            item.amount * item.price +
            parseFloat(item.chinaDeliveryCost) + 
            parseFloat(item.delivery_fee) +
            parseFloat(a),
          0
        )
        .toFixed(2);
    },
  },
};
</script>

<style src="lity/dist/lity.min.css"></style>

<style scoped>
form {
  height: 100%;
  justify-content: center;
}

.invoice {
  display: flex;
  /* width: 100rem; */
  /* text-align:center; */
}

.invoiceItems {
  margin: 10px;
  padding-top: 20px;
  background-color: rgb(241, 241, 241);
  justify-content: center;
}

.input {
  width: 100%;
  border: 1px solid #000;
  margin-bottom: 1rem;
  line-height: 3rem;
  padding: 0 1rem;
}

.btn {
  background-color: #000;
  color: #fff;
  line-height: 2rem;
  padding: 0 1rem;
}

.submit {
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  line-height: 3rem;
  display: flex;
  justify-content: center;
}

.table {
  margin-top: 10px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
  vertical-align: middle;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.result {
  border: 3px solid rgb(107, 107, 107);
  height: 60px;
  margin-top: 3px;
  width: 20%;
  align-items: center;
  display: flex;
  justify-content: center;
  float: right;
  color: #000;
  /* margin-bottom: 2rem; */
}
</style>