import { httpErrorHandler, HttpRequest } from "../../handler";

const SET_PRODUCTS = 'setProducts';
const SET_LOADING_PRODUCTS_LISTING = 'setLoadingProduct';
const SET_SUBMITTING_PRODUCT = 'setSubmittingProduct';

export default {
  namespaced: true,
  state: {
    isLoadingProduct: false,
    isSubmittingProduct: false,
    isLoadingProductCategory: false,
    productsListing: { pageMeta: null, products: [] },
    productCategories: { pageMeta: null, categories: [] },
  },
  mutations: {
    [SET_PRODUCTS](state, payload) {
      state.productsListing = payload
    },
    [SET_LOADING_PRODUCTS_LISTING](state, payload) {
      state.isLoadingProduct = payload
    },
    [SET_SUBMITTING_PRODUCT](state, payload) {
      state.isSubmittingProduct = payload
    }
  },
  actions: {
    async getProducts(context, payload) {
      context.commit(SET_LOADING_PRODUCTS_LISTING, true)
      const url = 'localProduct'
      const { query } = payload
      const { category, ...rest } = query
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...rest, category: category ? category : undefined })
      if (success) {
        context.commit(SET_PRODUCTS, result)
      } else {
        httpErrorHandler(message)
      }
      context.commit(SET_LOADING_PRODUCTS_LISTING, false)
    },
    async getById(context, payload) {
      const { id } = payload
      const url = `localProduct/${id}`
      const {success, result, message} = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if(success) {
        return result
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async deleteProduct(context, payload) {
      const { id } = payload
      const url = `localProduct/${id}`
      const {success, message } = await HttpRequest.authorizedRequest(url, 'DELETE', {}, {}, {})
      context.commit(SET_PRODUCTS, context.state.productsListing.data.filter(item => item._id != id));
      if(success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async createProduct(context, payload) {
      const url = 'localProduct'
      const { data } = payload
      console.log(data);
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', data)
      if (success) {
        return result
      } else {
        httpErrorHandler(message)
      }
    }
  }
}