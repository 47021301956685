<template>
  <div class="page dashboard products-page">
    <div class="content">
			<div
				v-if="isActionMenuOpen"
				class="action-menu-backdrop"
				v-on:click="toggleActionMenu('')"
			></div>

			<div class="title-bar">
				<p class="title">Product Management</p>
				<div class="group">
					<div class="btn new">
						<div class="icon"></div>
                        <router-link
                            :to="`/new-product`"
                            class="label"
                        >
						    New Product
                        </router-link>
					</div>
				</div>
			</div>

      <!-- <TabBarComponent
				:isLoading="isLoadingProductCategory"
				:query.sync="query"
				:data="productCategories.data"
				queryKey="category"
				:label="(record) => record.category"
				:updateSearch="updateSearch"
        placeholder="Search by Name"
			/> -->

      <ListingComponent
				model="data"
				:isLoading="isLoadingProduct"
				:data="products"
				:query.sync="query"
				:refresh="getProducts"
				:selected.sync="selected"
				:listingOptions="listingOptions"
				:deleteRecord="deleteProduct"
			/>
    </div>
				<!-- :toggleActionMenu="toggleActionMenu" -->

    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
			<div 
        class="btn close" v-on:click="toggleActionMenu('')"></div>
		</div>
  </div>
</template>


<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";
import moment from "moment";

export default {
  name: "ProductsDashboard",
  components: {
    ListingLoadingComponent,
    TabBarLoadingComponent,
    ListingComponent,
    TabBarComponent,
  },
  data() {
    return {
      selected: [],
			isActionMenuOpen: "",
			focusedProduct: {},
			q: "",
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
				sortBy: "",
				category: 0,
			},
		};
  },
  methods: {
    toggleActionMenu(menu, payload = {}) {
			if (menu !== "") {
				if (menu === "edit" || menu === "read") {
					this.focusedProduct = payload;
				} 
			}
			this.isActionMenuOpen = menu;
		},
    updateSearch(q) {
			this.q = q;
			this.getProducts();
    },
    async getProducts() {
      await this.$store.dispatch('productsModule/getProducts', {
        query: { ...this.query, q: this.q }
      })
    },
    async getProductCategories() {
      await this.$store.dispatch('productsModule/getProductCategories')
    },
    async deleteProduct(item) {
        const confirmed = confirm('Are you sure you want to delete this?')

        if(confirmed) {
            this.$store.dispatch("productsModule/deleteProduct", {
                id: item._id
            });
        }
    }
  },
  watch: {
    query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
          this.getProducts();
				}
			},
		},
  },
  created() {
    this.getProducts();
    this.getProductCategories();
  },
  computed: {
    listingOptions() {
			return {
				hasSelect: false,
				actions: ["delete"],
				queryOptions: {
					sortBy: [
						{
							label: "Name",
							value: "name",
						},

					],
					sortDirection: [
						{
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
					],
				},
				schema: [
					{
						label: "Name",
						key: "name",
					},
					{
						label: "Price",
						key: "price",
                        value: (record) => {
                            const price = record.price.minPrice == record.price.maxPrice ? record.price.minPrice : record.price.minPrice + ' - ' + record.price.maxPrice;

                            return '$' + price;
                        }
					},
					{
						label: "Promo Price",
						key: "price",
                        value: (record) => {
                            const price =  record.promoPrice.minPrice == record.promoPrice.maxPrice ? record.promoPrice.minPrice : record.promoPrice.minPrice + ' - ' + record.promoPrice.maxPrice;

                            return '$' + price;
                        }
					},
					{
						label: "Type",
						key: "type",
					},
					{
						label: "Variations",
                        key: "variations",
                        value: (record) => {
                            return record.variations.length
                            }
                        },
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
					},
				],
			};
		},
    isLoadingProductCategory() {
      return this.$store.state.productsModule.isLoadingProductCategory;
    },
    productCategories() {
      return this.$store.state.productsModule.productCategories;
    },
    isLoadingProduct() {
      return this.$store.state.productsModule.isLoadingProduct;
    },
    products() {
      return this.$store.state.productsModule.productsListing;
    },
    isSubmittingProduct(){
      return this.$store.state.productsModule.isSubmittingProduct;
    }
  }
};
</script>
<style scoped>
</style>
