<template>
  <div class="shipping-details">
    <p class="btn" v-on:click="toggleActionMenu('edit')">Back</p>
    <p class="title">Shipping Details</p>
    <div class="shipping-detail-list">
      <div v-for="shipping in user.shippingDetail" :key="shipping._id" class="shipping-detial-item">
        <div class="shipping-info">
          <p class="info">{{shipping.lastName + shipping.firstName}}</p>
          <p class="info">{{shipping.phoneNumber}}</p>
          <p class="info">{{shipping.address}}</p>
          <p class="info">{{shipping.city}}</p>
          <p class="info">{{shipping.country}}</p>
          <p class="info">{{shipping.postalCode}}</p>
        </div>
        <div class="map"></div>
      </div>
      <p class="add-shipping-details btn">Add Shipping Details</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShippingDetails',
  props: ['user', 'toggleActionMenu'],
  computed:{
    
  }
}
</script>

<style>

</style>