<template>
    <div class="page dashboard percentage-cut-page">
        <div class="content">
            <div class="title-bar">
                <p class="title">Setting, {{id ? 'Edit' : 'Add New'}} Promotion Product</p>
            </div>
            <div>
                <form v-on:submit.prevent="editSetting">
                    <div class="label required">Name</div>
                    <div class="input-ctn">
                        <input type="text" v-model="name" placeholder="Name" class="input">
                    </div>
                    <div class="label required">Image</div>
                    <div class="input-ctn">
                        <input type="text" v-model="imageUrl" placeholder="Image" class="input">
                    </div>
                    <div class="label required">Link To</div>
                    <div class="input-ctn">
                        <input type="text" v-model="linkTo" placeholder="Link To" class="input">
                    </div>

                    <div class="label required">Origin Price($)</div>
                    <div class="input-ctn">
                        <input type="number" min="0" step="0.01" v-model="originPrice" placeholder="Origin Price" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <div class="label required">Promotion Price($)</div>
                    <div class="input-ctn">
                        <input type="number" min="0" step="0.01" v-model="promotionPrice" placeholder="Promotion Price"
                            class="input" oninput="validity.valid||(value='');">
                    </div>
                    <div class="label required">Promotion Percentage(%)</div>
                    <div class="input-ctn">
                        <input type="number" min="0" v-model="promotionPercentage" placeholder="Promotion Percentage"
                            class="input" oninput="validity.valid||(value='');">
                        <input type="color" v-model="promotionPercentageColor" class="input">
                    </div>

                    <div class="label">Order</div>
                    <div class="input-ctn">
                        <input type="number" min="0" v-model="order" placeholder="Order" class="input"
                            oninput="validity.valid||(value='');">
                    </div>

                    <button class="btn submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SettingPromotionProductNew",
        components: {},
        data() {
            return {
                id: null,
                name: null,
                imageUrl: null,
                linkTo: null,
                originPrice: 0,
                promotionPrice: 0,
                promotionPercentage: 0,
                promotionPercentageColor: "#ff4200",
                order: 20,
                isLoading: true
            }

        },
        mounted() {
            this.id = this.$route.query._id;
            this.name = this.$route.query.name;
            this.imageUrl = this.$route.query.imageUrl;
            this.linkTo = this.$route.query.linkTo;
            this.originPrice = this.$route.query.originPrice || 0;
            this.promotionPrice = this.$route.query.promotionPrice || 0;
            this.promotionPercentage = this.$route.query.promotionPercentage || 0;
            this.promotionPercentageColor = this.$route.query.promotionPercentageColor || "#ff4200";
            this.order = this.$route.query.order || 20;
        },
        methods: {
            async editSetting() {
                this.isLoading = true

                const success = await this.$store.dispatch(this.id ? 'updateSettingPromotionProduct' :
                    'createSettingPromotionProduct', {
                        _id: this.id,
                        name: this.name,
                        imageUrl: this.imageUrl,
                        linkTo: this.linkTo,
                        originPrice: this.originPrice,
                        promotionPrice: this.promotionPrice,
                        promotionPercentage: this.promotionPercentage,
                        promotionPercentageColor: this.promotionPercentageColor,
                        order: this.order
                    })
                if (success) {
                    alert("Successfully");
                    this.$router.push({
                        path: "/setting/promotion-product"
                    });
                }
                this.isLoading = false
            }
        }

    }
</script>
<style scoped>
    form {
        width: 30rem;

    }

    .input {
        width: 100%;
        border: 1px solid #000;
        margin-bottom: 1rem;
        line-height: 3rem;
        padding: 0 1rem;
    }

    .btn {
        background-color: #000;
        color: #fff;
        line-height: 2rem;
        padding: 0 1rem;
    }

    .submit {
        width: 100%;
        background-color: #000000;
        color: #ffffff;
        line-height: 3rem;
        display: flex;
        justify-content: center;
    }
</style>