import { httpErrorHandler, HttpRequest } from "../../handler"

export default {
  namespaced: true,
  state: {
    notifications: {
      orders: 0,
      topUps: 0,
      transactions: 0
    },
  },
  mutations: {
    setNotification(state, payload){
      state.notifications = payload
    }
  },
  actions: {
    async getNotification(context, payload) {
      const url = 'notification'
      const {success, result, message } = await HttpRequest.authorizedRequest(url, "GET")
      if(success) {
        context.commit('setNotification', result)
        console.log(result)
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    }
  }
}