<template>
  <div class="modal">
    <div class="modal-content select-category-modal">
      <p class="btn" v-on:click="handleSubmit(null)">x</p>
      <form @submit.prevent>
        <input type="text" id="selectSalePersonModelSearchInput" v-model="search" class="input" placeholder="Search SalePerson">
      </form>
      <div class="category-list">
          <div>
              
          </div>
        <div 
          v-for="item in salePersons.data" 
          :key="item._id"
          v-on:click="handleSubmit(item)"
          class="category btn"
        >
          <p>{{item.code + ' - ' + item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit'],
  data() {
    return {
        searchTimeout: null,
        search: ''
    }
  },
  watch: {
    search: function() {
        this.searchTimeout && clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(
            () => this.getSalePersons(), 500
        )
    },
  },
  mounted() {
      this.getSalePersons();
  },
  methods: {
    handleSubmit(salePerson) {
        // this.$store.dispatch("salePersonsModule/clearSalePersons");
        this.onSubmit(salePerson)
        this.closeModal()
    },
    getSalePersons: function() {
        this.$store.dispatch("salePersonModule/getSalePeople", {
            roles: this.roles,
            query: {
                search: this.search,
            }
        });
    }
  },
  computed: {
    salePersons() {
        return this.$store.state.salePersonModule.salePeople;
    }
  }
}
</script>

<style>

</style>