<template>
  <div class="page dashboard merchant-details">
    <p v-if="loading">Fetching Data . . .</p>
    <div class="content" v-if="!loading">
      <div class="title-bar">
        <p class="title">Merchant Dashboard</p>
      </div>
      <div class="page-content">
        <div class="merchant-info">
          <div class="user-info card">
            <p class="bold">Shop Owner</p>
            <p>Username: {{merchant.owner.name}}</p>
            <p>Phone: {{merchant.owner.phoneNumber}}</p>
            <p class="btn">View Account</p>
          </div>
          <div class="shop-info card">
            <div
              :style="{'background-image': `url(${API_URL}/${merchant.logo})`}"
              class="merchant-logo"
            />
            <p class="bold">Merchant</p>
            <p>Shopname: {{merchant.name}}</p>
            <p>{{merchant.slogan}}</p>
          </div>
        </div>
        <div class="merchant-products">
          <div class="category-list">
            <div
              :class="`category btn ${(category.category._id === selectedCategory.category._id) ? 'active' : ''}`"
              v-for="category in productsCategories"
              v-if="category.category"
              :key="category.category._id"
              v-on:click="selectCategory(category)"
            >{{category.category}}</div>
          </div>
          <div class="product-list">
            <router-link
              :to="`/products/${product._id}`"
              class="product-item"
              v-for="product in selectedCategory.products"
              :key="product._id"
            >
              <div
                :style="{'background-image': `url(${API_URL}/${product.mainImage})`}"
                class="product-image"
              />
              <div class="product-info">
                <p class="bold">{{product.name}}</p>
              </div>
            </router-link>
          </div>
          <div class="new-product">
            <router-link
              class="btn"
              :to="{
                path: '/new-product', 
                query: {merchantId}
                }"
            >New product</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from "../../const";
export default {
  data() {
    return {
      loading: true,
      merchant: {},
      productsCategories: [],
      selectedCategory: {}
    }
  },
  methods: {
    async getMerchantDetails() {
      const {merchant, productsCategories } = await this.$store.dispatch('merchantModule/getMerchantDetails', {id: this.merchantId})
      this.merchant = merchant
      this.productsCategories = productsCategories
      if(productsCategories.length > 0){
        this.selectedCategory = productsCategories[0]
      }
      this.loading = false
    },
    selectCategory(category) {
      this.selectedCategory = category
    }
  },
  created() {
    this.getMerchantDetails()
  },
  computed: {
    merchantId() {
      return this.$route.params.id
    },
    API_URL() {
      return API_URL;
    }
  }
}
</script>

<style>

</style>