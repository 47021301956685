<template>
  <div class="side-menu-component">
    <div class="shadow-ctn">
      <div class="ctn">
        <div class="menu">
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer' ||  currentUser.role.name === 'moderator'"
            :class="`item ${tabName == 'transactions' ? 'active' : ''}`"
            v-on:click="goToRoute('/transactions')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/transection.png')`}">
              <p class="counter" v-if="notifications.transactions !== 0">{{notifications.transactions}}</p>
            </div>
            <div class="label">Transactions</div>
          </div>
          <div
            :class="`item ${tabName == 'orders' ? 'active' : ''}`"
            v-on:click="goToRoute('/orders')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/order.png')`}">
              <p class="counter" v-if="notifications.orders !== 0">{{notifications.orders}}</p>
            </div>
            <div class="label">Orders</div>
          </div>
          <div
            :class="`item ${tabName == 'quotations' ? 'active' : ''}`"
            v-on:click="goToRoute('/quotations')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/transection.png')`}">
            </div>
            <div class="label">VIP Quotations</div>
          </div>
          <div
            :class="`item ${tabName == 'invoices' ? 'active' : ''}`"
            v-on:click="goToRoute('/invoices')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/order.png')`}">
            </div>
            <div class="label">VIP Invoices</div>
          </div>

          <div
              v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
              :class="`item ${tabName == 'Commission' ? 'active' : ''}`"
              v-on:click="goToRoute('/commission')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/dollar-sidemenu.png')`} "></div>
            <div class="label">Commission</div>
          </div>

          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
            :class="`item ${tabName == 'top-up-request' ? 'active' : ''}`"
            v-on:click="goToRoute('/top-up-request')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/topuprequest.png')`}">
              <p class="counter" v-if="notifications.topUps !== 0">{{notifications.topUps}}</p>
            </div>
            <div class="label">Top-up Requests</div>
          </div>
          <div
            :class="`item ${tabName == 'product-categories' ? 'active' : ''}`"
            v-on:click="goToRoute('/product-categories')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/category.png')`}"></div>
            <div class="label">Product Categories</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'moderator'"
            :class="`item ${tabName == 'products' ? 'active' : ''}`"
            v-on:click="goToRoute('/products')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/product.png')`}"></div>
            <div class="label">Products</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'order'"
            :class="`item ${tabName == 'banners' ? 'active' : ''}`"
            v-on:click="goToRoute('/banners')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/banner.png')`}"></div>
            <div class="label">Banner</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'order'"
            :class="`item ${tabName == 'merchants' ? 'active' : ''}`"
            v-on:click="goToRoute('/merchants')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/merchant.png')`}"></div>
            <div class="label">Merchants</div>
          </div>
          <div
            :class="`item ${tabName == 'users' ? 'active' : ''}`"
            v-on:click="goToRoute('/users')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/user.png')`}"></div>
            <div class="label">Users</div>
          </div>
          <div
            :class="`item ${tabName == 'firstinfo' ? 'active' : ''}`"
            v-on:click="goToRoute('/firstinfo')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/user.png')`}"></div>
            <div class="label">Visitor Info</div>
          </div>
          <div
            :class="`item ${tabName == 'customer-type' ? 'active' : ''}`"
            v-on:click="goToRoute('/customer-type')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/category.png')`}"></div>
            <div class="label">Customer Type</div>
          </div>
          <div
            :class="`item ${tabName == 'customer' ? 'active' : ''}`"
            v-on:click="goToRoute('/customer')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/user.png')`}"></div>
            <div class="label">VIP Customer</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin'"
            :class="`item ${tabName == 'summary' ? 'active' : ''}`"
            v-on:click="goToRoute('/summary')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/exchange.png')`}"></div>
            <div class="label">Summary</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin'"
            :class="`item ${tabName == 'shipping-methods' ? 'active' : ''}`"
            v-on:click="goToRoute('/shipping-methods')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/delivery.png')`}"></div>
            <div class="label">Shipping Methods</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin'"
            :class="`item ${tabName == 'exchange-rate' ? 'active' : ''}`"
            v-on:click="goToRoute('/exchange-rate')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/exchange.png')`}"></div>
            <div class="label">Exchange Rate</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin'"
            :class="`item ${tabName == 'percentage-cut' ? 'active' : ''}`"
            v-on:click="goToRoute('/percentage-cut')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/percent.png')`}"></div>
            <div class="label">Percentage Cut</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
            :class="`item ${tabName == 'sale-person' ? 'active' : ''}`"
            v-on:click="goToRoute('/sale-person')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/sale_person.png')`}"></div>
            <div class="label">Sale Person</div>
          </div>
          <div
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
            :class="`item ${tabName == 'top-up-info' ? 'active' : ''}`"
            v-on:click="goToRoute('/top-up-info')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/topupinfo.png')`}"></div>
            <div class="label">Top up Info</div>
          </div>

          <div
              :class="`item ${tabName == 'setting' ? 'active' : ''}`"
              v-on:click="goToRoute('/setting')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/settings.png')`}"></div>
            <div class="label">Homepage Product</div>
          </div>

          <div
              :class="`item ${tabName == 'setting-promotion-product' ? 'active' : ''}`"
              v-on:click="goToRoute('/setting/promotion-product')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/settings.png')`}"></div>
            <div class="label">Promotion Product</div>
          </div>

          <div
              :class="`item ${tabName == 'setting-suggestion' ? 'active' : ''}`"
              v-on:click="goToRoute('/setting/suggestion')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/settings.png')`}"></div>
            <div class="label">Suggestion</div>
          </div>

          <!-- <div
              v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
              :class="`item ${tabName == 'CustomerSubscription' ? 'active' : ''}`"
              v-on:click="goToRoute('/customer-subscription')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/customerSubscription.png')`}"></div>
            <div class="label">Customer Subscription</div>
          </div> -->

          <div
              v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
              :class="`item ${tabName == 'BalanceTransaction' ? 'active' : ''}`"
              v-on:click="goToRoute('/balance')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/dollar-sidemenu.png')`}"></div>
            <div class="label">Balance</div>
          </div>

          <!-- voucher -->
          <!-- <div
              v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
              :class="`item ${tabName == 'VoucherDashboard' ? 'active' : ''}`"
              v-on:click="goToRoute('/voucher-history')"
          >
            <div class="icon" :style="{'background-image': `url('/icons/gift.png')`}"></div>
            <div class="label">Voucher</div>
          </div> -->

          <div class="item" v-on:click="logOut('/profile')">
            <div class="icon" :style="{'background-image': `url('/icons/logout.png')`}"></div>
            <div class="label">Log Out</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "SideMenuComponent",
  computed: {
    tabName() {
      return this.$route.meta.tabName;
    },
    notifications() {
      return this.$store.state.notificationModule.notifications;
    },
    currentUser() {
      return this.$store.state.usersModule.currentUser
    }
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    goToRoute(route) {
      this.$router.push({ path: route });
    },
    getNotification() {
      this.$store.dispatch("notificationModule/getNotification");
    },
    logOut() {
      const response = this.$store.dispatch("usersModule/logOut");

      if (response) {
        this.$router.push({ path: "/auth" });
      }
    },
  }
};
</script>
<style scoped>
</style>
