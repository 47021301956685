import Axios from 'axios'
import { TOKEN_LABEL } from '../const'
import { API_URL } from "../const";

const publicRequest = async (url, method, data, params = {}) => {
  return new Promise(resolve => {
    const request = Axios({
      method,
      url: `${API_URL}/${url}`,
      data,
      params,
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
      }
    })
    request.then(response => {
      if (200 <= response.status && response.status < 300) {
        resolve(response.data)
      }else {
        resolve({ success: false, message: error.message })
      }
    })
    request.catch((error, res) => {
      resolve({success: false, message: error.response.data.message})
    })
  })
}
const authorizedRequest = async (url, method, data, params={}, extraHeaders={}) => {
  return new Promise(resolve => {
    const TOKEN = localStorage.getItem(TOKEN_LABEL)
    if (!TOKEN) {
      resolve( {success: false, message: 'Not logged in'})
    }
    const request = Axios({
      method,
      url: `${API_URL}/${url}`,
      data,
      params,
      headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Authorization': `Bearer ${TOKEN}`,
        ...extraHeaders
      }
    })
    request.then(response => {
      if (200 <= response.status && response.status < 300) {
        resolve(response.data)
      } else {
        resolve({ success: false, message: error.message })
      }
    })
    request.catch(error => {
      resolve({ success: false, message: error.response.data.message })
    })
  })
}

export default {
  publicRequest,
  authorizedRequest,
}