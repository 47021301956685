<template>
  <div class="adjust-user-balance">
    <p class="title">Adjuct Balance</p>
    <p>Current Balance: {{balance}}</p>
    <form>
      <div class="input-ctn">
        <div class="label">Amount</div>
        <input 
          type="number" 
          v-model="amount"
          placeholder="Amount" 
          class="input"
        >
      </div>
      <div class="input-ctn">
        <div class="label">Note</div>
        <input 
          v-model="note"
          type="text" 
          class="input"
          placeholder="Note"
        >
      </div>
      <div class="row" v-if="!loading">
        <p class="btn" v-on:click="adjustBalance(1)">Add</p>
        <p class="btn" v-on:click="adjustBalance(0)">Subtract</p>
      </div>
      <div class="row" v-if="loading">
        <p class="bold">Loading . . .</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AdjustUserBalance',
  props: ['user'],
  data() {
    return {
      loading: false,
      amount: 0,
      note: '',
      balance: this.user.balance.amount
    }
  },
  methods: {
    async adjustBalance(method) {
      this.loading = true
      const success = await this.$store.dispatch('usersModule/topUpUser', {
        id: this.user._id,
        amount: parseFloat(parseFloat(this.amount).toFixed(2)),
        note: this.note, 
        method
      })
      if(success) {
        alert('Completed')
      }

      method === 1 && (this.balance += parseFloat(parseFloat(this.amount).toFixed(2)))
      method === 0 && (this.balance -= parseFloat(parseFloat(this.amount).toFixed(2)))
      this.amount = 0
      this.note = ''
      this.loading = false

    }
  },
  computed: {
    currentUser() {
      return this.user
    }
  }
}
</script>

<style>

</style>
