<template>
  <div class="page dashboard product-details">
    <div class="content">
      <div class="title-bar">
        <div class="title">
          Product Details
        </div>
      </div>
      <p v-if="loading">Loading. . . </p>
      <div class="page-content" v-if="!loading">
        <div class="product-image">
            <div class="main-image" :style="{'background-image': `url(${API_URL}/${product.mainImage})`}" />
          <div class="image-list">
            <img 
              :key="idx"
              v-for="(image,idx) in product.listImage" 
              :src='`${API_URL}/${image}`' 
              class="image"
            >
          </div>
        </div>
        <div class="product-info">
          <div class="product-detail">
            <p class="bold big">
              Name: {{product.name}}
            </p>
            <p class="big">Category: {{product.category.category}}</p>
            <p>Tag: {{product.tag}}</p>
            <p class="bold">Price: $ {{product.price.maxPrice}}</p>
            <p class="bold">Promo Price: $ {{product.promoPrice.maxPrice}}</p>
          </div>
          <div class="variations">
            <p class="bold big">Variation</p>
            <div 
              :key="vIdx"
              class="variation-item" 
              v-for="(variation, vIdx) in product.variations"
            >
              <p class="bold">{{variation.name}}</p>
              <div class="option-list">
                <div :key="option.value" class="option" v-for="option in variation.data">
                  <img v-if="option.image" :src='`${API_URL}/${option.image}`' alt="" class="option-image">
                  <div class="option-info">
                    <p>{{option.value}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="delete-product">
            <p class="btn delete" v-on:click="deleteProduct">Delete product</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {API_URL} from '../../const'
export default {
  data() {
    return {
      loading: true,
      product: {}
    }
  },
  created() {
    this.getProductDetails()
  },
  methods: {
    async deleteProduct() {
      const confirmed = confirm('Are you sure?')
      if(confirmed) {
        const done = await this.$store.dispatch('productsModule/deleteProduct', {id: this.productId})
        if(done){
          this.$router.go(-1)
        }
      }
    }, 
    async getProductDetails () {
      const result = await this.$store.dispatch('productsModule/getById', {id: this.productId})
      if(result) {
        this.product = result
        console.log(result)
        this.loading = false
      } else {
        alert('cant get product')
      }
    }
  },
  computed: {
    productId () {
      return this.$route.params.id
    },
    API_URL() {
      return API_URL
    }
  }
}
</script>

<style>

</style>