<template>
	<div class="page dashboard order-dashboard">
		<div class="content">
			<div class="title-bar">
				<p class="title">Visitor Info</p>
			</div>
            <input placeholder="Search for ..." type="text" class="search" v-model="query.search" />
            <input type="date" class="search" style="width: 20%" v-model="query.fromDate">
            <input type="date" class="search" style="margin-left: 10px; width: 20%" v-model="query.toDate">
            <download-excel
                class="export-btn" 
                :fetch="excelData"
                :name="excelName"
            >
                Export Execel
            </download-excel>
			<ListingComponent
				model="data"
				:isLoading="isLoadingItem"
				:data="items"
				:query.sync="query"
				:refresh="getItems"
				:listingOptions="listingOptions"
                :deleteRecord="() => {}"
                :calledRecord="calledRecord"
			/>
		</div>
	</div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import moment from "moment";
export default {
	name: "FirstInfoDashboard",
	components: {
		ListingLoadingComponent,
		ListingComponent
	},
	data() {
		return {
			isLoadingItem: false,
            searchTimeout: null,
			query: {
				order: "DESC",
				page: 1,
				limit: 10,
				sortBy: "createdAt",
				status: 0,
                search: null,
                fromDate: null,
                toDate: null,
            },
            excelName: 'Visitor-'+moment().format('YYYY-MM-DD')+'.xls'
		};
	},
	created() {
		this.getItems();
	},
	methods: {
        async calledRecord(record) {
            await this.$store.dispatch("firstInfoModule/followedUp", {
                _id: record._id
            });

            this.getItems()
        },
		async getItems(keyword) {
			this.isLoadingItem = true;
			await this.$store.dispatch("firstInfoModule/getFirstInfos", this.query);
			this.isLoadingItem = false;
		},
        async excelData() {
            await this.$store.dispatch("firstInfoModule/exportFirstInfos", {
                export: true,
                ...this.query
            });

            const data = this.$store.state.firstInfoModule.exportFirstInfos;

            return data.map((item, index) => {
                return {
                    '#': index + 1,
                    'Name': item.name || "N/A",
                    'Phone': item.phoneNumber ? item.phoneNumber.replace(855, 0) : "N/A",
                    'Call': item.isFollowedUp ? "Yes" : "No",
                    'Date': moment(item.createdAt).format('DD/MM/YYYY H:mm')
                };
            });
        },
  },
  watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getItems();
				}
			},
            search: function() {
                this.searchTimeout && clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(
                    () => this.getItems(), 500
                )
            }
		},
	},
	computed: {
		items() {
			return this.$store.state.firstInfoModule.firstInfos;
		},
		listingOptions() {
			return {
				hasSelected: false,
				actions: ['called'],
				queryOptions: {
					sortBy: [],
					sortDirection: [
						{ label: "Ascending", value: "ASC" },
						{ label: "Descending", value: "DESC" },
					],
				},
				schema: [
					{
						label: "Name",
						key: "Name",
						value: (record) => record.name || 'N/A',
					},
					{
						label: "Phone Number",
						key: "phoneNumber",
						value: (record) => record.phoneNumber || "N/A",
					},
					{
						label: "Created At",
						key: "createdAt",
						value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
					},
					{
						label: "Call",
						key: "called",
						value: (record) => record.isFollowedUp ? "Yes" : "No",
					}
				],
			};
		},
	},
};
</script>

<style></style>
