<template>
  <div class="modal">
    <div class="modal-content select-category-modal">
      <p class="btn" v-on:click="handleSubmit(null)">x</p>
      <form @submit.prevent>
        <input type="text" id="selectUserModelSearchInput" v-model="search" class="input" placeholder="Search User">
      </form>
      <div class="category-list">
          <div>
              
          </div>
        <div 
          v-for="item in users.data" 
          :key="item._id"
          v-on:click="handleSubmit(item)"
          class="category btn"
        >
          <p>{{item.name + ' - ' + item.phoneNumber}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['closeModal', 'onSubmit', 'roles'],
  data() {
    return {
        searchTimeout: null,
        search: ''
    }
  },
  watch: {
    search: function() {
        this.searchTimeout && clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(
            () => this.getUsers(), 500
        )
    },
  },
  methods: {
    handleSubmit(user) {
        this.$store.dispatch("usersModule/clearUsers");
        this.onSubmit(user)
        this.closeModal()
    },
    getUsers: function() {
        this.$store.dispatch("usersModule/getUsers", {
            roles: this.roles,
            query: {
                search: !!this.search ? this.search : 'UGAKYHSLKHJ:FKJASKHVFLBJNJNA>NSJ<FVBKJH SAKGFKSAG',
            }
        });
    }
  },
  computed: {
    users() {
        return this.$store.state.usersModule.usersListing;
    }
  }
}
</script>

<style>

</style>