<template>
  <div class="page dashboard order-details">
    <div class="content">
      <div class="title-bar" style="justify-content: normal">
        <p class="title">VIP Invoice Details ({{ invoice.invoiceId }})</p>
        <div
          class="icon"
          style="
            background-image: url(/icons/copy-text.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
          "
          @click="copy(invoice.invoiceId, 'ID ')"
        ></div>
        <div
          class="icon"
          style="
            background-image: url(/icons/printer.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
          "
          @click="printInvoince()"
        ></div>
        <div
          class="icon"
          style="
            background-image: url(/icons/duplicate.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
          "
          @click="duplicateInoivce()"
        ></div>
        <div
          class="icon"
          style="
            background-image: url(/icons/writing.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 12px;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
          "
          @click="printData()"
        ></div>
      </div>
      <router-link to="/invoices">Back</router-link>
      <p v-if="isLoading">Fetching Data . . .</p>
      <div class="page-content" v-if="!isLoading">
        <div class="order-info">
          <div class="user-info">
            <p class="bold">
              UserName:
              {{
                invoice.customer
                  ? invoice.customer.name +
                    " - " +
                    invoice.customer.phoneNumber +
                    "(" +
                    invoice.customer.code +
                    ")"
                  : "(Deleted Account)"
              }}
            </p>
            <p>
              Address:
              {{ invoice.customer ? invoice.customer.address : null }}
            </p>
          </div>
          <div class="user-info">
            <p class="bold">
              Sale Person:
              {{
                invoice.salePerson
                  ? invoice.salePerson.name +
                    " - " +
                    (invoice.salePerson.phoneNumber || "N/A") +
                    "(" +
                    invoice.salePerson.code +
                    ")"
                  : "(Deleted Account)"
              }}
            </p>
          </div>
          <div class="shipping-methods">
            <p class="bold">Shipping Setting</p>
            <p>Shipping Method: {{ invoice.shippingMethod.name }}</p>
            <p>
              Delivery Method:
              {{
                invoice.transportMethod === 0 ? "Self-pickup" : "Nokor delivery"
              }}
            </p>
          </div>
          <div class="payment-confirmation">
            <p class="bold">Payment</p>
            <p>
              Other Cost: $
              {{ invoice.internationalShippingCost }}
            </p>
            <p>Total China Fee: $ {{ total_china_fee.toFixed(2) }}</p>
            <p>Total Product Price: $ {{ total_product_price.toFixed(2) }}</p>
            <p v-if="invoice.insurance">
              Insurance: {{ insuranceLabel }} = $ {{ insurance.toFixed(2) }}
            </p>
            <p v-if="invoice.discountAmount" class="bold">
              Discount: $ {{ invoice.discountAmount.toFixed(2) }}
            </p>
            <br />
            <p class="bold big">Total: $ {{ total.toFixed(2) }}</p>
            <p class="bold big">
              Paid: $
              {{ invoice.paidAmount ? invoice.paidAmount.toFixed(2) : 0 }}
            </p>
            <p class="bold big">
              Due: $ {{ (total - (invoice.paidAmount || 0)).toFixed(2) }}
            </p>
            <br />
            <button
              v-on:click="openUpdateCostModal"
              class="btn pay"
              v-if="invoice.status < 5"
            >
              Update Cost
            </button>
            <button
              v-on:click="openUpdatePaidModal"
              class="btn pay"
              style="margin-left: 5px"
              v-if="invoice.status < 5"
            >
              Update Payment
            </button>
          </div>
          <div class="payment-confirmation">
            <p class="bold text-danger">
              <span style="float: left"> Payment Images</span>
            </p>
            <br />
            <div style="display: flex; width: 410px; overflow-y: auto;">
              <div
                style="position: relative"
                v-for="(image, index) in invoice.paymentImages"
                :key="index"
              >
                <img
                  class="order-image"
                  :src="`${API_URL}/${image.image}`"
                  data-lity
                  :data-lity-target="`${API_URL}/${image.image}`"
                  width="80px"
                  style="cursor: pointer; margin-right: 10px; margin-top: 10px"
                  alt=""
                />
                <button
                  type="button"
                  style="
                    border: none;
                    color: #fff;
                    position: absolute;
                    top: 0;
                    left: 62px;
                    font-size: 15px;
                    cursor: pointer;
                    border-radius: 50%;
                    height: 27px;
                    background: red;
                    width: 27px;
                    z-index: 2;
                  "
                  @click="removeImage(index, image.image)"
                >
                  x
                </button>
              </div>
            </div>

            <br />
            <button
              @click="openUploadPaymentImageModel"
              class="btn pay"
              v-if="invoice.status < 5"
            >
              Upload Image
            </button>
          </div>
          <!-- <div class="payment-confirmation">
            <p class="bold text-danger">
              <span style="float: left"> Summary Images</span>
            </p>
            <br />
            <div
              style="position: relative"
              v-for="(image, index) in invoice.summaryImages"
              :key="index"
            >
              <img
                class="order-image"
                :src="`${API_URL}/${image.image}`"
                data-lity
                :data-lity-target="`${API_URL}/${image.image}`"
                width="80px"
                style="cursor: pointer; margin-right: 5px; margin-top: 10px"
                alt=""
              />
              <button
                type="button"
                style="
                  border: none;
                  color: #fff;
                  position: absolute;
                  top: 0;
                  left: 62px;
                  font-size: 15px;
                  cursor: pointer;
                  border-radius: 50%;
                  height: 27px;
                  background: red;
                  width: 27px;
                "
                @click="removeSummary(index)"
              >
                x
              </button>
            </div>

            <br />
            <button
              @click="openUploadSummaryImageModel"
              class="btn pay"
              v-if="invoice.status < 5"
            >
              Upload Sumarry Image
            </button>
          </div> -->
          <!-- summarryImages -->
        </div>

        <div class="items-list">
          <div class="status">
            <p class="bold">Invoice Status:</p>
            <p v-if="updatingStatus">Updating Status . . .</p>
            <div class="status-list" v-if="invoice.status === 9">
              <p>Rejected</p>
            </div>
            <div
              class="status-list"
              v-if="!updatingStatus && invoice.status !== 9"
            >
              <p v-on:click="updateInvoiceStatus(0)" :class="`btn active`">
                Pending
              </p>
              <p :class="`btn ${invoice.status > 0 ? 'active' : 'not-allow'}`">
                Ordering
              </p>
              <p :class="`btn ${invoice.status > 1 ? 'active' : 'not-allow'}`">
                In China
              </p>
              <p :class="`btn ${invoice.status > 2 ? 'active' : 'not-allow'}`">
                Shipping
              </p>
              <p :class="`btn ${invoice.status > 3 ? 'active' : 'not-allow'}`">
                In Cambodia
              </p>
              <p
                v-on:click="updateInvoiceStatus(5)"
                :class="`btn ${invoice.status > 4 ? 'active' : ''}`"
              >
                Wait For Payment
              </p>
              <p
                v-on:click="updateInvoiceStatus(6)"
                :class="`btn ${invoice.status > 5 ? 'active' : ''}`"
              >
                Paid
              </p>
              <p
                v-on:click="updateInvoiceStatus(7)"
                :class="`btn ${invoice.status > 6 ? 'active' : ''}`"
              >
                Delivering
              </p>
              <p
                v-on:click="updateInvoiceStatus(8)"
                :class="`btn ${invoice.status > 7 ? 'active' : ''}`"
              >
                Completed
              </p>
            </div>
          </div>
          <p class="bold big">Items</p>

          <div class="product-list">
            <div
              class="product-item"
              :style="
                product.itemId == itemId ? 'border: 1px solid red;' : null
              "
              v-for="(product, index) in items"
              :key="index"
            >
              <div
                class="text_num"
                style="
                  background: #333;
                  color: #fff;
                  padding: 0.1rem 0.4rem;
                  border-radius: 0.2rem;
                "
              >
                <ul>
                  <li>{{ index + 1 }}</li>
                </ul>
              </div>
              <img
                :src="
                  product.image &&
                  (product.image.includes('http') ||
                    product.image.includes('data:image'))
                    ? product.image
                    : `${API_URL}/${product.image}`
                "
                data-lity
                :data-lity-target="
                  product.image &&
                  (product.image.includes('http') ||
                    product.image.includes('data:image'))
                    ? product.image
                    : `${API_URL}/${product.image}`
                "
                alt
                class="image"
              />
              <div class="product-info">
                <div style="width: 60%; display: inline-block">
                  <p class="big bold">
                    {{ product.variantNote }}
                  </p>
                  <p class="small bold">
                    Unit Price: {{ product.price }} (x{{ product.amount }}) = $
                    {{ parseFloat(product.price * product.amount).toFixed(2) }}
                  </p>
                  <p class="small bold" v-if="product.status !== 9 && product.refundAmount > 0">
                    Small Refund: {{ product.price }} (x{{ product.refundAmount }}) = $
                    {{ parseFloat(product.price * product.refundAmount).toFixed(2) }}
                  </p>
                  <p class="small bold">
                    China Fee: $ {{ product.chinaDeliveryCost }}
                  </p>
                  <p class="bold">
                    Total: $
                    {{
                      parseFloat(
                        (product.price * product.amount) - (product.price * product.refundAmount) +
                          product.chinaDeliveryCost
                      ).toFixed(2)
                    }}
                  </p>
                  <a
                    target="_blank"
                    v-if="
                      product.checkProductLink &&
                      product.checkProductLink.includes('http')
                    "
                    :href="product.checkProductLink"
                    class="link bold"
                    >Check Product</a
                  >
                </div>
                <div
                  class="btn-group"
                  style="width: 40%; display: inline-block; vertical-align: top"
                >
                  <textarea
                    v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) || product.note"
                    v-model="product.note"
                    rows="3"
                    style="
                      width: 170px;
                      margin-top: 5px;
                      border: 1px solid #6c757d;
                      border-radius: 5px;
                      heigth: 60px;
                      padding: 0.3rem;
                    "
                    :readonly="!['admin', 'financer', 'order'].includes(currentUser.role.name)"
                  >
                  </textarea>
                  <p
                    v-on:click="noteInvoiceItem(product.itemId, product.note)"
                    v-if="
                      ['admin', 'financer', 'order'].includes(
                        currentUser.role.name
                      )
                    "
                    class="btn info"
                    style="width: 100px"
                  >
                    Update Note
                  </p>
                <br v-if="invoice.status < 4 && product.status < 4" />
                <input
                  v-if="(invoice.status < 4 && product.status < 4) || product.trackingId"
                  placeholder="Tracking ID"
                  type="text"
                  style="
                    border: 1px solid #6c757d;
                    border-radius: 5px;
                    width: 150px;
                  "
                  v-model="product.trackingId"
                />
                <p
                    v-if="invoice.status < 4 && product.status < 4"
                  v-on:click="
                    updateTrackingIdInvoiceItem(
                      product.itemId,
                      product.trackingId
                    )
                  "
                  class="btn info"
                  style="margin-top: 5px; width: 90px"
                >
                  Update
                </p>
                </div>
              </div>
              <div class="btn-group">
                <!-- <div>
                  <input
                  type="checkbox"
                  v-model="product.isArrived"
                  @click="updateInvoiceArrival(product.itemId)"
                  class="checkbox"
                />
                <span>
                    {{product.isArrived? 'Arrive': "Not Arrived"}}
                </span>
                </div> -->

                <label v-if="product.orderingAt"
                  >Ordering At: {{ product.orderingAt }}</label
                >
                <br v-if="product.orderingAt" />
                <label v-if="product.inChinaAt"
                  >In China At: {{ product.inChinaAt }}</label
                >
                <br v-if="product.inChinaAt" />
                <label v-if="product.shippingAt"
                  >Shipping At: {{ product.shippingAt }}</label
                >
                <br v-if="product.shippingAt" />
                <label v-if="product.inCambodiaAt"
                  >In Cambodia At: {{ product.inCambodiaAt }}</label
                >
                <br v-if="product.inCambodiaAt" />
                <label v-if="product.chinaMoney && product.status > 0"
                  >China Money: {{ product.chinaMoney }}</label
                >
                <br v-if="product.chinaMoney && product.status > 0" />
                <input
                  v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && invoice.status == 0 && invoice.status >= product.status"
                  placeholder="China Money"
                  type="number"
                  setp="0.01"
                  style="
                    border: 1px solid #6c757d;
                    border-radius: 5px;
                    width: 200px;
                  "
                  v-model="product.chinaMoney"
                />
                <br
                  v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && invoice.status == 0 && invoice.status >= product.status"
                />
                <input
                  v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && invoice.status < 4 && product.status < 4"
                  type="datetime-local"
                  style="
                    border: 1px solid #6c757d;
                    border-radius: 5px;
                    width: 200px;
                  "
                  v-model="product.nextStatusDate"
                />
                <p
                  v-if="['admin', 'financer', 'order'].includes(currentUser.role.name) && invoice.status < 4 && product.status < 4"
                  v-on:click="
                    nextStatusInvoiceItem(
                      product.itemId,
                      product.trackingId,
                      product.nextStatusDate,
                      product.chinaMoney
                    )
                  "
                  class="btn info"
                  style="margin-top: 5px; width: 90px"
                >
                  {{ getNextStatus(product.status) }}
                </p>
                <p v-if="product.status === 9" class="rejected">Refunded</p>
                <p
                  v-else-if="['admin', 'financer'].includes(currentUser.role.name) && product.refundAmount == 0"
                  @click="openRefundItemModal(product.itemId)"
                  class="btn refund"
                  style="margin-top: 5px; width: 90px"
                >
                  Refund
                </p>
                <p
                  v-else-if="['admin', 'financer'].includes(currentUser.role.name) && product.refundAmount > 0"
                  @click="openRefundItemModal(product.itemId)"
                  class="btn refund"
                  style="margin-top: 5px; width: 120px"
                >
                  Refund ({{product.refundAmount}})
                </p>
                <!-- <div class="btn-group" v-if="invoice.status === 0 && !invoice.deletedAt">
                    <template v-if="product.status === 0">
                        <p class="btn reject" style="padding-left: 10px; padding-right: 10px;">Reject</p>
                    </template>
                    <p v-if="product.status === 2" class="rejected">Rejected</p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateCostModal
      v-if="updateCostModalOpened"
      :closeModal="closeUpdateCostModal"
      :onSubmit="updateExtraPrice"
      :chineseShippingCost="invoice.chineseShippingCost"
      :internationalShippingCost="invoice.internationalShippingCost"
    />
    <UpdatePaidModal
      v-if="updatePaidModalOpened"
      :closeModal="closeUpdatePaidModal"
      :onSubmit="updatePaidAmount"
      :paidAmount="invoice.paidAmount ? invoice.paidAmount : 0"
    />
    <UploadFileModal
      v-if="uploadPaymentImageModelOpened"
      :closeModal="closeUploadPaymentImageModel"
      :handleFormSubmit="uploadPaymentImageModelSubmit"
    />
    <UploadFileModal
      v-if="uploadSummaryImageModelOpened"
      :closeModal="closeUploadSummaryImageModel"
      :handleFormSubmit="uploadSummaryImageModelSubmit"
    />
    <RefundItemModal :onSubmit="refundItem" :closeModal="closeRefundItemModal" v-if="refundItemModalOpened"/>
  </div>
</template>

<script>
import UpdateCostModal from "./UpdateCostModal";
import UpdatePaidModal from "./UpdatePaidModal";
require("lity");
import { API_URL } from "../../const";
import { FileUpload } from "../../handler";
import UploadFileModal from "../../components/UploadFileModal";
import moment from "moment";
import RefundItemModal from "./RefundItemModal";
export default {
  components: {
    FileUpload,
    UploadFileModal,
    UpdateCostModal,
    UpdatePaidModal,
    RefundItemModal
  },
  data() {
    return {
      isLoading: true,
      updatingStatus: false,
      API_URL: API_URL,
      updateCostModalOpened: false,
      updatePaidModalOpened: false,
      itemId: this.$route.query.itemId,
      uploadPaymentImageModelOpened: false,
      uploadSummaryImageModelOpened: false,
      refundItemModalOpened: false,
      refundItemId: null
    };
  },
  async created() {
    await this.getInvoiceDetails();
    if (this.itemId) {
      do {
        this.invoice &&
          setTimeout(() => this.arrivedCambodia(this.itemId), 1000);
      } while (!this.invoice);
    }
  },
  methods: {
    async removeImage(index, image) {
        const success = await this.$store.dispatch(
          "invoiceModule/deletePaymentImage", {
            path: image,
            id: this.invoice._id,
          }
        );

        if(success) {
          this.invoice.paymentImages.splice(index, 1);
        }
    },
    removeSummary(index) {
      this.invoice.summaryImages.splice(index, 1);
    },
    duplicateInoivce() {
      this.$router.push({
        path: `/invoices/new?duplicate_id=${this.invoice._id}`,
      });
      // window.open(`/invoices/${this.invoice._id}/print`);
    },
    printData() {
      this.$router.push({
        path: `/invoices/driver-note?duplicate_id=${this.invoice._id}`,
      });
      // window.open(`/invoices/${this.invoice._id}/print`);
    },
    printInvoince() {
      this.$router.push({ path: `/invoices/${this.invoice._id}/print` });
      // window.open(`/invoices/${this.invoice._id}/print`);
    },
    async uploadPaymentImageModelSubmit(file) {
      if (file) {
        this.uploadPaymentImageModelOpened = false;
        const fileObj = await FileUpload.uploadFile(file);
        const success = await this.$store.dispatch(
          "invoiceModule/uploadPaymentImage",
          {
            image: fileObj.path,
            id: this.invoice._id,
          }
        );
        if (success) {
          alert("Upload payment image successfully");
        } else {
          alert("Error something went wrong.");
        }
      }
    },
    openUploadPaymentImageModel() {
      this.uploadPaymentImageModelOpened = true;
    },
    closeUploadPaymentImageModel() {
      this.uploadPaymentImageModelOpened = false;
    },

    // summaryImages
    async uploadSummaryImageModelSubmit(file) {
      if (file) {
        this.uploadSummaryImageModelOpened = false;
        const fileObj = await FileUpload.uploadFile(file);
        const success = await this.$store.dispatch(
          "invoiceModule/uploadSummaryImage",
          {
            image: fileObj.path,
            id: this.invoice._id,
          }
        );
        if (success) {
          alert("Upload Summary image successfully");
        } else {
          alert("Error something went wrong.");
        }
      }
    },
    openUploadSummaryImageModel() {
      this.uploadSummaryImageModelOpened = true;
    },
    closeUploadSummaryImageModel() {
      this.uploadSummaryImageModelOpened = false;
    },
    // end summaryImages

    async arrivedCambodia(itemId) {
      const item = this.items.find((item) => item.itemId == itemId);

      if (item && item.status == 3) {
        const confirmed = confirm(
          "Are you sure this item arrived in cambodia?"
        );

        if (confirmed) {
          this.$store.dispatch("invoiceModule/nextStatusInvoiceItem", {
            invoiceId: this.invoiceId,
            itemId,
            date: moment().format("YYYY-MM-DD h:mm A"),
          });
        }
      }
    },
    async payInvoice() {
      const confirmed = confirm(
        "Are you sure, you want to update payment for this invoice?"
      );
      if (confirmed) {
        const done = await this.$store.dispatch("invoiceModule/payInvoice", {
          _id: this.invoiceId,
          piad: this.invoice.paid,
        });
        if (done) {
          this.getInvoiceDetails();
        }
      }
    },
    closeUpdateCostModal() {
      this.updateCostModalOpened = false;
    },
    openUpdateCostModal() {
      this.updateCostModalOpened = true;
    },
    async updateExtraPrice(payload) {
      const done = await this.$store.dispatch("invoiceModule/updateCost", {
        _id: this.invoiceId,
        ...payload,
      });
      if (done) {
        this.getInvoiceDetails();
        this.closeUpdateCostModal();
        alert("Updated");
      }
    },
    closeUpdatePaidModal() {
      this.updatePaidModalOpened = false;
    },
    openUpdatePaidModal() {
      this.updatePaidModalOpened = true;
    },
    async updatePaidAmount(payload) {
      const done = await this.$store.dispatch(
        "invoiceModule/updatePaidAmount",
        {
          _id: this.invoiceId,
          ...payload,
        }
      );
      if (done) {
        this.getInvoiceDetails();
        this.closeUpdatePaidModal();
        alert("Updated");
      }
    },
    getNextStatus(status) {
      switch (status) {
        case 0:
          return "Ordering";
        case 1:
          return "In China";
        case 2:
          return "Shipping";
        case 3:
          return "In Cambodia";
      }
      return null;
    },

    copy(text, label) {
      if (window.clipboardData && window.clipboardData.setData) {
        alert("Copy " + label + "successfully");
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          alert("Copy " + label + "successfully");
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          alert("Copy failed");
          console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },

    async updateInvoiceArrival(itemId) {
      const confirmed = confirm("Are you sure this invoice has arrived?");
      if (confirmed) {
        this.$store.dispatch("invoiceModule/invoiceArrived", {
          _id: this.invoiceId,
          itemId,
        });
      }
    },

    async getInvoiceDetails() {
      await this.$store.dispatch("invoiceModule/detail", {
        _id: this.invoiceId,
      });
      this.isLoading = false;
    },

    async updateInvoiceStatus(status) {
      const confirmed = confirm("Are you sure you want to updated status?");
      if (confirmed) {
        this.updatingStatus = true;
        await this.$store.dispatch("invoiceModule/updateStatus", {
          _id: this.invoice._id,
          status,
        });
        this.updatingStatus = false;
      }
    },

    async noteInvoiceItem(itemId, note) {
      const confirmed = confirm("Are you sure you want to note this item?");
      if (confirmed) {
        this.$store.dispatch("invoiceModule/noteInvoiceItem", {
          invoiceId: this.invoiceId,
          itemId,
          note,
        });
      }
    },

    async updateTrackingIdInvoiceItem(itemId, trackingId) {
      const confirmed = confirm(
        "Are you sure you want to update tracking id this item?"
      );
      if (confirmed) {
        const success = await this.$store.dispatch("invoiceModule/trackingIdInvoiceItem", {
          invoiceId: this.invoiceId,
          itemId,
          trackingId,
        });

        if(success) {
            alert('Update tracking id suceesfully')
        } else {
            alert('Fail to update tracking id')
        }
      }
    },

    openRefundItemModal(itemId) {
        this.refundItemModalOpened = true; 
        this.refundItemId = itemId;
    },

    closeRefundItemModal() {
        this.refundItemModalOpened = false; 
        this.refundItemId = null;
    },

    async refundItem(refundAmount) {
        const success = await this.$store.dispatch("invoiceModule/refundItem", {
          invoiceId: this.invoiceId,
          itemId: this.refundItemId,
          refundAmount:refundAmount
        });

        if(success) {
            alert('Refund item successfully')
        } else {
            alert('Fail to refund item')
        }
    },

    async nextStatusInvoiceItem(itemId, trackingId, date, chinaMoney) {
      const confirmed = confirm(
        "Are you sure you want to update status this item?"
      );
      if (confirmed) {
        date = date ? moment(date) : moment();
        this.$store.dispatch("invoiceModule/nextStatusInvoiceItem", {
          invoiceId: this.invoiceId,
          itemId,
          date: date.format("YYYY-MM-DD h:mm A"),
          trackingId,
          chinaMoney: chinaMoney || 0,
        });
      }
    },
  },
  computed: {
    invoiceId() {
      return this.$route.params.id;
    },
    items() {
      return this.$store.state.invoiceModule.invoiceDetails
        ? this.$store.state.invoiceModule.invoiceDetails.invoiceItems
        : [];
    },
    invoice() {
      return this.$store.state.invoiceModule.invoiceDetails;
    },
    currentUser() {
      return this.$store.state.usersModule.currentUser;
    },
    notRefundItems() {
        return this.items.filter(o => o.status !== 9);
    },
    total_product_price() {
      return _.sumBy(this.notRefundItems, (o) => {
        return o.price * o.amount - (o.price * o.refundAmount);
      });
    },
    total_china_fee() {
      return _.sumBy(this.notRefundItems, (o) => {
        return o.chinaDeliveryCost;
      });
    },
    insurance() {
      return this.invoice.insurance ? this.total_product_price * 0.05 : 0;
    },
    insuranceLabel() {
      return this.total_product_price.toFixed(2) + " x 5%";
    },
    total() {
      return (
        this.total_product_price +
        this.insurance +
        this.total_china_fee +
        this.invoice.internationalShippingCost -
        this.invoice.discountAmount
      );
    },
  },
};
</script>

<style src="lity/dist/lity.min.css"></style>
<style scoped>
.not-allow {
  cursor: not-allowed;
}
</style>
    