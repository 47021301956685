import HttpRequest from './HttpRequest';
import { httpErrorHandler } from '.';

const uploadFile = async (image) => {
  const url = 'file'
  const formData = new FormData();
  formData.append('file',image)
  const {success, message, result} = await HttpRequest.authorizedRequest(url, 'POST', formData, {}, {'Content-Type': 'multipart/form-data'})
  if(success) {
    return result;
  } else {
    console.log(message);
    httpErrorHandler(message)
  }
}

const getFile = async (_id) => {
  const url = `file/${_id}`
  const {success, message, result} = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
  if (success, message, result) {
    return result;
  } else {
    httpErrorHandler(message)
  }
}

export default {
  uploadFile,
  getFile
}