<template>
  <div class="modal">
    <div class="modal-content other-cost-modal">
      <p class="btn" v-on:click="closeModal">x</p>
      <form v-on:submit.prevent="handleSubmit">
        <div class="input-ctn">
          <div class="label">International Shipping Cost</div>
          <input
            type="text"
            class="input"
            placeholder="International Shipping Cost"
            v-model="internationalShippingCost"
          />
        </div>
        <div class="input-ctn">
          <div class="label">Chinese Shipping Cost</div>
          <input
            type="text"
            class="input"
            placeholder="Chinese Shipping Cost"
            v-model="chineseShippingCost"
          />
        </div>
        <div class="input-ctn">
          <div class="label">Local Delivery Cost</div>
          <input
            type="text"
            class="input"
            placeholder="Local Delivery Cost"
            v-model="localDeliveryCost"
          />
        </div>
        <div class="input-ctn">
          <div class="label">Other Cost</div>
          <input type="text" class="input" placeholder="Other Cost" v-model="otherCost" />
        </div>
        <div class="submit">
          <p v-if="loading" class="btn disabled-btn">Creating . . .</p>
          <button
            v-if="!loading"
            type="submit"
            class="btn"
            style="background-color: black; color: white; padding: .5rem 1rem; margin-left: 2.5rem"
          >Make Order</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['needInsurance', 'onSubmit', 'closeModal'],
  data() {
    return {
      otherCost: 0,
      internationalShippingCost: 0,
      chineseShippingCost: 0,
      localDeliveryCost: 0,
      loading: false
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      const { 
        otherCost, 
        internationalShippingCost, 
        chineseShippingCost, 
        localDeliveryCost 
      } = this
      await this.onSubmit({
        otherCost, 
        internationalShippingCost, 
        chineseShippingCost, 
        localDeliveryCost 
      })
      this.loading = false
      this.closeModal()
    }
  }
}
</script>

<style>

</style>