import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    firstInfos: { pageMeta: {}, data: [] },
    exportFirstInfos: [],
  },
  mutations: {
    setFirstInfos(state, payload) {
      state.firstInfos = payload
    },
    setExportFirstInfos(state, payload) {
      state.exportFirstInfos = payload.data
    }
  },
  actions: {
    async getFirstInfos(context, payload) {
      const query = payload
      const url = 'firstinfo'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      console.log(result)
      if (success) {
        context.commit('setFirstInfos', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async exportFirstInfos(context, payload) {
      const query = payload
      const url = 'firstinfo'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      console.log(result)
      if (success) {
        context.commit('setExportFirstInfos', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async followedUp(context, payload) {
      const { _id } = payload
      const url = `firstinfo/${_id}/followedUp`
      const { success, message } = await HttpRequest.authorizedRequest(url, 'PATCH')
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
  }
}