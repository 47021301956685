<template>
  <div class="listing-loading-component">
    <table class="loading">
      <tr>
        <th class="select">
          <div class="select-item"></div>
        </th>
        <th>--</th>
        <th>--</th>
        <th>--</th>
        <th>--</th>
        <th class="option"></th>
      </tr>
      <tbody>
        <tr>
          <td class="select">
            <div class="select-item"></div>
          </td>
          <td class="has-image">
            <div class="image"></div>
            <div class="item"></div>
          </td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="option">
            <div class="icon"></div>
          </td>
        </tr>
        <tr>
          <td class="select">
            <div class="select-item"></div>
          </td>
          <td class="has-image">
            <div class="image"></div>
            <div class="item"></div>
          </td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="option">
            <div class="icon"></div>
          </td>
        </tr>
        <tr>
          <td class="select">
            <div class="select-item"></div>
          </td>
          <td class="has-image">
            <div class="image"></div>
            <div class="item"></div>
          </td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="option">
            <div class="icon"></div>
          </td>
        </tr>
        <tr>
          <td class="select">
            <div class="select-item"></div>
          </td>
          <td class="has-image">
            <div class="image"></div>
            <div class="item"></div>
          </td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="item"></td>
          <td class="option">
            <div class="icon"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "ListingLoadingComponent"
};
</script>
<style scoped>
</style>
