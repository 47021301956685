<template>
  <main>
    <center class="con">
        <img src="../../assets/Nokor.png" alt="">
    </center>
    <div class="item_quotation">
        <div class="box" style="width: 33%">
            <a href="#">www.nokorshop.com</a>
            <pre>   Tel: {{ quotation.salePerson.phoneNumber ? quotation.salePerson.phoneNumber : "N/A" }} (Telegrame)</pre>
            <pre>        : {{ quotation.salePerson.phoneNumber ? quotation.salePerson.phoneNumber : "N/A"  }}</pre>
            <pre>   Sale Name: {{ quotation.salePerson.name }}</pre>
        </div>
        <div class="quotation" style="width: 34%">
            <h3>សម្រង់តម្រៃ</h3>
            <h4>QUOTATION</h4>
        </div>
        <div class="box" style="width: 33%">
            <pre>ថ្ងៃខែឆ្នាំ         ៖​ {{ createdAt }}</pre>
            <pre>លេខវិក្កយបត្រ​  ៖ {{ quotation.quotationNo }}</pre>
            <pre style="white-space: break-spaces; word-break: break-word;">ឈ្មោះអតិថិជន  ៖​ {{ quotation.customer.name }}</pre>
            <pre>ប្រភេទអតិថិជន ៖ VIP</pre>
            <pre>លេខទូរស័ព្ទ     ៖​ {{ quotation.customer.phoneNumber }}</pre>
            <pre style="white-space: break-spaces; word-break: break-word;">ទីតាំង           ៖​ {{quotation.customer.address}}</pre>
        </div>
    </div>
    <table>
        <tr>
        <th width="5%"><p>លរ</p></th>
        <th width="15%"><p>រូបភាព</p></th>
        <th width="25%">
          <p>ពណ៌ ទំហំ</p>
        </th>
        <th width="10%"><p>ចំនួន</p></th>
        <th width="15%"><p>តម្លៃរាយ</p></th>
        <th width="15%">
          <p>
            ថ្លៃដឹកនៅចិន
          </p>
        </th>
        <th width="15%"><p>សរុប​</p></th>
      </tr>

        <!-- @{{ items }} -->
        <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>
            <td><img :src="item.image && (item.image.includes('http') || item.image.includes('data:image')) ? item.image : `${API_URL}/${item.image}`" alt="" width="70rem" height="70rem"></td>
            <td style="
            word-break: break-all;
            text-align: center;
            width: 200px;
            padding: 0 20px;
          "><p style="white-space: pre-line;">{{ item.variantNote }}</p></td>
            <td>{{ item.amount}}</td>
            <td>
                <div style="text-align: center">
                    <p>$ {{ item.price}}</p>
                </div>
            </td>
            <td>
                <div style="text-align: center">
                    <p>$ {{ item.chinaDeliveryCost }}</p>
                </div>
            </td>
            <td>
                <div style="text-align: center">
                    <p>$ {{ (item.price * item.amount + item.chinaDeliveryCost).toFixed(2) }}</p>
                </div>
            </td>
        </tr>

        <!-- total -->
        
        <tr class="box2">
            <td colspan="7" style="padding: 10px 10px;"></td>
        </tr>
        <tr class="box1">
            <td colspan="5" style="text-align: right; color: orangered; font-weight: 600;"><p>សរុប​ ៖ </p></td>
            <td colspan="2">
                <div style="text-align: right; padding-right: 1rem;">
                    <p style="font-weight: 600; color: orangered">$ {{ (total_product_price + total_china_fee).toFixed(2) }} </p>
                </div>
            </td>
        </tr>
        <tr class="box2">
            <td colspan="2" style="text-align: left;">PAYMENT PROCESS:</td>
            <td style="text-align: left; font-weight: 600;">ABA BANKING</td>
            <td colspan="2" style="color: orangered; text-align: right; font-weight: 600;">Shipping Fee ៖</td>
            <td colspan="2">
                <div style="text-align: right">
                    <p style="font-weight: 600; padding-right: 1rem; color: orangered;" v-if="quotation.internationalShippingCost != 'null'">$ {{ quotation.internationalShippingCost.toFixed(2) }}</p>
                </div>
            </td>
        </tr>
        <tr class="box2">
            <td colspan="2" style="text-align: left;">ACCOUNT NAME:</td>
            <td style="text-align: left; font-weight: 600;">
                UNG HOK
            </td>
            <td colspan="2" style="color: orangered; text-align: right; font-weight: 600;">Air or Land ៖​</td>
            <td colspan="2">
                <div class="space" style="text-align: right;padding-right: 1rem;">
                    <span></span>
                    <p style="color: blue;">{{quotation.shippingMethod.name}}</p>
                </div>
            </td>
        </tr>
        <tr class="box2">
            <td colspan="2" style="text-align: left;">ACCOUNT NUMBER:</td>
            <td style="text-align: left; font-weight: 600;">000 88 75 88</td>
            <td colspan="2" style="color: orangered; text-align: right; font-weight: 600;">Insurance ៖</td>
            <td colspan="2">
                <div style="text-align: right">
                    <p style="font-weight: 600; padding-right: 1rem; color: orangered;" >$ {{ insurance.toFixed(2) }}</p>
                </div>
            </td>
        </tr>
        <tr class="box2" v-if="quotation.discountAmount">
            <td colspan="5" style="color: orangered; text-align: right; font-weight: 600;">Discount ៖</td>
            <td colspan="2">
                <div style="text-align: right">
                    <p style="font-weight: 600; padding-right: 1rem; color: blue;" > - $ {{ quotation.discountAmount.toFixed(2) }}</p>
                </div>
            </td>
        </tr>
        <tr class="box1" style="border: 2.7px solid orangered;">
            <td colspan="5" style="text-align: right; color: orangered; font-weight: 600;"><p>សរុប​ទាំងអស់ ៖ </p></td>
            <td colspan="2">
                <div style="text-align: right; padding-right: 1rem; ">
                    <p style="font-weight: 600; color: orangered;" >$ {{ total.toFixed(2) }}</p>
                </div>
            </td>
        </tr>
        <!-- footer -->
        <tr class="box2">
            <td colspan="7" style="text-align: left; padding: 10px">
            <h2>Note:</h2>
            <span>- ប្រាក់កក់ហើយ មិនអាចដកវិញបានទេ</span> <br>
            <span>- រាល់ការបញ្ជាទិញផលិតផលងាយបែកបាក់ ប្រសិនបើអតិថិជនទិញធានារ៉ាប់រង​ ខាងក្រុមហុ៊ននឹងធ្វើការសងត្រឡប់ ចំនួន ៣ដងនៃថ្លៃដឹកជញ្ជូន (ប្រសិនមានការបែកបាក់)</span> <br>
            <span>- សូមអតិថិជនធ្វើការទូទាត់ចូលគណនីក្រុមហ៊ុន បើពុំដូចនេះក្រុមហ៊ុនមិនទទួលខុសត្រូវ</span> <br>
            <span>- គណនីទទួលប្រាក់ក្រុមហ៊ុនតាមរយៈ ABA <br>
            <pre>  Account Number: <u style="color: blue">000 88 75 88</u></pre>
            <pre>  Account Name: Ung Hok, Morn Sreymom and Ung Leng </pre>
            </span>
            <span>- គណនីទទួលប្រាក់ក្រុមហ៊ុនតាមរយៈ​​ Wing <br>
            <pre>  Phone Number: <u style="color: blue">092 88 75 88</u></pre>
            </span>
            </td>
        </tr>   
        <tr class="text" style="background: orangered; color: #fff;">
            <td colspan="7" style="text-align: center;"><p>ADD.: 21E0, ST. 199, TOUL SVAY PREY 2, KHAN BEUNG KENG KANG, PHNOM PENH</p></td>
        </tr>
    </table>
  </main>
</template>

<script>
require('lity');
import { API_URL } from "../../const";
import moment from "moment";
export default {
    data() {
		return {
			isLoading: true,
            API_URL:API_URL,
		};
        
	},
    
    mounted: function () {
        setTimeout(()=>{
            window.print();
        },1000);

        window.addEventListener('afterprint', (event) => {
            this.$router.back()
        });
    },

	methods: {
		async getQuotationDetails() {
			await this.$store.dispatch("quotationModule/detail", {
				_id: this.quotationId,
			});
			this.isLoading = false;
            
		},
        
	},
	computed: {
        createdAt() {
            return moment(this.quotation.createdAt).format("DD/MM/YYYY")
        },  
		quotationId() {
			return this.$route.params.id;
		},
		items() {
			return this.$store.state.quotationModule.quotationDetails ? this.$store.state.quotationModule.quotationDetails.quotationItems : [];
		},
		quotation() {
			return this.$store.state.quotationModule.quotationDetails;
		},
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        },
        total_product_price() {
            return _.sumBy(this.items, (o) => {
                return o.price * o.amount;
            })
        },
        total_china_fee() {
            return _.sumBy(this.items, (o) => {
                return o.chinaDeliveryCost;
            })
        },
        insurance() {
            return this.quotation.insurance ? this.total_product_price * 0.05 : 0;
        },
        insuranceLabel() {
            return this.total_product_price.toFixed(2) + ' x 5%';
        },
        total() {
            return this.total_product_price + this.insurance + this.total_china_fee + this.quotation.internationalShippingCost - this.quotation.discountAmount;
        },
	},
};
</script>

<style src="lity/dist/lity.min.css"></style>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Battambang:wght@300;400&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Battambang', cursive;
}

html {
    font-size: 85%;
}
body{
    background: #eee;
}
main{
    width: 80%;
    margin: auto;
}
.con{
    z-index: 100;
}
.con img{
    width: 15rem;
    margin-top: 1rem;
    z-index: 100;
}
.item_quotation
{
    display: flex;
    justify-content: space-between;
}
.item_quotation a
{
    color: black;
    font-weight: 600;
}
.item_quotation .quotation
{
    margin-top: 2rem;
    text-align: center;
    color: orangered;
}
.item_quotation .quotation h3{
    font-family: 'Moul', cursive;
}
.item_quotation .quotation h4{
    font-size: 1.1rem;
}
.item_quotation .box{
    margin-top: .5rem;

}
table{
    position: relative;
    width: 100%; 
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1.5rem 0;
    overflow: hidden;
}
table .paid{
    position: absolute;
    bottom: 17rem;
    left: 45%;
}
table .paid img{
    width: 11rem;
}
td , th{
    padding: 5px 5px;
    text-align: center;
    border: 0.1px solid #646464;
}
    th{
    border: none;
    background-color: orangered;
    color: #fafafa;
    font-weight: 200;
}
    tr{
    background-color: #fafafa;
}
    tr:nth-child(even){
    background-color: #fce8e8;
}
.box1 td{
    background: yellow;
    padding: 5px 5px;
}
.box2 td{
    background-color: #fafafa;
    padding: 5px 5px;
}


@media print {
    main{
        width: 96%;
    }
    table{
        margin: 3px 0;
    }
    table .paid{
        position: absolute;
        bottom: 10rem;
        left: 45%;
    }
    table .paid img{
        width: 10rem;
    }
    .con img{
        width: 18rem;
        margin-top: 0;
    }
    th {
        border: 0.1px solid rgb(41, 41, 41);
        color: black;
    }
    td , th{
        padding: 3px 3px;
    }
    .text td{
        color: black;
    }
    .box1 td{
        padding: 2px 2px;
    }
    .box2 td{
        padding: 2px 2px;
    }
}
</style>
