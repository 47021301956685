<template>
	<div class="page dashboard shipping-method-page">
		<div class="content">
			<div class="title-bar">
				<p class="title">Shipping Methods</p>
			</div>
			<ListingComponent
				model="data"
				:isLoading="isLoading"
				:data="shippingMethods"
				:query.sync="query"
				:refresh="getShippingMethods"
				:toggleActionMenu="toggleActionMenu"
				:listingOptions="listingOptions"
				:deleteRecord="() => {}"
			/>
		</div>
		<div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
			<div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <div class="title">Edit Shipping</div>
			<form v-on:submit.prevent="updateShippingMethod">
        <div class="input-ctn">
          <div class="label">Name</div>
          <input
            v-model="focusedMethods.name"
            type="text" 
            class="input"
            placeholder="Name"
          >
        </div>
        <div class="input-ctn">
          <div class="label">Description</div>
          <input 
            v-model="focusedMethods.description"
            type="text" 
            class="input"
            placeholder="Description"
          >
        </div>
        <div class="input-ctn">
          <div class="label">Duration</div>
          <input 
            v-model="focusedMethods.duration"
            type="text" 
            class="input"
            placeholder="Duration"
          >
        </div>
        <button type="submit" class="btn submit">Save Change</button>
      </form>
		</div>
	</div>
</template>

<script>
import ListingComponent from "../../components/ListingComponent";
export default {
	name: "ShippingMethods",
	components: {
		ListingComponent,
	},
	data() {
		return {
      isActionMenuOpen: "",
      isLoading: true,
      focusedMethods: {
        name: "",
        duration: "",
        description: ""
      },
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
			},
		};
	},
	mounted() {
		this.getShippingMethods();
	},
	methods: {
		async getShippingMethods() {
			this.isLoading = true;
			await this.$store.dispatch("shippingMethodModule/getShippingMethods", {
				query: this.query,
			});
			this.isLoading = false;
    },
    async updateShippingMethod() {
      this.isLoading = true
      const {
        _id,
        name, 
        description,
        duration
      } = this.focusedMethods
      await this.$store.dispatch("shippingMethodModule/editShippingMethods", {
        _id, name, description, duration
      })
      this.toggleActionMenu("")
      this.getShippingMethods()
    },
		toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedMethods = {...payload};
				}
			}
			this.isActionMenuOpen = menu;
		},
	},
	computed: {
		listingOptions() {
			return {
				hasSelect: false,
				actions: [],
				queryOptions: {
					sortBy: [],
					sortDirection: [
						{
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
					],
				},
				schema: [
					{ label: "Name", key: "name" },
					{ label: "Description", key: "description" },
					{ label: "Duration", key: "duration" },
				],
			};
		},
		shippingMethods() {
			return this.$store.state.shippingMethodModule.shippingMethods;
		},
	},
};
</script>

<style></style>
