 <template>
  <div id="app">
    <p v-if="loading">Loading . . . </p>
    <router-view v-if="!loading"></router-view>
  </div>
</template>

<script>
import { TOKEN_LABEL } from "./const";

export default {
  name: "App",
  created() {
    this.authenticate();
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    token() {
      return localStorage.getItem(TOKEN_LABEL);
    },
    currentUser() {
      return this.$store.state.usersModule.currentUser;
    }
  },
  methods: {
    async authenticate() {
      if (this.token) {
        const response = await this.$store.dispatch("usersModule/loadUser");
        this.loading = false
        if (response) {
          if (this.$route.name == "log-in-page") {
            this.$router.push({ path: "/" });
          }
        } else if (this.$route.name != "log-in-page") {
          this.$router.push({ path: "/auth" });
        }
      } else{
        if (this.$route.name !== 'log-in-page') {
          this.$router.push({ path: "/auth" });
        }
        this.loading = false
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/app.scss";
@media print{
  html{
    font-size: 62.5%;
  }
}
</style>
