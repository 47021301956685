import MainLayout from '../../layouts/MainLayout.vue';
import Dashboard from '../../pages/dashboard/Dashboard.vue'
import ProductsDashboard from '../../pages/products/ProductsDashBoard.vue'
import UsersDashboard from '../../pages/users/UsersDashboard.vue'
import FirstInfoDashboard from '../../pages/FirstInfo/FirstInfoDashboard.vue'
import MechantsDashboard from '../../pages/mechants/MechantsDashboard';
import ProductCategoriesDashboard from '../../pages/productCategories/ProductCategoriesDashboard';
import TransactionsDashboard from '../../pages/Transactions/TransactionsDashboard'
import CommissionDashboard from '../../pages/commission/CommissionDashboard';
import OrderDashboard from '../../pages/orders/OrderDashboard';
import OrderDetails from '../../pages/orders/OrderDetails';
import QuotationDashboard from '../../pages/quotations/QuotationDashboard';
import QuotationNew from '../../pages/quotations/QuotationNew';
import QuotationDetails from '../../pages/quotations/QuotationDetails';
import QuotationPrint from '../../pages/quotations/QuotationPrint';
import InvoiceDashboard from '../../pages/invoices/InvoiceDashboard';
import InvoiceNew from '../../pages/invoices/InvoiceNew';
import InvoiceDeliveryNote from '../../pages/invoices/InvoiceDeliveryNote';
import InvoiceDeliveryNotePrint from '../../pages/invoices/InvoiceDeliveryNotePrint';
import InvoiceDetails from '../../pages/invoices/InvoiceDetails';
import InvoicePrint from '../../pages/invoices/InvoicePrint';
import TransactionDetails from '../../pages/Transactions/TransactionDetails'
import MerchantDetails from '../../pages/mechants/MerchantDetails';
import NewProduct from '../../pages/products/NewProduct';
import TopUpRequestDashboard from "../../pages/TopUpRequest/TopUpRequestDashboard";
import ProductDetails from "../../pages/products/ProductDetails";
import Banners from '../../pages/Banners/Banners'
import ShippingMethods from '../../pages/shippingMethods/ShippingMethods'
import ExchangeRate from '../../pages/ExchangeRate/ExchangeRate'
import PercentageCutDashboard from '../../pages/PercentageCut/PercentageCutDashboard';
import SalePersonDashboard from '../../pages/salePersons/SalePersonDashboard';
import TopUpMethod from '../../pages/TopUpMethod/TopUpMethod';
import Summary from '../../pages/summary/Summary';
import SettingSuggestionNew from '../../pages/Setting/SettingSuggestionNew';
import SettingSuggestionDashboard from '../../pages/Setting/SettingSuggestionDashboard';
import SettingHomePageNew from '../../pages/Setting/SettingHomePageNew';
import SettingHomePageDashboard from '../../pages/Setting/SettingHomePageDashboard';
import SettingPromotionProductNew from '../../pages/Setting/SettingPromotionProductNew';
import SettingPromotionProductDashboard from '../../pages/Setting/SettingPromotionProductDashboard';
import CustomerSubscription from '../../pages/CustomerSubscription/CustomerSubscription'
import BalanceTransaction from '../../pages/BalanceTransaction/BalanceTransaction'
import CustomerTypeDashboard from '../../pages/customerType/CustomerTypeDashboard';
import CustomerDashboard from '../../pages/customer/CustomerDashboard';
import VoucherDashboard from '../../pages/Voucher/VoucherDashboard'

export default [
  {
    path: '/',
    component: MainLayout,
    children: [
    //   {
    //     path: '/',
    //     // name: 'transactions-dashboard',
    //     component: TransactionsDashboard,
    //     meta: {
    //       // tabName: 'transactions'
    //     }
    //   },
      {
        path: '/top-up-request',
        name: 'top-up-request-dashboard',
        component: TopUpRequestDashboard,
        meta: {
          tabName: 'top-up-request'
        }
      },
      {
        path: '/products',
        name: 'products-dashboard',
        component: ProductsDashboard,
        meta: {
          tabName: 'products'
        }
      },
      {
        path: '/products/:id',
        name: 'product-details',
        component: ProductDetails,
        meta: {
          tabName: 'products'
        }
      },
      {
        path: '/transactions',
        name: 'transactions-dashboard',
        component: TransactionsDashboard,
        meta: {
          tabName: 'transactions'
        }
      },
      {
        path: '/transactions/:id',
        name: 'transactions-details',
        component: TransactionDetails,
        meta: {
          tabName: 'transactions'
        }
      },
      {
        path: '/orders',
        name: 'orders-dashboard',
        component: OrderDashboard,
        meta: {
          tabName: 'orders'
        }
      },
      {
        path: '/banners',
        name: 'banners',
        component: Banners,
        meta: {
          tabName: 'banners'
        }
      },
      {
        path: '/orders/:id',
        name: 'order-details',
        component: OrderDetails,
        meta: {
          tabName: 'orders'
        }
      },
      {
        path: '/quotations',
        name: 'quotations-dashboard',
        component: QuotationDashboard,
        meta: {
          tabName: 'quotations'
        }
      },
      {
        path: '/quotations/new',
        name: 'quotations-new',
        component: QuotationNew,
        meta: {
          tabName: 'quotations'
        }
      },
      {
        path: '/quotations/:id/edit',
        name: 'quotations-edit',
        component: QuotationNew,
        meta: {
          tabName: 'quotations'
        }
      },
      {
        path: '/quotations/:id',
        name: 'quotations-details',
        component: QuotationDetails,
        meta: {
          tabName: 'quotations'
        }
      },
      {
        path: '/invoices',
        name: 'invoices-dashboard',
        component: InvoiceDashboard,
        meta: {
          tabName: 'invoices'
        }
      },
      {
        path: '/invoices/new',
        name: 'invoices-new',
        component: InvoiceNew,
        meta: {
          tabName: 'invoices'
        }
      },
      {
        path: '/invoices/driver-note',
        name: 'invoices-test',
        component: InvoiceDeliveryNote,
        meta: {
          tabName: 'invoices'
        }
      },
      {
        path: '/invoices/edit',
        name: 'invoices-edit',
        component: InvoiceNew,
        meta: {
          tabName: 'invoices'
        }
      },
      {
        path: '/invoices/:id',
        name: 'invoices-details',
        component: InvoiceDetails,
        meta: {
          tabName: 'invoices'
        }
      },
      {
        path: '/product-categories',
        name: 'product-categories-dashboard',
        component: ProductCategoriesDashboard,
        meta: {
          tabName: 'product-categories'
        }
      },
      {
        path: '/merchants',
        name: 'merchants-dashboard',
        component: MechantsDashboard,
        meta: {
          tabName: 'merchants'
        }
      },
      {
        path: '/new-product',
        name: 'new-product',
        component: NewProduct,
        meta: {
          tabName: 'product'
        }
      },
      {
        path: '/merchants/:id',
        name: 'merchant-detials',
        component: MerchantDetails,
        meta: {
          tabName: 'merchants'
        }
      },
      {
        path: '/shipping-methods',
        name: 'shipping-methods',
        component: ShippingMethods,
        meta: {
          tabName: 'shipping-methods'
        }
      },
      {
        path: '/exchange-rate',
        name: 'exchange-rate',
        component: ExchangeRate,
        meta: {
          tabName: 'exchange-rate'
        }
      },
      {
        path: '/percentage-cut',
        name: 'percentage-cut',
        component: PercentageCutDashboard,
        meta: {
          tabName: 'percentage-cut'
        }
      },
      {
        path: '/sale-person',
        name: 'sale-person',
        component: SalePersonDashboard,
        meta: {
          tabName: 'sale-person'
        }
      },
      {
        path: '/customer',
        name: 'customer',
        component: CustomerDashboard,
        meta: {
          tabName: 'customer'
        }
      },
      {
        path: '/customer-type',
        name: 'customer-type',
        component: CustomerTypeDashboard,
        meta: {
          tabName: 'customer-type'
        }
      },
      {
        path: '/top-up-info',
        name: 'top-up-info',
        component: TopUpMethod,
        meta: {
          tabName: 'top-up-info'
        }
      },
      {
        path: '/users',
        name: 'users-dashboard',
        component: UsersDashboard,
        meta: {
          tabName: 'users'
        }
      },
      {
        path: '/firstinfo',
        name: 'firstinfo-dashboard',
        component: FirstInfoDashboard,
        meta: {
          tabName: 'firstinfo'
        }
      },
      {
        path: '/summary',
        name: 'webpage-summary',
        component: Summary,
        meta: {
          tabName: 'summary'
        }
      },
      {
        path: '/setting',
        name: 'setting-home-page-product',
        component: SettingHomePageDashboard,
        meta: {
          tabName: 'setting'
        }
      },
      {
        path: '/setting/edit-home-page-product',
        name: 'setting-edit-home-page-product',
        component: SettingHomePageNew,
        meta: {
          tabName: 'setting'
        }
      },
      {
        path: '/setting/promotion-product',
        name: 'setting-promotion-product',
        component: SettingPromotionProductDashboard,
        meta: {
          tabName: 'setting-promotion-product'
        }
      },
      {
        path: '/setting/edit-promotion-product',
        name: 'setting-edit-promotion-product',
        component: SettingPromotionProductNew,
        meta: {
          tabName: 'setting-promotion-product'
        }
      },
      {
        path: '/setting/suggestion',
        name: 'setting-suggestion',
        component: SettingSuggestionDashboard,
        meta: {
          tabName: 'setting-suggestion'
        }
      },
      {
        path: '/setting/edit-suggestion',
        name: 'setting-edit-suggestion',
        component: SettingSuggestionNew,
        meta: {
          tabName: 'setting-suggestion'
        }
      },
      {
        path: '/customer-subscription',
        name: 'CustomerSubscription',
        component: CustomerSubscription,
        meta: {
          tabName: 'CustomerSubscription'
        }
      },
      {
        path: '/balance',
        name: 'BalanceTransaction',
        component: BalanceTransaction,
        meta: {
          tabName: 'BalanceTransaction'
        }
      },
      {
        path: '/voucher',
        name: 'VoucherDashboard',
        component: VoucherDashboard,
        meta: {
          tabName: 'VoucherDashboard'
        }
      },
      {
        path: '/commission',
        name: 'CommissionDashboard',
        component: CommissionDashboard,
        meta: {
          tabName: 'Commission'
        }
      }
    ]
  },
  {
    path: '/invoices/:id/print',
    component: InvoicePrint
  },
  {
    path: '/quotations/:id/print',
    component: QuotationPrint
  },
  {
    path: '/invoices/print/driver-note',
    component: InvoiceDeliveryNotePrint
  }
]
