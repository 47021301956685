import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    requestList: { pageMeta: null, requests: [] },
    infoList: { pageMeta: null, requests: [] }
  },
  mutations: {
    setRequestList(state, payload) {
      state.requestList = payload
    },
    setInfoList(state, payload) {
      state.infoList = payload
    }
  },
  actions: {
    async getRequest(context, payload) {
      const url = 'topupRequest'
      const { query } = payload
      console.log(query);
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) {
        context.commit('setRequestList', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updateStatus(context, payload) {
      const { id, status } = payload
      const url = `topupRequest/${id}/status`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { status })
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, {root:true})
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async getInfo(context, payload) {
      const url = 'topupInfo'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "GET", {}, query, {})
      if (success) {
        context.commit('setInfoList', result)
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async createInfo(context, payload) {
      const url = 'topupInfo'
      const { title, description, image } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", { title, description, image }, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateInfo(context, payload) {
      const { _id, title, description, image } = payload
      const url = `topupInfo/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { title, description, image }, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async deleteMethod(context, payload) {
      const { _id } = payload
      const url = `topupInfo/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'DELETE', {}, {}, {})
      if(success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    }
  }
}