import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    invoices: { pageMeta: {}, data: [] },
    invoiceDetails: {},
    isArrived: {},
    exportInvoices: [],
    printDeliveryNote: {}
  },
  mutations: {
    setInvoices(state, payload) {
      state.invoices = payload
    },
    setExportInvoices(state, payload) {
      state.exportInvoices = payload.data
    },
    setInvoiceDetails(state, payload) {
      state.invoiceDetails = payload
    },
    setInvoiceDetailStatus(state, payload) {
      state.invoiceDetails.status = payload
    },
    setInvoiceArrived(state, payload) {
      state.isArrived = payload
    },
    pushInvoicePaymentImage(state, payload) {
        if(state.invoiceDetails._id == payload.id) {
            if(!state.invoiceDetails.paymentImages) {
                state.invoiceDetails.paymentImages = [];
            }
            state.invoiceDetails.paymentImages.push({
                image: payload.image
            });
        }
    },
    pushInvoiceSummaryImage(state, payload) {
      if(state.invoiceDetails._id == payload.id) {
          if(!state.invoiceDetails.summaryImages) {
              state.invoiceDetails.summaryImages = [];
          }
          state.invoiceDetails.summaryImages.push({
              image: payload.image
          });
      }
    },
    setPrintDeliveryNote(state, payload) {
        state.printDeliveryNote = payload
    },
  },
  actions: {

    async detail(context, payload) {
      const { _id } = payload
      const url = `invoice/${_id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        context.commit('setInvoiceDetails', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },

    async printDeliveryNote(context, payload) {
        context.commit('setPrintDeliveryNote', payload)
    },

    async create(context, payload) {
        const url = `invoice`
        const {success,result,message} = await HttpRequest.authorizedRequest(url, 'POST', payload, {})
        if(success){
            return result;
        }else{
            httpErrorHandler(message)
        }
    },
    async update(context, payload) {
        const { _id } = payload;
        const url = `invoice/update/${_id}`;
        const {success,result,message} = await HttpRequest.authorizedRequest(url, 'PATCH', payload, {})
        if(success){
            return true
        }else{
            httpErrorHandler(message)
        }
    },

    async delete(context, payload) {
      const {
        _id
      } = payload
      const url = `invoice/${_id}`
      const {
        success,
        message
      } = await HttpRequest.authorizedRequest(url, "DELETE", {}, {})
      if (success) {
        context.commit('setInvoiceDetails', context.state.invoinces.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    },

    async recover(context, payload) {
      const {
        _id
      } = payload
      const url = `invoice/${_id}/recover`
      const {
        success,
        message
      } = await HttpRequest.authorizedRequest(url, "POST", {}, {})
      if (success) {
        context.commit('setInvoiceDetails', context.state.invoinces.data.filter(item => item._id != _id));
        return true
      } else {
        httpErrorHandler(message)
      }
    },

    async getInvoiceByTrackingId(context, payload) {
      const { trackingId } = payload
      const url = `invoice/byTrackingId/${trackingId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, {}, {})
      if (success) {
        return result;
      } else {
        return false;
      }
    },
    async getInvoices(context, payload) {
      const query = payload
      const url = 'invoice'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setInvoices', result)
        return result;
      } else {
        httpErrorHandler(message)
      }
    },
    async getComissionInvoices(context, payload) {
      const query = payload
      const url = 'invoice/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setInvoices', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async exportComissionInvoices(context, payload) {
      const query = payload
      const url = 'invoice/commission'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, { ...query }, {})
      if (success) {
        context.commit('setExportInvoices', result)
      } else {
        httpErrorHandler(message)
      }
    },
    async updateStatus(context, payload) {
      const { _id, status } = payload
      const url = `invoice/${_id}/status`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', { status }, {}, {})
      if (success) {
        context.commit('setInvoiceDetailStatus', status)
        context.dispatch('notificationModule/getNotification', {}, { root: true })
      } else {
        httpErrorHandler(message)
      }
    },
    async invoiceArrived(context, payload) {
      const { _id, itemId } = payload
      const url = `invoice/${_id}/arrive/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {})
      if (success) {
        context.commit('setInvoiceArrived', { _id: _id, itemId })
      } else {
        httpErrorHandler(message)
      }
    },
    async payInvoice(context, payload) {
      const { _id } = payload
      const url = `invoice/${_id}/pay`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async rejectInvoice(context, payload) {
      const { _id } = payload
      const url = `invoice/${_id}/reject`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {}, {}, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async refundItem(context, payload) {
      const { invoiceId, itemId, refundAmount } = payload
      const url = `invoice/${invoiceId}/refund/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
        refundAmount
      }, {})
      if (success) {
        context.dispatch('detail', { _id: invoiceId })
        return true;
      } else {
        httpErrorHandler(message)
        return false;
      }
    },
    async noteInvoiceItem(context, payload) {
      const { invoiceId, itemId, note } = payload
      const url = `invoice/${invoiceId}/note/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {note}, {})
      if (success) {
        context.dispatch('detail', { _id: invoiceId })
      }
    },
    async nextStatusInvoiceItem(context, payload) {
      const { invoiceId, itemId, date, trackingId, chinaMoney } = payload
      const url = `invoice/${invoiceId}/nextStatus/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
          date,
          trackingId,
          chinaMoney
      }, {})
      if (success) {
        context.dispatch('detail', { _id: invoiceId })
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async trackingIdInvoiceItem(context, payload) {
      const { invoiceId, itemId, trackingId } = payload
      const url = `invoice/${invoiceId}/trackingId/${itemId}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'PATCH', {
          trackingId,
      }, {})
      if (success) {
        context.dispatch('detail', { _id: invoiceId })

        return true;
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async updateCost(context, payload) {
      const url = `invoice/${payload._id}/servicePrice`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    async updatePaidAmount(context, payload) {
      const url = `invoice/${payload._id}/paidAmount`
      const {
        success,
        result,
        message
      } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {})
      if (success) {
        context.dispatch('notificationModule/getNotification', {}, { root: true })
        return true
      } else {
        httpErrorHandler(message)
      }
    },
    
    async uploadPaymentImage(context, payload) {
      const { id, image } = payload;
      const url = `invoice/${id}/upload_payment`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {image})
      if (success) {
        context.commit('pushInvoicePaymentImage', { image, id })
        return true
      } else {
        context.commit('appModule/alertMessage',
          {
            showing: true, color: '#dc3545', message
          }, { root: true })
        return false
      }
    },

    async deletePaymentImage(context, payload) {
      const { id, path } = payload;
      const url = `invoice/${id}/upload_payment`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", {
        path
      });
      
      if (success) {
        return true
      } else {
        context.commit('appModule/alertMessage',
          {
            showing: true, color: '#dc3545', message
          }, { root: true })
        return false
      }
    },

    async uploadSummaryImage(context, payload) {
      const { id, image } = payload;
      const url = `invoice/${id}/upload_summary_image`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "POST", {image})
      if (success) {
        context.commit('pushInvoiceSummaryImage', { image, id })
        return true
      } else {
        context.commit('appModule/alertMessage',
          {
            showing: true, color: '#dc3545', message
          }, { root: true })
        return false
      }
    }
  }
}