<template>
  <div class="tab-bar-loading-component">
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
  </div>
</template>


<script>
export default {
  name: "TabBarLoadingComponent"
};
</script>
<style scoped>
</style>
