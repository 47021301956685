<template>
    <div class="page dashboard percentage-cut-page">
        <div class="content">
            <div class="title-bar">
                <p class="title">Setting, {{id ? 'Edit' : 'Add New'}} Suggestion</p>
            </div>
            <div>
                <form v-on:submit.prevent="editSetting">
                    <div class="label required">English</div>
                    <div class="input-ctn">
                        <input type="text" v-model="en" placeholder="English" class="input">
                    </div>
                    <div class="label required">Khmer</div>
                    <div class="input-ctn">
                        <input type="text" v-model="km" placeholder="Khmer" class="input">
                    </div>
                    <div class="label required">China</div>
                    <div class="input-ctn">
                        <input type="text" v-model="cn" placeholder="China" class="input">
                    </div>
                    <button class="btn submit">Save</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SettingSuggestionNew",
        components: {},
        data() {
            return {
                id: null,
                en: null,
                km: null,
                cn: null,
                order: 20,
            }

        },
        mounted() {
            this.id = this.$route.query._id;
            this.en = this.$route.query.en;
            this.km = this.$route.query.km;
            this.cn = this.$route.query.cn;
        },
        methods: {
            async editSetting() {
                const success = await this.$store.dispatch(this.id ? 'updateSettingSuggestion' : 'createSettingSuggestion', {
                    _id: this.id,
                    en: this.en,
                    km: this.km,
                    cn: this.cn,
                })
                if (success) {
                    alert("Successfully");
                    this.$router.push({ path: "/setting" });
                }
            }
        }

    }
</script>
<style scoped>
    form {
        width: 30rem;

    }

    .input {
        width: 100%;
        border: 1px solid #000;
        margin-bottom: 1rem;
        line-height: 3rem;
        padding: 0 1rem;
    }

    .btn {
        background-color: #000;
        color: #fff;
        line-height: 2rem;
        padding: 0 1rem;
    }

    .submit {
        width: 100%;
        background-color: #000000;
        color: #ffffff;
        line-height: 3rem;
        display: flex;
        justify-content: center;
    }
</style>