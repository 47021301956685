<template>
  <div v-if="show" class="form-item-component">
      <slot :readOnly="state == 'read'" />
  </div>
</template>


<script>
export default {
  name: "FormItemComponent",
  props: {
    new: {
      type: Boolean,
      required: false,
      default: true
    },
    edit: {
      type: Boolean,
      required: false,
      default: true
    },
    read: {
      type: Boolean,
      required: false,
      default: true
    },
    state: {
      type: String,
      required: true
    }
  },
  computed: {
    show() {
      let value = true;

      console.log(this.new);
      if (this.state == "new" && !this.new) {
        console.log("1");
        value = false;
      } else if (this.state == "edit" && !this.edit) {
        console.log("2");

        value = false;
      } else if (this.state == "read" && !this.read) {
        console.log("3");

        value = false;
      }

      return value;
    }
  }
};
</script>
<style scoped>
</style>
