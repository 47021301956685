import { HttpRequest, httpErrorHandler } from "../../handler"

export default {
  namespaced: true,
  state: {
    salePeople: {
      pageMeta: null, data: []
    }
  }, 
  mutations: {
    setSalePeople(state, payload) {
      state.salePeople = payload
    }
  },
  actions: {
    async getSalePeople(context, payload) {
      const url = 'salePerson'
      const { query } = payload
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'GET', {}, query, {})
      if (success) { 
        context.commit('setSalePeople', result)
      } else {
        httpErrorHandler(message)
      }
    }, 
    async createSalePerson(context, payload) {
      const url = 'salePerson'
      const { success, result, message } = await HttpRequest.authorizedRequest(url, 'POST', payload, {}, {})
      if(success) {
        return true
      } else {
        httpErrorHandler(message)
        return false
      }
    },
    async editSalePerson(context, payload) {
      const url = `salePerson/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "PATCH", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    },
    async deleteSalePerson(context, payload) {
      const url = `salePerson/${payload._id}`
      const { success, result, message } = await HttpRequest.authorizedRequest(url, "DELETE", payload, {}, {})
      if (success) {
        return true
      } else {
        httpErrorHandler(message) 
        return false
      }
    }
  }
}