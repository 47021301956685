<template>
  <div class="page dashboard users-dashboard">
    <div class="content">
      <div v-if="isActionMenuOpen" class="action-menu-backdrop" v-on:click="toggleActionMenu('')"></div>

      <div class="title-bar">
        <p class="title">User Management</p>
        <div class="group">
          <div class="btn new">
            <div class="icon"></div>
            <p class="label" v-on:click="toggleActionMenu('new')">New User</p>
          </div>
          <div class="btn more"></div>
        </div>
      </div>

      <TabBarComponent
        :isLoading="isLoadingUserRoles"
        :query.sync="query"
        :data="userRoles.data"
        queryKey="role"
        :label="(record) => record.name"
        :updateSearch="updateSearch"
        placeholder="Search by Phone Number"
      />

      <input placeholder="Search for users..." type="text" class="search" v-model="search" />
        <input
            v-model="salePersonLabel"
            type="text"
            class="search"
            style="margin-left: 10px"
            placeholder="Sale Person"
            @focus="openSalePersonModal"
        />
        <br>
        <input type="date" class="search" style="width: 20%; margin-top:10px" v-model="query.fromDate">
        <input type="date" class="search" style="margin-left: 10px; margin-bottom: 10px; width: 20%" v-model="query.toDate">
        <download-excel
            v-if="currentUser.role.name === 'admin' || currentUser.role.name === 'financer'"
            class="export-btn" 
            :fetch="excelData"
            :name="excelName"
        >
            Export Execel

        </download-excel>
        <p class="title" v-if="(currentUser.role.name === 'admin' || currentUser.role.name === 'financer') && (!this.role || this.role.name == 'buyer')">Total Buyer Balance: {{parseFloat(totalBuyerBalance).toFixed(2)}}</p>
      <ListingComponent
        model="data"
        :isLoading="isLoadingUsersListing"
        :data="usersListing"
        :query.sync="query"
        :refresh="getUsers"
        :toggleActionMenu="toggleActionMenu"
        :selected.sync="selected"
        :listingOptions="listingOptions"
        :deleteRecord="deleteUser"
      />
    </div>

    <div :class="`action-menu ${isActionMenuOpen ? 'active' : ''}`">
      <div class="btn close" v-on:click="toggleActionMenu('')"></div>
      <NewUserForm v-if="isActionMenuOpen === 'new'" :successAddUser="successAddUser" />
      <EditUser
        :toggleActionMenu="toggleActionMenu"
        :user="focusedUser"
        v-if="isActionMenuOpen === 'read' || isActionMenuOpen === 'edit'"
        :editUser="editUser"
        :getUsers="getUsers"
        :roles="userRoles"
      />
      <ShippingDetails
        :toggleActionMenu="toggleActionMenu"
        v-if="isActionMenuOpen === 'shippingDetails'"
        :user="focusedUser"
      />
      <NewMerchantForm
        :toggleActionMenu="toggleActionMenu"
        v-if="isActionMenuOpen === 'newMerchant'"
        :user="focusedUser"
      />
      <EditMerchant
        :toggleActionMenu="toggleActionMenu"
        v-if="isActionMenuOpen === 'editMerchant'"
        :user="focusedUser"
      />
      <AdjustUserBalance
        :toggleActionMenu="toggleActionMenu"
        v-if="isActionMenuOpen === 'adjustBalance'"
        :user="focusedUser"
      />
      <ChangePassword
        :toggleActionMenu="toggleActionMenu"
        v-if="isActionMenuOpen === 'ChangePassword'"
        :user="focusedUser"
      />
    </div>
    <SelectSalePersonModal
        :closeModal="closeSalePersonModal"
        v-show="salePersonModalOpened"
        :onSubmit="selectedSalePerson"
    />
  </div>
</template>

<script>
import ListingLoadingComponent from "../../components/ListingLoadingComponent";
import TabBarLoadingComponent from "../../components/TabBarLoadingComponent";
import ListingComponent from "../../components/ListingComponent";
import TabBarComponent from "../../components/TabBarComponent";

import ShippingDetails from './ShippingDetails'
import NewUserForm from "./NewUserForm";
import EditUser from './EditUser';
import NewMerchantForm from './NewMerchantForm';
import EditMerchant from '../mechants/EditMerchant';
import AdjustUserBalance from './AdjustUserBalance'
import ChangePassword from './ChangePassword'
import SelectSalePersonModal from "../../components/SelectSalePersonModal";
import moment from "moment";

export default {
	name: "UsersDashboard",
	components: {
		ListingLoadingComponent,
		TabBarLoadingComponent,
		ListingComponent,
		TabBarComponent,
        NewUserForm,
        EditUser,
        ShippingDetails,
        NewMerchantForm,
        EditMerchant,
        AdjustUserBalance,
        ChangePassword,
        SelectSalePersonModal
	},
	data: function() {
		return {
            salePerson: null,
            salePersonLabel: null,
            salePersonModalOpened: false,
			searchTimeout: null,
			selected: [],
			isActionMenuOpen: "",
			focusedUser: {},
			q: "",
			query: {
				order: "ASC",
				page: 1,
				limit: 10,
				sortBy: "",
				role: 0,
                fromDate: null,
                toDate: null
			},
            salePerson: null,
			search: ""
		};
	},
	created() {
		this.getUserRoles();
		this.getUsers();
	},
	computed: {
		listingOptions() {
            const actions = this.currentUser.role.name == 'admin' ? [ "delete"] : [];
			return {
				hasSelect: true,
				actions,
				queryOptions: {
					sortBy: [
						{
							label: "Email",
							value: "email",
						},
						{
							label: "Role",
							value: "role",
						},
					],
					sortDirection: [
						{
							label: "Ascending",
							value: "ASC",
						},
						{
							label: "Descending",
							value: "DESC",
						},
					],
				},
				schema: [
					{
						label: "Username",
						key: "name",
					},
					{
						label: "Phone Number",
						key: "phoneNumber",
					},
					{
						label: "Balance",
						key: "balance",
						value: (record) => record.balance?.amount 
					},
					{
						label: "Type",
						key: "buyerType",
						value: (record) => record.buyerType,
					},
					{
						label: "Sale Person",
						key: "SalePerson",
						value: (record) => record.salePerson ? record.salePerson.code + ' - ' + record.salePerson.name : null
					},
					{
						label: "Role",
						key: "role",
						value: (record) => record.role.name,
					},
                    {
                        label: "Created At",
                        key: "createdAt",
                        value: (record) => moment(record.createdAt).format('YYYY-MM-DD HH:mm'),
                    },
				],
			};
		},
		userRoles() {
			return this.$store.state.usersModule.userRoles;
		},
		isLoadingUserRoles() {
			return this.$store.state.usersModule.isLoadingUserRoles;
		},
		usersListing() {
			return this.$store.state.usersModule.usersListing;
		},
		isLoadingUsersListing() {
			return this.$store.state.usersModule.isLoadingUsersListing;
		},
		isSubmittingUser() {
			return this.$store.state.usersModule.isSubmittingUser;
		},
        totalBuyerBalance() {
			return this.$store.state.usersModule.usersListing.totalBuyerBalance || 0;
        },
        role() {
            return this.query.role ? this.userRoles.data.find(i => i._id == this.query.role) : null;
        },
        excelName() {
            return (this.role ? this.role.name : 'user') + '-'+moment().format('YYYY-MM-DD')+'.xls';
        },
        currentUser() {
            return this.$store.state.usersModule.currentUser;
        }
	},
	watch: {
		query: {
			deep: true,
			handler: function() {
				if (this.query.page >= 1) {
					this.getUsers();
				}
			},
		},
		search: function() {
			this.searchTimeout && clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(
				() => this.getUsers(), 500
			)
		}
	},
	methods: {
        async excelData() {
			const data = await this.$store.dispatch("usersModule/exportUsers", {
				query: { ...this.query, q: this.q, search: this.search, salePerson: this.salePerson?._id, export: true},
			});

            if(data.length <= 0 ) {
                alert('Empty records')
                return;
            }

            return data.map((record, index) => {
                return {
                    '#': index + 1,
                    "Username": record.name,
                    "Phone Number": record.phoneNumber,
                    "balance": record.balance?.amount,
                    "buyerType":  record.buyerType,
                    'Sale Person': record.salePerson ? record.salePerson.code + ' - ' + record.salePerson.name : null,
                    'Role': record.role.name,
                    'Date': moment(record.createdAt).format('DD/MM/YYYY H:mm'),
                };
            });
        },
		async getUserRoles() {
			await this.$store.dispatch("usersModule/getUserRoles");
		},
		async getUsers() {
			await this.$store.dispatch("usersModule/getUsers", {
				query: { ...this.query, q: this.q, search: this.search, salePerson: this.salePerson?._id},
			});
		},
        openSalePersonModal() {
            this.salePersonModalOpened = true;
            setTimeout(() => {
                document.getElementById("selectUserModelSearchInput").focus();
            })
        },
        closeSalePersonModal() {
            this.salePersonModalOpened = false;
        },
        selectedSalePerson(salePerson) {
            if(this.salePerson == salePerson) {
                return;
            }

            if(salePerson) {
                this.salePersonLabel = salePerson.code + ' - ' + salePerson.name;
            } else {
                this.salePersonLabel = null;
            }
            this.salePerson = salePerson;
            this.getUsers();
        },
		toggleActionMenu(menu, payload) {
			if (menu !== "") {
				if (payload) {
					this.focusedUser = payload;
				} 
			}
			this.isActionMenuOpen = menu;
		},
		updateSearch(q) {
			this.q = q;
			this.getUsers();
		},
		async deleteUser(payload) {
			const confirmation = confirm(
				`Are you sure your want to delete user?\nUser: ${payload.name}`
			);
			if (confirmation) {
				const response = await this.$store.dispatch("usersModule/deleteUser", {
					id: payload._id,
				});
				if (response) {
					this.getUsers();
				}
			}
		},
		async editUser() {
			const response = await this.$store.dispatch("usersModule/updateUser", {
				...this.focusedUser,
			});
		},
		async successAddUser() {
            this.isActionMenuOpen === "";
            this.getUsers();
		},
	},
};
</script>
<style scoped></style>
